import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Box, Button, Grid, Icon, IconButton, Paper, Stack, TextField } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { green, red, yellow } from "@mui/material/colors";
import { Container } from "@mui/system";
import { collection, deleteDoc, doc, getDoc, onSnapshot, updateDoc, setDoc } from "firebase/firestore";
import { deleteObject, getMetadata, ref } from "firebase/storage";
import React, { useContext, useEffect, useState, useMemo } from "react";
import ListingImageComponent from "./ListingImageComponent";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { db, storage } from "../../firebase/firebase-utils";
import mapSnapshot from "../../functions/common-functions/mapSnapshot";
import { GridContainer, GridDivider, GridFlexBox, addAdornment } from "../../themes/themes";
import { AuthContext } from "./../../context/AuthContext";
import { Name } from "./../../themes/themes";
import { SELECT_ITEM } from "./SelectConst";
import { UploadListingFilesButton } from "./UploadListingFilesButton";
import { addListingId } from "./addListingId";
import { getAgentListings } from "./getAgentListing";
import { updateAgentHeader } from "./updateAgentHeader";
import { updateListing } from "./updateListing";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import useNotification from "../../context-utils/NotificationContext";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//created by wayne 4-12-2023
//for listing function with add, edit, view features.
const ListingPage = () => {
    const { user } = useContext(AuthContext);
    const [listings, setListings] = useState([]);
    const [currentListing, setCurrentListing] = useState({});
    const [currentListingId, setCurrentListingId] = useState("");

    const [newListingName, setNewListingName] = useState("");
    const [userHeader, setUserHeader] = useState({
        avatar: user.settings?.listingHeader?.avatar || "",
        agentDomain: user.settings?.listingHeader?.agentDomain || "property.djcsystem.com",
        agentFB: user.settings?.listingHeader?.agentFB || "",
        email: user?.email || "",
        phone: user?.phone || "",
        whatsapp: user.settings?.listingHeader?.whatsapp || "",
        agentCompany: user.settings?.listingHeader?.agentCompany || "",
        agentCompanyAdd: user.settings?.listingHeader?.agentCompanyAdd || "",
        aboutTitle: user.settings?.listingHeader?.aboutTitle || "",
        aboutDesc: user.settings?.listingHeader?.aboutDesc || "",
        displayEmail: user.settings?.listingHeader?.displayEmail || "",
        displayNumber: user.settings?.listingHeader?.displayNumber || ""
    });
    const [selectedImage, setSelectedImage] = useState(null);
    const [displayImage, setDisplayImage] = useState(null);
    const [images, setImages] = useState(null);
    const [inputDisable, setInputDisable] = useState(true);
    const [refresh, setRefresh] = useState(false);

    const defaultSearchObj = {
        propertyName: "",
        propertyTitle: "",
        propertyPrice: "",
        propertyState: "",
        propertyCity: "",
        propertyTownship: "",
        propertyPoscode: "",
        propertyAddress: "",
        propertyPurpose: ""
    };

    const [searchObj, setSearchObj] = useState(defaultSearchObj);
    const notification = useNotification();

    const handleNewListing = (e) => {
        setNewListingName(e.target.value);
    };

    const handleSearchObj = (e) => {
        const { name, value } = e.target;
        setSearchObj((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const loadHeader = async () => {
        const objHeader = {
            avatar: user.settings?.listingHeader?.avatar || "",
            agentDomain: user.settings?.listingHeader?.agentDomain || "property.djcsystem.com",
            agentFB: user.settings?.listingHeader?.agentFB || "",
            email: user?.email || "",
            phone: user?.phone || "",
            whatsapp: user.settings?.listingHeader?.whatsapp || "",
            agentCompany: user.settings?.listingHeader?.agentCompany || "",
            agentCompanyAdd: user.settings?.listingHeader?.agentCompanyAdd || "",
            aboutTitle: user.settings?.listingHeader?.aboutTitle || "",
            aboutDesc: user.settings?.listingHeader?.aboutDesc || "",
            displayEmail: user.settings?.listingHeader?.displayEmail || "",
            displayNumber: user.settings?.listingHeader?.displayNumber || ""
        };
        if (objHeader) {
            setUserHeader(objHeader);
        }
        // else
        // {
        //     setUserHeader({});
        // }
    };

    const handleListingSelection = (currentListing) => {
        // Set the selected property to state
        console.log("currentListing.id:", currentListing.id);
        setCurrentListingId(currentListing.id);
        setInputDisable(false);
        setRefresh(!refresh);
    };

    const handleSaveHeader = async () => {
        console.log("currentListing:", currentListing);
        try {
            if (currentListing?.id) {
                setCurrentListing((prevState) => {
                    const updatedListing = {
                        ...prevState
                        // domain: userHeader.agentDomain ? userHeader.agentDomain : "property.djcsystem.com"
                    };
                    // Perform the update inside the callback
                    updateListing(updatedListing).catch((error) => {
                        // Handle error from updateListing herec
                        console.log("Error:", error);
                    });
                    return updatedListing;
                });
            }
            console.log("userHeader:", userHeader);
            await updateAgentHeader(userHeader, selectedImage, user);
            notification("Record updated.", "success");
        } catch (err) {
            console.log(err);
        }
    };

    const saveDuplicateListing = async (listobj) => {
        try {
            if (listobj.id) {
                console.log("listobj:", listobj);
                await updateListing(listobj);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleView = () => {
        console.log("user", user);
        console.log("domain:", userHeader.agentDomain);
        const defaultDomain = "property.djcsystem.com";
        const linkToOpen =
            !userHeader.agentDomain || userHeader.agentDomain === defaultDomain
                ? `${process.env.REACT_APP_LISTING_HOST}agent/${user.clientId}`
                : userHeader.agentDomain;
        window.open(linkToOpen, "_blank");
    };

    const handleDeleteImageInListing = async (image) => {
        const result = await confirmation("Delete Image/File", "Are you sure you want to delete this image/file?");
        if (result) {
            await handleDeleteImage(image);
        }
    };

    const removeItemById = (array, idToRemove) => {
        return array.filter((item) => item !== idToRemove);
    };

    const handleDeleteImage = async (image) => {
        //retrieve the image path first, then use the path to delete the image in storage first,
        //then only delete images details in listings.files

        try {
            const imagesRef = doc(db, "listings", currentListing.id, "files", image.id);
            const imageDoc = await getDoc(imagesRef);
            const imageData = imageDoc.data();

            let imgToDelete = ref(storage, imageData.fullPath);
            //delete the original image file
            await deleteObject(imgToDelete);
            const fileNameWithoutExtension = imageData.fullPath.split("/").pop().split(".").slice(0, -1).join(".");
            const newFileName = `${fileNameWithoutExtension}_1080x1080.jpeg`;

            const pathWithoutFileName = imageData.fullPath.substring(0, imageData.fullPath.lastIndexOf("/"));
            const thumbnailsFullPath = pathWithoutFileName + "/" + "thumbnails/" + newFileName;

            imgToDelete = ref(storage, thumbnailsFullPath);
            //delete the thumbnails file.
            getMetadata(imgToDelete).then(async () => await deleteObject(imgToDelete)); //this line to cater if thumbnail file is not exist.

            //delete file under listing/files
            await deleteDoc(imagesRef);

            //this section is to delete propertyImg that is use for user sorting purpose.
            currentListing.propertyImg = removeItemById(currentListing.propertyImg, image.id);
            const listingsCollectionRef = doc(db, "listings", currentListing.id);
            await setDoc(listingsCollectionRef, currentListing, { merge: true });
            console.log("Image deleted.");
        } catch (err) {
            console.log(err);listings
        }
    };

    const confirmation = useConfirmation();

    const handleDeleteListing = async (listing) => {
        console.log("listing:", listing);
        const result = await confirmation("Delete Listing", "Are you sure you want to delete this listing?");
        if (result) {
            const listRef = doc(db, "listings", listing.id);
            await deleteDoc(listRef);
            console.log("Listing deleted.");
        }
    };

    const handleListingFile = async (files) => {
        handleDeleteImage(files);
    };

    const handleClickAddListing = async () => {
        if (newListingName) {
            const id = await addListingId(user.email, newListingName);
            setCurrentListingId(id);
            setNewListingName("");
        } else {
            notification("Please key in new listing name to add.", "warning");
        }
    };

    // Event listener for file input change
    const handleAvatarChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            const reader = new FileReader();

            reader.onload = function (e) {
                // Check if the loaded data is a valid image
                const isImage = e.target.result.startsWith("data:image/");

                if (isImage) {
                    // Use the data URL as the source for Avatar
                    setSelectedImage(selectedFile);
                    setDisplayImage(e.target.result);
                } else {
                    console.error("Selected file is not a valid image.");
                    // Handle the error or provide feedback to the user
                }
            };

            reader.readAsDataURL(selectedFile);
        }
    };

    useEffect(() => {
        try {
            if (!currentListingId) {
                console.log("currentListingId is empty.",);
                setImages(null); // Reset images array when no current listing ID is present
                return;
            }
            const imagesRef = collection(db, "listings", currentListingId, "files");

            const unsubscribe = onSnapshot(imagesRef, (querySnapshot) => {
                const newImages = mapSnapshot(querySnapshot);

                setImages(newImages);
            });
            return () => unsubscribe();
        } catch (err) {
            console.log("Listing Page:", err);
        }
    }, [currentListingId, refresh]);

    useEffect(() => {
        try {
            const unsubscribe = getAgentListings(user.email, setListings);
            loadHeader();

            return () => unsubscribe();
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        if (!currentListingId) return;
        const docRef = doc(db, "listings", currentListingId);
        const unsubscribe = onSnapshot(docRef, (doc) => {
            const listing = mapDocSnapshot(doc);
            setCurrentListing(listing);
        });

        return () => unsubscribe();
    }, [currentListingId]);

    useEffect(()=>{
        setImages(null);
        setCurrentListing({});
    },[searchObj])

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log("value:", value);
        setCurrentListing((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        // Find the category of the selected property type
        const selectedCategory = Object.keys(SELECT_ITEM.propertyType).find((category) =>
            SELECT_ITEM.propertyType[category].includes(value)
        );

        // Only update if a category is found
        if (selectedCategory) {
            setCurrentListing((prevState) => ({
                ...prevState,
                [name]: value,
                landTitle: selectedCategory
            }));
        } else {
            console.log("No matching category found for value:", value);
        }
    };

    const handleDuplicateListing = async (listing) => {
        console.log("handleDuplicateListing:", listing);
        const result = await confirmation("Duplicate Listing", "Are you sure you want to duplicate this listing?");
        if (result) {
            let newDuplicatedListing = { ...listing };
            delete newDuplicatedListing.id;
            delete newDuplicatedListing.createdDate;
            delete newDuplicatedListing.path;
            delete newDuplicatedListing.active;

            const id = await addListingId(user.email, newDuplicatedListing.propertyName + "_copy");
            setCurrentListingId(id);
            delete newDuplicatedListing.propertyName;
            newDuplicatedListing.id = id;
            newDuplicatedListing.active = false;

            await saveDuplicateListing(newDuplicatedListing);
        }
    };

    let displayListing = useMemo(() => {
        // setImages(null);
        // Remove setCurrentListing({}); from here
        const filtered = listings.filter((listing) => {
            return Object.entries(searchObj).every(([key, value]) => {
                return value === "" || listing[key]?.toLowerCase().includes(value.toLowerCase());
            });
        });        
  
        console.log('clear images and currentListing.');

        return filtered;
    }, [listings, searchObj]);

    const moveItem = (index, direction) => {
        if ((direction === "up" && index === 0) || (direction === "down" && index === images.length - 1)) {
            // Can't move outside the array bounds
            return;
        }

        const newIndex = direction === "up" ? index - 1 : index + 1;
        const newData = [...images];
        [newData[index], newData[newIndex]] = [newData[newIndex], newData[index]]; // Swap elements
        setImages(newData);

        setCurrentListing((currentListing) => ({
            ...currentListing,
            propertyImg: newData.map((item) => item.id)
        }));
    };

    return (
        <Container maxWidth="none">
            <Grid container display="flex" spacing={1}>
                <GridFlexBox gap={"8px"}>
                    <Typography variant="h6" sx={{ marginTop: "10px" }}>
                        Property Listing Page Configuration
                    </Typography>
                </GridFlexBox>
                <GridDivider />
                <Grid item display="flex" xs={12} md={4}>
                    {/*listing add, view and file add input field */}
                    <Grid container display="flex" spacing={1} alignContent={"flex-start"}>
                        <Grid item display="flex" xs={12} md={12} flexDirection={"column"}>
                            <Paper sx={{ width: "100%", padding: "8px" }}>
                                <Name color={currentListing?.listingId ? green[500] : yellow[800]}>
                                    Current Listing: {currentListing?.propertyName || "Please select a property"}
                                </Name>
                            </Paper>
                        </Grid>
                        <Grid item display="flex" xs={12} md={12} flexDirection={"column"}>
                            <Paper sx={{ width: "100%", padding: "8px" }}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Add Listing</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box display="flex" width="100%" gap={"8px"} justifyContent={"space-between"}>
                                            <Name>Listing:</Name>
                                            <TextField
                                                placeholder="Key in to add new listing."
                                                size="small"
                                                InputProps={addAdornment(handleClickAddListing)}
                                                value={newListingName}
                                                onChange={handleNewListing}
                                                style={{ width: "70%" }}
                                            />
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Search Listing By Field</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box
                                            display="flex"
                                            width="100%"
                                            gap={"8px"}
                                            justifyContent={"space-between"}
                                            sx={{ marginBottom: "10px" }}
                                        >
                                            <Name>Property Name:</Name>
                                            <TextField
                                                name="propertyName"
                                                size="small"
                                                value={searchObj.propertyName}
                                                onChange={handleSearchObj}
                                                style={{ width: "70%" }}
                                            />
                                        </Box>
                                        <Box
                                            display="flex"
                                            width="100%"
                                            gap={"8px"}
                                            justifyContent={"space-between"}
                                            sx={{ marginBottom: "10px" }}
                                        >
                                            <Name>Property Title:</Name>
                                            <TextField
                                                name="propertyTitle"
                                                size="small"
                                                value={searchObj.propertyTitle}
                                                onChange={handleSearchObj}
                                                style={{ width: "70%" }}
                                            />
                                        </Box>
                                        <Box
                                            display="flex"
                                            width="100%"
                                            gap={"8px"}
                                            justifyContent={"space-between"}
                                            sx={{ marginBottom: "10px" }}
                                        >
                                            <Name>Price:</Name>
                                            <TextField
                                                name="propertyPrice"
                                                size="small"
                                                value={searchObj.propertyPrice}
                                                onChange={handleSearchObj}
                                                style={{ width: "70%" }}
                                            />
                                        </Box>

                                        <Box
                                            display="flex"
                                            width="100%"
                                            gap={"8px"}
                                            justifyContent={"space-between"}
                                            sx={{ marginBottom: "10px" }}
                                        >
                                            <Name>State:</Name>
                                            <Select
                                                name="propertyState"
                                                labelId="propertyState"
                                                size="small"
                                                margin="dense"
                                                value={searchObj.propertyState}
                                                onChange={handleSearchObj}
                                                style={{ width: "70%" }}
                                            >
                                                {[...SELECT_ITEM.propertyState].sort().map((type) => (
                                                    <MenuItem key={type} value={type}>
                                                        {type}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Box>

                                        <Box
                                            display="flex"
                                            width="100%"
                                            gap={"8px"}
                                            justifyContent={"space-between"}
                                            sx={{ marginBottom: "10px" }}
                                        >
                                            <Name>City:</Name>
                                            <TextField
                                                name="propertyCity"
                                                size="small"
                                                value={searchObj.propertyCity}
                                                onChange={handleSearchObj}
                                                style={{ width: "70%" }}
                                            />
                                        </Box>

                                        <Box
                                            display="flex"
                                            width="100%"
                                            gap={"8px"}
                                            justifyContent={"space-between"}
                                            sx={{ marginBottom: "10px" }}
                                        >
                                            <Name>Township:</Name>
                                            <TextField
                                                name="propertyTownship"
                                                size="small"
                                                value={searchObj.propertyTownship}
                                                onChange={handleSearchObj}
                                                style={{ width: "70%" }}
                                            />
                                        </Box>

                                        <Box
                                            display="flex"
                                            width="100%"
                                            gap={"8px"}
                                            justifyContent={"space-between"}
                                            sx={{ marginBottom: "10px" }}
                                        >
                                            <Name>Poscode:</Name>
                                            <TextField
                                                name="propertyPoscode"
                                                size="small"
                                                value={searchObj.propertyPoscode}
                                                onChange={handleSearchObj}
                                                style={{ width: "70%" }}
                                            />
                                        </Box>

                                        <Box
                                            display="flex"
                                            width="100%"
                                            gap={"8px"}
                                            justifyContent={"space-between"}
                                            sx={{ marginBottom: "10px" }}
                                        >
                                            <Name>Address :</Name>
                                            <TextField
                                                name="propertyAddress"
                                                size="small"
                                                multiline
                                                rows={2}
                                                value={searchObj.propertyAddress}
                                                onChange={handleSearchObj}
                                                style={{ width: "70%" }}
                                            />
                                        </Box>

                                        <Box
                                            display="flex"
                                            width="100%"
                                            gap={"8px"}
                                            justifyContent={"space-between"}
                                            sx={{ marginBottom: "10px" }}
                                        >
                                            <Name>Purpose :</Name>
                                            <Select
                                                name="propertyPurpose"
                                                labelId="propertyPurpose"
                                                margin="dense"
                                                value={searchObj.propertyPurpose}
                                                onChange={handleSearchObj}
                                                size="small"
                                                style={{ width: "70%" }}
                                            >
                                                {[...SELECT_ITEM.propertyPurpose].sort().map((type) => (
                                                    <MenuItem key={type} value={type}>
                                                        {type}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Box>

                                        <Box
                                            display="flex"
                                            width="100%"
                                            gap={"8px"}
                                            justifyContent={"center"}
                                            sx={{ marginBottom: "10px" }}
                                        >
                                            <Button onClick={() => setSearchObj(defaultSearchObj)}>Clear</Button>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            </Paper>
                        </Grid>
                        <Grid item display="flex" xs={12} md={12} flexDirection={"column"}>
                            <Paper
                                sx={{
                                    width: "100%",
                                    padding: "8px",
                                    height: "30vh",
                                    overflowY: "auto",
                                    overflowX: "hidden"
                                }}
                            >
                                {displayListing
                                    .sort((a, b) => {
                                        if (a.propertyName < b.propertyName) {
                                            return -1;
                                        }
                                        if (a.propertyName > b.propertyName) {
                                            return 1;
                                        }
                                        return 0;
                                    })
                                    .map((list, index) => {
                                        return (
                                            <React.Fragment key={list.id || index}>
                                                <Box display={"flex"} width={"100%"}>
                                                    <Grid
                                                        key={index * 2}
                                                        item
                                                        xs={6}
                                                        alignSelf={"center"}
                                                        sx={{
                                                            cursor: "pointer"
                                                        }}
                                                    >
                                                        <Name
                                                            bold={list.id === currentListing?.id ? true : false}
                                                            onClick={() => handleListingSelection(list)}
                                                        >
                                                            {list?.propertyName}
                                                        </Name>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        alignSelf={"center"}
                                                        sx={{
                                                            cursor: "pointer"
                                                        }}
                                                    >
                                                        <Button
                                                            sx={{
                                                                color: list?.active === true ? green[500] : red[500]
                                                            }}
                                                            onClick={async () => {
                                                                console.log("list===", list);
                                                                const listingsCollectionRef = doc(
                                                                    db,
                                                                    "listings",
                                                                    list.id
                                                                );

                                                                // Await the Firestore update operation to complete
                                                                await updateDoc(listingsCollectionRef, {
                                                                    active: !list.active // Toggle the 'active' state
                                                                });

                                                                // Correctly update `currentListing` based on the result
                                                                if (currentListingId === list.id) {
                                                                    // Check if the current listing is the one being updated
                                                                    setCurrentListing((prevState) => ({
                                                                        ...prevState,
                                                                        active: !prevState.active // Correctly toggle the 'active' state based on the previous state
                                                                    }));
                                                                }
                                                            }}
                                                        >
                                                            {list?.active === true ? "Active" : "Disabled"}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <IconButton onClick={() => handleDuplicateListing(list)}>
                                                            <ContentCopyIcon />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <IconButton onClick={() => handleDeleteListing(list)}>
                                                            <Icon>delete</Icon>
                                                        </IconButton>
                                                    </Grid>
                                                </Box>
                                                <GridDivider />
                                            </React.Fragment>
                                        );
                                    })}
                            </Paper>
                        </Grid>
                        <Grid item display="flex" flexDirection={"column"} xs={12}>
                            <Paper sx={{ width: "100%", padding: "8px" }}>
                                <Grid item xs={12}>
                                    <Name>Property Images: </Name>
                                </Grid>
                                <GridDivider />
                                {images
                                    ?.sort((a, b) =>
                                        currentListing.propertyImg && currentListing.propertyImg.length
                                            ? currentListing.propertyImg.indexOf(a.id) -
                                                  currentListing.propertyImg.indexOf(b.id) || 1
                                            : 0
                                    )
                                    .map((image, index) => {
                                        return (
                                            <GridContainer key={image.downloadURL}>
                                                {/* First Column: Image and Filename */}
                                                <Grid item container display="flex" alignItems="center" xs={8}>
                                                    <Box display="flex" flexDirection="column" gap="8px">
                                                        <ListingImageComponent
                                                            image={image}
                                                            currentListingId={currentListing.id}
                                                            width={["100px", "150px"]}
                                                        />
                                                        <Name>
                                                            {image.name}
                                                            {index === 0 ? "  (COVER PHOTO)" : ""}
                                                        </Name>
                                                    </Box>
                                                </Grid>

                                                {/* Second Column: Delete Icon */}
                                                <Grid
                                                    item
                                                    container
                                                    display="flex"
                                                    alignItems="center"
                                                    justify="flex-end"
                                                    xs={4}
                                                >
                                                    <ArrowCircleUpIcon
                                                        onClick={() => moveItem(index, "up")}
                                                        style={{ cursor: "pointer" }}
                                                    />
                                                    <ArrowCircleDownIcon
                                                        onClick={() => moveItem(index, "down")}
                                                        style={{ cursor: "pointer", marginLeft: "10px" }}
                                                    />
                                                    <IconButton onClick={() => handleDeleteImageInListing(image)}>
                                                        <Icon>delete</Icon>
                                                    </IconButton>
                                                </Grid>

                                                <GridDivider />
                                            </GridContainer>
                                        );
                                    })}
                                {currentListing?.id && (
                                    <Box sx={{ marginTop: "8px" }}>
                                        <UploadListingFilesButton
                                            handleListingFile={handleListingFile}
                                            label="Add"
                                            currentListing={currentListing}
                                        />
                                    </Box>
                                )}
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                {/*listing details input field */}
                <Grid item display="flex" xs={12} md={4}>
                    <Grid container display="flex" spacing={2} alignContent={"flex-start"}>
                        <Grid
                            item
                            display="flex"
                            xs={12}
                            md={12}
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Paper
                                sx={{
                                    width: "100%",
                                    padding: "8px",
                                    minHeight: "100vh", // Changed height to minHeight
                                    overflowY: "auto",
                                    overflowX: "hidden"
                                }}
                            >
                                <Box display="flex" flexDirection="column" alignItems="center">
                                    <Grid
                                        item
                                        xs={10}
                                        alignSelf={"center"}
                                        sx={{
                                            cursor: "pointer",
                                            alignItems: "center",
                                            width: "100%"
                                        }}
                                    >
                                        <Stack spacing={2} style={{ width: "100%", marginTop: "20px" }}>
                                            <TextField
                                                name="propertyName"
                                                label="Property Title" //purposely reversed because audrey mix up title with name
                                                variant="outlined"
                                                margin="dense"
                                                value={currentListing?.propertyName || ""}
                                                onChange={handleChange}
                                                disabled={inputDisable}
                                            />
                                            <TextField
                                                name="propertyTitle"
                                                label="Property Name" //purposely reversed because audrey mix up title with name
                                                variant="outlined"
                                                margin="dense"
                                                multiline
                                                rows={4}
                                                value={currentListing?.propertyTitle || ""}
                                                onChange={handleChange}
                                                disabled={inputDisable}
                                            />
                                            <TextField
                                                name="propertyPrice"
                                                label="Property Price"
                                                variant="outlined"
                                                margin="dense"
                                                type="number"
                                                value={currentListing?.propertyPrice || ""}
                                                onChange={handleChange}
                                                disabled={inputDisable}
                                            />
                                            <FormControl fullWidth>
                                                <InputLabel id="propertyState">Property State</InputLabel>
                                                <Select
                                                    name="propertyState"
                                                    labelId="propertyState"
                                                    label="Property State"
                                                    margin="dense"
                                                    value={currentListing?.propertyState || ""}
                                                    onChange={handleChange}
                                                    disabled={inputDisable}
                                                >
                                                    {[...SELECT_ITEM.propertyState].sort().map((type) => (
                                                        <MenuItem key={type} value={type}>
                                                            {type}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                name="propertyCity"
                                                label="Property City"
                                                variant="outlined"
                                                margin="dense"
                                                value={currentListing?.propertyCity || ""}
                                                onChange={handleChange}
                                                disabled={inputDisable}
                                            />
                                            <TextField
                                                name="propertyTownship"
                                                label="Property Township"
                                                variant="outlined"
                                                margin="dense"
                                                value={currentListing?.propertyTownship || ""}
                                                onChange={handleChange}
                                                disabled={inputDisable}
                                            />

                                            <TextField
                                                name="propertyPoscode"
                                                label="Property Poscode"
                                                variant="outlined"
                                                margin="dense"
                                                value={currentListing?.propertyPoscode || ""}
                                                onChange={handleChange}
                                                disabled={inputDisable}
                                            />

                                            <TextField
                                                name="propertyAddress"
                                                label="Property Address"
                                                variant="outlined"
                                                margin="dense"
                                                type="number"
                                                multiline
                                                rows={4}
                                                value={currentListing?.propertyAddress || ""}
                                                onChange={handleChange}
                                                disabled={inputDisable}
                                            />

                                            <FormControl fullWidth>
                                                <InputLabel id="propertyPurpose">Property Purpose</InputLabel>
                                                <Select
                                                    name="propertyPurpose"
                                                    labelId="propertyPurpose"
                                                    label="Property Purpose"
                                                    margin="dense"
                                                    value={currentListing?.propertyPurpose || ""}
                                                    onChange={handleChange}
                                                    disabled={inputDisable}
                                                >
                                                    {[...SELECT_ITEM.propertyPurpose].sort().map((type) => (
                                                        <MenuItem key={type} value={type}>
                                                            {type}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                            <FormControl fullWidth>
                                                <InputLabel htmlFor="grouped-native-select">Property Type</InputLabel>
                                                <Select
                                                    native
                                                    name="propertyType"
                                                    id="grouped-native-select"
                                                    label="Property Type"
                                                    margin="dense"
                                                    value={currentListing?.propertyType || ""}
                                                    onChange={handleSelectChange}
                                                >
                                                    <option aria-label="None" value="" />
                                                    {Object.entries(SELECT_ITEM.propertyType).map(
                                                        ([category, types]) => (
                                                            <optgroup label={category} key={category}>
                                                                {types.map((type) => (
                                                                    <option value={type} key={type}>
                                                                        {type}
                                                                    </option>
                                                                ))}
                                                            </optgroup>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>

                                            <TextField
                                                name="landTitle"
                                                label="Land Title"
                                                variant="outlined"
                                                margin="dense"
                                                value={currentListing?.landTitle || ""}
                                                disabled={true}
                                            />

                                            <FormControl fullWidth>
                                                <InputLabel id="tenure">Tenure</InputLabel>
                                                <Select
                                                    name="tenure"
                                                    labelId="tenure"
                                                    label="Tenure"
                                                    margin="dense"
                                                    value={currentListing?.tenure || ""}
                                                    onChange={handleChange}
                                                    disabled={inputDisable}
                                                >
                                                    {[...SELECT_ITEM.tenure].sort().map((type) => (
                                                        <MenuItem key={type} value={type}>
                                                            {type}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                            <FormControl fullWidth>
                                                <InputLabel id="occupancy">Occupancy</InputLabel>
                                                <Select
                                                    name="occupancy"
                                                    labelId="occupancy"
                                                    label="Occupancy"
                                                    margin="dense"
                                                    value={currentListing?.occupancy || ""}
                                                    onChange={handleChange}
                                                    disabled={inputDisable}
                                                >
                                                    {[...SELECT_ITEM.occupancy].sort().map((type) => (
                                                        <MenuItem key={type} value={type}>
                                                            {type}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                            <FormControl fullWidth>
                                                <InputLabel id="bumiLot">Bumi Lot</InputLabel>
                                                <Select
                                                    name="bumiLot"
                                                    labelId="bumiLot"
                                                    label="Bumi Lot"
                                                    margin="dense"
                                                    value={currentListing?.bumiLot || ""}
                                                    onChange={handleChange}
                                                    disabled={inputDisable}
                                                >
                                                    {[...SELECT_ITEM.bumiLot].sort().map((lot) => (
                                                        <MenuItem key={lot} value={lot}>
                                                            {lot}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                            <TextField
                                                name="landAreaSize"
                                                label="Land Area Size (sq. ft.)"
                                                variant="outlined"
                                                margin="dense"
                                                value={currentListing?.landAreaSize || ""}
                                                onChange={handleChange}
                                                disabled={inputDisable}
                                            />

                                            <TextField
                                                name="propertyBuildUp"
                                                label="Property Build Up (sq. ft.)"
                                                variant="outlined"
                                                margin="dense"
                                                value={currentListing?.propertyBuildUp || ""}
                                                onChange={handleChange}
                                                disabled={inputDisable}
                                            />
                                            <FormControl fullWidth>
                                                <InputLabel id="propertyFurnishes">Furnishes</InputLabel>
                                                <Select
                                                    name="propertyFurnishes"
                                                    labelId="propertyFurnishes"
                                                    label="Furnishes"
                                                    margin="dense"
                                                    value={currentListing?.propertyFurnishes || ""}
                                                    onChange={handleChange}
                                                    disabled={inputDisable}
                                                >
                                                    {[...SELECT_ITEM.furnish].sort().map((furnish) => (
                                                        <MenuItem key={furnish} value={furnish}>
                                                            {furnish}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                            <TextField
                                                name="propertyBedRoom"
                                                label="Bedroom Count"
                                                variant="outlined"
                                                type="number"
                                                margin="dense"
                                                value={currentListing?.propertyBedRoom || ""}
                                                onChange={handleChange}
                                                disabled={inputDisable}
                                            />
                                            <TextField
                                                name="propertyBathRoom"
                                                label="Bathroom Count"
                                                variant="outlined"
                                                type="number"
                                                margin="dense"
                                                value={currentListing?.propertyBathRoom || ""}
                                                onChange={handleChange}
                                                disabled={inputDisable}
                                            />
                                            <TextField
                                                name="propertyCarPark"
                                                label="Carpark Count"
                                                variant="outlined"
                                                type="number"
                                                margin="dense"
                                                value={currentListing?.propertyCarPark || ""}
                                                onChange={handleChange}
                                                disabled={inputDisable}
                                            />
                                            <TextField
                                                name="propertyMaintenanceFee"
                                                label="Property Maintenance Fee"
                                                variant="outlined"
                                                margin="dense"
                                                type="number"
                                                multiline
                                                rows={4}
                                                value={currentListing?.propertyMaintenanceFee || ""}
                                                onChange={handleChange}
                                                disabled={inputDisable}
                                            />
                                            <FormControl fullWidth>
                                                <InputLabel id="facingDirection">Facing Direction</InputLabel>
                                                <Select
                                                    name="facingDirection"
                                                    labelId="facingDirection"
                                                    label="Facing Direction"
                                                    margin="dense"
                                                    value={currentListing?.facingDirection || ""}
                                                    onChange={handleChange}
                                                    disabled={inputDisable}
                                                >
                                                    {[...SELECT_ITEM.facingDirection].sort().map((direction) => (
                                                        <MenuItem key={direction} value={direction}>
                                                            {direction}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                name="propertyDesc"
                                                label="Property Description"
                                                variant="outlined"
                                                multiline
                                                rows={8}
                                                margin="dense"
                                                value={currentListing?.propertyDesc || ""}
                                                onChange={handleChange}
                                                disabled={inputDisable}
                                            />

                                            <TextField
                                                name="propertyRemark"
                                                label="Property Remark"
                                                variant="outlined"
                                                multiline
                                                rows={8}
                                                margin="dense"
                                                value={currentListing?.propertyRemark || ""}
                                                onChange={handleChange}
                                                disabled={inputDisable}
                                            />
                                            <TextField
                                                name="propertyWhatsappMessage"
                                                label="Whatsapp message to be shown to customer."
                                                variant="outlined"
                                                multiline
                                                rows={8}
                                                margin="dense"
                                                value={currentListing?.propertyWhatsappMessage || ""}
                                                onChange={handleChange}
                                                disabled={inputDisable}
                                            />

                                            <TextField
                                                name="propertySEOKeyword"
                                                label="Keywords for SEO. Separate each keyword by comma."
                                                variant="outlined"
                                                multiline
                                                rows={4}
                                                value={currentListing?.propertySEOKeyword || ""}
                                                onChange={handleChange}
                                                disabled={inputDisable}
                                                sx={{ marginBottom: "50px" }}
                                            />
                                        </Stack>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                {/*user bio header input field*/}
                <Grid item display="flex" xs={12} md={4}>
                    <Grid container display="flex" spacing={1} alignContent={"flex-start"}>
                        <Grid
                            item
                            display="flex"
                            xs={12}
                            md={12}
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Paper
                                sx={{
                                    width: "100%",
                                    padding: "8px",
                                    minHeight: "100vh", // Changed height to minHeight
                                    overflowY: "auto",
                                    overflowX: "hidden"
                                }}
                            >
                                <Box display="flex" flexDirection="column" alignItems="center">
                                    <Grid
                                        item
                                        xs={10}
                                        alignSelf={"center"}
                                        sx={{
                                            cursor: "pointer",
                                            alignItems: "center",
                                            width: "100%",
                                            justifyContent: "center"
                                        }}
                                    >
                                        <Stack
                                            direction="column"
                                            spacing={2}
                                            sx={{ margin: 2, alignItems: "center", width: "100%" }}
                                        >
                                            <label>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    id="fileInput"
                                                    style={{ display: "none" }}
                                                    onChange={handleAvatarChange}
                                                />
                                                {/* Avatar component with src dynamically set based on selectedImage */}
                                                <Avatar
                                                    alt={user.displayName || ""}
                                                    src={displayImage || userHeader.avatar} // Use selectedImage if available, otherwise use default
                                                    sx={{
                                                        width: 106,
                                                        height: 106,
                                                        flex: "none", // Prevent stretching
                                                        marginLeft: { xs: "auto", sm: 0 },
                                                        marginRight: { xs: "auto", sm: 2 },
                                                        display: "block",
                                                        boxShadow: 5,
                                                        marginBottom: 1
                                                    }}
                                                />
                                            </label>
                                            <Typography
                                                variant="h6"
                                                gutterBottom
                                                sx={{
                                                    fontWeight: "bold",
                                                    flex: 1,
                                                    textAlign: "center",
                                                    maxWidth: 400,
                                                    marginTop: 3
                                                }}
                                            >
                                                {user.displayName}
                                            </Typography>
                                            <Stack
                                                direction="column"
                                                spacing={2}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: {
                                                        xs: "center", // Align center for small screens
                                                        sm: "flex-start" // Align flex-start for larger screens
                                                    },
                                                    marginLeft: 2,
                                                    width: "100%"
                                                }}
                                            >
                                                <section
                                                    style={{ display: "flex", alignItems: "center", width: "100%" }}
                                                >
                                                    <DomainVerificationIcon />
                                                    <span style={{ marginLeft: 8, width: "100%" }}>
                                                        <TextField
                                                            name="agentDomain"
                                                            label="Domain(https://www.yourdomain.com)"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="dense"
                                                            value={userHeader.agentDomain}
                                                            onChange={(e) => {
                                                                setUserHeader((prevState) => ({
                                                                    ...prevState,
                                                                    agentDomain: e.target.value
                                                                }));
                                                            }}
                                                        />
                                                    </span>
                                                </section>
                                                <section
                                                    style={{ display: "flex", alignItems: "center", width: "100%" }}
                                                >
                                                    <FacebookIcon />
                                                    <span style={{ marginLeft: 8, width: "100%" }}>
                                                        <TextField
                                                            name="agentFB"
                                                            label="Facebook Link"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="dense"
                                                            value={userHeader.agentFB || ""}
                                                            onChange={(e) => {
                                                                setUserHeader((prevState) => ({
                                                                    ...prevState,
                                                                    agentFB: e.target.value
                                                                }));
                                                            }}
                                                        />
                                                    </span>
                                                </section>
                                                <section
                                                    style={{ display: "flex", alignItems: "center", width: "100%" }}
                                                >
                                                    <EmailIcon />

                                                    <span style={{ marginLeft: 8, width: "100%" }}>
                                                        <TextField
                                                            name="displayEmail"
                                                            label="Display Email"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="dense"
                                                            value={userHeader.displayEmail || ""}
                                                            onChange={(e) => {
                                                                setUserHeader((prevState) => ({
                                                                    ...prevState,
                                                                    displayEmail: e.target.value
                                                                }));
                                                            }}
                                                        />
                                                    </span>
                                                </section>
                                                <section
                                                    style={{ display: "flex", alignItems: "center", width: "100%" }}
                                                >
                                                    <LocalPhoneIcon />
                                                    <span style={{ marginLeft: 8, width: "100%" }}>
                                                        <TextField
                                                            name="displayNumber"
                                                            label="Display Number"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="dense"
                                                            value={userHeader.displayNumber || ""}
                                                            onChange={(e) =>
                                                                setUserHeader((prevState) => ({
                                                                    ...prevState,
                                                                    displayNumber: e.target.value
                                                                }))
                                                            }
                                                        />
                                                    </span>
                                                </section>

                                                <section
                                                    style={{ display: "flex", alignItems: "center", width: "100%" }}
                                                >
                                                    <WhatsAppIcon />
                                                    <span style={{ marginLeft: 8, width: "100%" }}>
                                                        <TextField
                                                            name="whatsapp"
                                                            label="Whatsapp"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="dense"
                                                            value={userHeader.whatsapp || ""}
                                                            onChange={(e) =>
                                                                setUserHeader((prevState) => ({
                                                                    ...prevState,
                                                                    whatsapp: e.target.value
                                                                }))
                                                            }
                                                        />
                                                    </span>
                                                </section>
                                            </Stack>

                                            <TextField
                                                name="agentCompany"
                                                label="Agent Company"
                                                variant="outlined"
                                                fullWidth
                                                margin="dense"
                                                value={userHeader.agentCompany || ""}
                                                onChange={(e) =>
                                                    setUserHeader((prevState) => ({
                                                        ...prevState,
                                                        agentCompany: e.target.value
                                                    }))
                                                }
                                            />

                                            <TextField
                                                name="agentCompanyAdd"
                                                label="Agent Company Address"
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                rows={4}
                                                margin="dense"
                                                value={userHeader.agentCompanyAdd || ""}
                                                onChange={(e) =>
                                                    setUserHeader((prevState) => ({
                                                        ...prevState,
                                                        agentCompanyAdd: e.target.value
                                                    }))
                                                }
                                            />

                                            <TextField
                                                name="aboutTitle"
                                                label="Title for About"
                                                variant="outlined"
                                                margin="dense"
                                                fullWidth
                                                value={userHeader.aboutTitle || ""}
                                                onChange={(e) =>
                                                    setUserHeader((prevState) => ({
                                                        ...prevState,
                                                        aboutTitle: e.target.value
                                                    }))
                                                }
                                            />

                                            <TextField
                                                name="aboutDesc"
                                                label="About Description"
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                rows={4}
                                                margin="dense"
                                                value={userHeader.aboutDesc || ""}
                                                onChange={(e) =>
                                                    setUserHeader((prevState) => ({
                                                        ...prevState,
                                                        aboutDesc: e.target.value
                                                    }))
                                                }
                                            />

                                            <Button
                                                variant="contained"
                                                onClick={handleSaveHeader}
                                                sx={{ width: "100%" }}
                                            >
                                                Save / Repost
                                            </Button>
                                            <Button
                                                variant="contained"
                                                onClick={handleView}
                                                sx={{ width: "100%" }}
                                                color="success"
                                            >
                                                View Page
                                            </Button>
                                        </Stack>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ListingPage;
