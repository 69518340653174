import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import convertDate from "../utils-functions/convertDate";

export function loadActions(lead, setActions, setLoading) {
    try {
        setLoading(true);
        const collectionRef = collection(db, "leads", lead.id, "actions");
        return onSnapshot(collectionRef, async (snapShot) => {
            if (snapShot.empty) {
                setLoading(false);
                setActions([]);
                return;
            }
            let actions = snapShot.docs.map((doc) => {
                const data = doc.data();

                return {
                    ...data,
                    leadId: lead.id,
                    id: doc.id,
                    created: convertDate(data.created),
                    userName: data.userName || data.user?.split("@")?.[0] || "",
                };
            });

            actions.sort((a, b) => new Date(b.created).valueOf() - new Date(a.created).valueOf());
            setLoading(false);
            setActions(actions);
        });
    } catch (error) {
        console.log(error);
        setLoading(true);
    }
}
