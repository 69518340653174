import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import RestorePageOutlinedIcon from "@mui/icons-material/RestorePageOutlined";
import {
    Box,
    CircularProgress,
    Container,
    Dialog,
    Divider,
    Grid,
    Icon,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import { grey } from "@mui/material/colors";
import {
    addDoc,
    collection,
    doc,
    DocumentData,
    onSnapshot,
    orderBy,
    query,
    QuerySnapshot,
    updateDoc,
    where,
} from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ImageGenerationInput } from "../../../functions/src/types/imageGenerationTypes";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import mapSnapshot from "../../utils-functions/mapSnapshot";

type User={
    id: string;
    imageGenerationCredit: string;
    role: string;
}

type Image ={
    id: string;
    downloadURL?: string;
    prompt: string;
    userId: string;
    status: string;
    date: Date;
}

type AuthContextType= {
    user: User | null;
}

export default function ImageGenerationPage() {
    const [prompt, setPrompt] = useState<string>("");
    const [images, setImages] = useState<Image[]>([]);
    const [viewAll, setViewAll] = useState<boolean>(false);
    const [currentImage, setCurrentImage] = useState<Image | null>(null);
    const [openLightBox, setOpenLightBox] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [company, setCompany] = useState<string>("black-forest-labs");
    const [model, setModel] = useState<string>("flux-pro");

    const { user } = useContext(AuthContext) as AuthContextType;

    const confirmation = useConfirmation();

    useEffect(() => {
        if (!user) return;

        let unsubscribe = () => {};

        if (!viewAll) {
            const collectionRef = collection(db, "imageGenerations");
            const q = query(
                collectionRef,
                where("userId", "==", user.id),
                orderBy("date", "desc"),
                where("status", "!=", "deleted")
            );

            unsubscribe = onSnapshot(q, (snapshot: QuerySnapshot<DocumentData>) => {
                const images = mapSnapshot(snapshot) as Image[];
                setImages(images);
            });
        } else {
            const collectionRef = collection(db, "imageGenerations");
            const q = query(collectionRef, orderBy("date", "desc"));
            unsubscribe = onSnapshot(q, (snapshot: QuerySnapshot<DocumentData>) => {
                const images = mapSnapshot(snapshot) as Image[];
                setImages(images);
            });
        }

        return unsubscribe;
    }, [user, viewAll]);

    const handleSend = async () => {
        if (!user) return;
        if (!prompt) return;

        let imageGenerationCredit = parseInt(user.imageGenerationCredit);
        if (!user.imageGenerationCredit) {
            toast.error("You don't have enough credit to generate image");
            return;
        }

        const data: ImageGenerationInput = {
            date: new Date(),
            prompt,
            guidance: 3,
            interval: 2,
            aspect_ratio: "1:1",
            safety_tolerance: 2,
            steps: 25,
            userId: user.id,
            provider: "replicate",
            company,
            model,
        };

        const collectionRef = collection(db, "imageGenerations");
        await addDoc(collectionRef, data);

        setPrompt("");

        imageGenerationCredit = parseInt(user.imageGenerationCredit) - 1;
        const docRef = doc(db, "users", user.id);
        await updateDoc(docRef, { imageGenerationCredit });

        toast.success("Image generation request sent successfully");
    };

    const handleDelete = async () => {
        if (!currentImage) return;

        const response = await confirmation("Delete Image", "Are you sure you want to delete this image?");

        if (!response) {
            setAnchorEl(null);
            return;
        }

        const docRef = doc(db, "imageGenerations", currentImage.id);
        await updateDoc(docRef, { status: "deleted" });

        toast.success("Image deleted successfully");
        setAnchorEl(null);
    };

    const handleRestore = async () => {
        if (!currentImage) return;

        const docRef = doc(db, "imageGenerations", currentImage.id);
        await updateDoc(docRef, { status: "active" });

        toast.success("Image restored successfully");
        setAnchorEl(null);
    };

    return (
        <Container>
            <Box display="flex" gap={1} mt={2} alignItems={"center"} justifyContent={"space-between"}>
                <Typography variant="h5" flex={1}>
                    DJC AI Image Generation
                </Typography>
                {user?.role === "Super Admin" && (
                    <>
                        <Typography>Model:</Typography>
                        <Select
                            onChange={(e: SelectChangeEvent) => setModel(e.target.value as string)}
                            value={model}
                        >
                            <MenuItem value={"flux-dev"}>flux-dev</MenuItem>
                            <MenuItem value={"flux-pro"}>flux-pro</MenuItem>
                        </Select>

                        <Typography>View all</Typography>
                        <Switch checked={viewAll} onChange={() => setViewAll((prev) => !prev)} />
                    </>
                )}

                <AccountBalanceWalletOutlinedIcon fontSize="large" />
                <Typography color={"green"} fontSize={"20px"}>
                    {user?.imageGenerationCredit || 0}
                </Typography>
            </Box>
            <Box width={"100%"} mt={1}>
                <TextField
                    fullWidth
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    placeholder="Your prompt to generate AI Image"
                    multiline
                    onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                        if (event.key === "Enter" && !event.shiftKey) {
                            event.preventDefault();
                            handleSend();
                        }
                    }}
                    rows={6}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleSend} disabled={!prompt}>
                                    <Icon>send</Icon>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <Box width={"100%"} my={2}>
                <Divider />
            </Box>
            <Box width={"100%"} mt={2}>
                <Grid container spacing={1}>
                    {images.map((image) => (
                        <Grid item key={image.id} xs={12} md={4}>
                            <Box gap={1} mt={1}>
                                {image.downloadURL ? (
                                    <Box display="flex" flexDirection={"column"}>
                                        <img
                                            src={image.downloadURL}
                                            alt={image.id}
                                            style={{
                                                width: "100%",
                                                cursor: "pointer",
                                                filter:
                                                    image.status === "deleted" ? "grayscale(100%)" : "none",
                                            }}
                                            onClick={() => {
                                                setOpenLightBox(true);
                                                setCurrentImage(image);
                                            }}
                                        />
                                        <Stack direction={"row"}>
                                            <Stack direction="column" flex={1}>
                                                <Typography variant="body2">{image.prompt}</Typography>
                                                <Typography
                                                    variant="caption"
                                                    color={grey[500]}
                                                >{`by ${image.userId}`}</Typography>
                                            </Stack>
                                            <Box>
                                                <IconButton
                                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                                        setCurrentImage(image);
                                                        setAnchorEl(e.currentTarget);
                                                    }}
                                                >
                                                    <Icon>more_vert</Icon>
                                                </IconButton>
                                            </Box>
                                        </Stack>
                                    </Box>
                                ) : (
                                    <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
                                        <CircularProgress />
                                        <Typography>{image.status}</Typography>
                                        <Typography variant="caption">{image.prompt}</Typography>
                                    </Box>
                                )}
                            </Box>
                        </Grid>
                    ))}
                </Grid>
                <br />
            </Box>

            <Dialog
                open={openLightBox}
                onClose={() => setOpenLightBox(false)}
                PaperProps={{ sx: { width: ["100vw", "45vw"], maxWidth: "none" } }}
            >
                <Box>
                    <a href={currentImage?.downloadURL} target="_blank" rel="noopener noreferrer">
                        <img
                            src={currentImage?.downloadURL}
                            alt={currentImage?.id}
                            style={{ width: "100%" }}
                        />
                    </a>
                </Box>
            </Dialog>

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                {currentImage?.status !== "deleted" && (
                    <Box>
                        <MenuItem onClick={handleDelete}>
                            <Stack direction={"row"} gap={1}>
                                <Icon color={"error"}>delete</Icon>
                                <Typography>Delete</Typography>
                            </Stack>
                        </MenuItem>
                        <MenuItem>
                            <Stack direction={"row"} gap={1}>
                                <Icon color={"primary"}>share</Icon>
                                <Typography>Share</Typography>
                            </Stack>
                        </MenuItem>
                    </Box>
                )}

                {currentImage?.status === "deleted" && (
                    <MenuItem onClick={handleRestore}>
                        <Stack direction={"row"} gap={1}>
                            <RestorePageOutlinedIcon />
                            <Typography>Restore</Typography>
                        </Stack>
                    </MenuItem>
                )}
            </Menu>
        </Container>
    );
}
