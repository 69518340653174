import { addDoc, arrayUnion, collection, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export async function addAction(lead, action, remark, user, files, apptm, others = {}) {
    console.log(apptm);
    console.log(others);
    try {
        //Adding the action into the actions subcollection inside leads collection

        const collecionRef = collection(db, "leads", lead.id, "actions");
        const actionData = {
            ...others,
            created: new Date(),
            user: user.email,
            action: action,
            remark: remark,
            files: files ? files : null,
            apptm: apptm || null,
            userName: user.displayName,
            leadId: lead.id,
        };

        const ref = await addDoc(collecionRef, actionData);
        console.log("Action " + action + " added into Firestore");

        if (lead.id.includes(":")) {
            const sourceLeadId = lead?.id?.split(":")[0];
            if (sourceLeadId) {
                const sourceLeadActionsRef = collection(db, "leads", sourceLeadId, "actions");
                await addDoc(sourceLeadActionsRef, { ...actionData, refId: ref.id });
            }
        }

        let lastAction;
        if (lead.lastAction) {
            if (Array.isArray(lead.lastAction)) {
                lastAction = [actionData, ...lead.lastAction];
            } else {
                lastAction = [actionData, lead.lastAction];
            }
        }
        // limit last action to 3
        if (lastAction.length > 3) {
            lastAction.pop();
        }

        //Adding or updating the attributes inside the leads collection
        let leadUpdateTemplate = {
            lastAction,
            actionedIds: arrayUnion(user.id),
        };

        //If there is no firstAction, then add one to it, else dont touch the firstAction
        if (!("firstAction" in lead)) {
            console.log("This lead doesnt have first action");
            leadUpdateTemplate = {
                ...leadUpdateTemplate,
                firstAction: actionData,
            };
        }

        const docRef = doc(db, "leads", lead.id);
        if (apptm) {
            await updateDoc(docRef, {
                ...leadUpdateTemplate,
                appointments: arrayUnion(apptm),
            });

            console.log("Update lastAction and appointment");
            console.log(actionData);
        } else if (action === "Booked Sale") {
            await updateDoc(docRef, {
                ...leadUpdateTemplate,
                bookedSales: arrayUnion(others),
            });

            console.log("Update lastAction and bookedSale");
            console.log(actionData);
        } else {
            await updateDoc(docRef, leadUpdateTemplate);

            console.log("Update lastAction");
            console.log(actionData);
            return ref.id;
        }
    } catch (error) {
        console.log(error);
    }
}
