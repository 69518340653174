import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, Button, Container, IconButton, TextField, Typography } from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { AuthContext } from "../context/AuthContext";
import { StoreContext } from "../context/StoreContext";
import { addAction } from "../functions/addAction";
import addAppointment from "../functions/addAppointment";
import addReminder from "../functions/addReminder";
import deleteAppointment from "../functions/deleteAppointment";
import loadAppointments from "../functions/loadAppointments";
import {
    ChipC,
    GridContainer,
    GridDivider,
    GridFlexBox,
    Loading,
    Name,
    TextFieldDate,
    Title,
} from "../themes/themes";
import convertDate from "../utils-functions/convertDate";
import formatDate from "../utils-functions/formatDate";
import getDay from "../utils-functions/getDay";
import useChooseFriend from "./../hooks/useChooseFriend";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default function ManageAppointments({ lead, handleClose }) {
    const DATE = formatDateForForm(new Date());
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(DATE);
    const [state, setState] = useState("");
    const [edit, setEdit] = useState(false);
    const [partners, setPartners] = useState([]);

    const { appointments, setAppointments, addUnsubscribeStore } = useContext(StoreContext);

    const { user } = useContext(AuthContext);

    useEffect(() => {
        if (!appointments) {
            console.log("loadAppointments");
            const unsubscribe = loadAppointments(user, setAppointments);
            addUnsubscribeStore(unsubscribe);
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(state);
        await handleAppointment(date, state, setLoading, lead, user, handleClose, partners);
    };

    const handleChange = (e) => {
        let { name, value } = e.target;
        setState({ ...state, [name]: value });
    };

    const handleDelete = async (appointment) => {
        await deleteAppointment(user, appointment);
    };

    const chooseFriends = useChooseFriend();

    const handleAddPartner = async () => {
        const response = await chooseFriends();
        // check whther the friend is already in the list
        if (response) {
            const { id } = response;
            const isExist = partners.find((partner) => partner.id === id);
            if (!isExist) {
                setPartners((state) => [...state, response]);
            }
        }
    };

    const handleDeletePartner = (partner) => {
        setPartners((state) => state.filter((item) => item.id !== partner.id));
    };

    return (
        <Container maxWidth="md" disableGutters>
            <GridContainer pl1 pr1>
                <GridFlexBox>
                    <Title>Manage Appointments</Title>
                </GridFlexBox>
                <GridDivider />
                <Loading loading={loading} />

                <GridDivider />
                {lead && (
                    <>
                        <GridFlexBox>
                            <Name ct bold>
                                {lead?.name}
                            </Name>
                        </GridFlexBox>
                        <GridDivider />
                    </>
                )}

                <GridFlexBox>
                    <TextFieldDate onChange={(e) => setDate(e.target.value)} value={date} />
                </GridFlexBox>
                <GridFlexBox>
                    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                        <Box my={1}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                name="venue"
                                label="venue"
                                onChange={handleChange}
                                required
                            />
                        </Box>
                        <Box my={1}>
                            <TextField
                                fullWidth
                                required
                                variant="outlined"
                                name="language"
                                label="language"
                                onChange={handleChange}
                            />
                        </Box>
                        <Box my={1}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                name="budget"
                                label="budget"
                                onChange={handleChange}
                            />
                        </Box>
                        <Box my={1}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                name="intention"
                                label="intention / remark"
                                onChange={handleChange}
                            />
                        </Box>
                        <Box my={1} display="flex">
                            <Typography sx={{ alignSelf: "center" }}>Notify friends:</Typography>
                            <IconButton onClick={handleAddPartner}>
                                <AddIcon />
                            </IconButton>
                        </Box>
                        <Box my={1} display={"flex"}>
                            {partners.map((partner) => (
                                <Box
                                    key={partner.id}
                                    display="flex"
                                    sx={{ border: "1px solid", borderRadius: "8px", padding: "4px" }}
                                >
                                    <Typography sx={{ alignSelf: "center" }}>
                                        {partner.displayName}
                                    </Typography>
                                    <IconButton size="small" onClick={() => handleDeletePartner(partner)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            ))}
                        </Box>
                        <Box my={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                startIcon={<AddIcon />}
                                type="submit"
                                disabled={loading}
                            >
                                Add
                            </Button>
                        </Box>
                    </form>
                </GridFlexBox>
                <GridDivider />
                <GridFlexBox xs={10}>
                    <Name>Scheduled Appointments</Name>
                </GridFlexBox>
                <GridFlexBox xs={2} fe>
                    <IconButton onClick={() => setEdit((state) => !state)}>
                        {edit ? <HighlightOffIcon /> : <EditIcon />}
                    </IconButton>
                </GridFlexBox>
                <GridDivider />
                {appointments?.map((appointment) => (
                    <React.Fragment key={appointment.id}>
                        <GridFlexBox fs xs={6}>
                            <Name ct bold>
                                Client name: {appointment.clientName}
                            </Name>
                        </GridFlexBox>

                        <GridFlexBox fe xs={6} gap={1}>
                            <ChipC color={getDay(appointment.date).color}>
                                {getDay(appointment.date).label}
                            </ChipC>
                            <Name ct>{displayDate(appointment.date)}</Name>
                            {edit && (
                                <IconButton
                                    style={{ padding: "0 0 0 8px" }}
                                    onClick={() => handleDelete(appointment)}
                                >
                                    <DeleteIcon color="warning" />
                                </IconButton>
                            )}
                        </GridFlexBox>
                        <GridFlexBox fs xs={12}>
                            <Name ct>Venue: {appointment.venue}</Name>
                        </GridFlexBox>
                        <GridDivider />
                    </React.Fragment>
                ))}
            </GridContainer>
        </Container>
    );
}

const displayDate = (date) => {
    return formatDate(convertDate(date), true);
};

async function handleAppointment(date, state, setLoading, lead, user, handleClose, partners) {
    const localDate = date.split("T")[0];
    const time = date.split("T")[1];
    const { venue, budget, language, intention } = state;
    const action = "Appointment";
    const remarkParts = [
        localDate ? "Date: " + moment(localDate).format("YYYY-MM-DD") : "",
        time ? "Time: " + time : "",
        venue ? "Venue: " + venue : "",
        language ? "Language: " + language : "",
        budget ? "Budget: " + budget : "",
        intention ? "Remark: " + intention : "",
    ];

    const remark = remarkParts.filter((part) => part !== "").join(" | ");

    setLoading(true);
    const appointment = {
        date: new Date(date),
        venue: state.venue || "",
        language: state.language || "",
        budget: state.budget || "",
        remarks: state.intention || "",
        leadId: lead.id || "",
        clientName: lead.name || "",
        clientEmail: lead.email || "",
        clientPhone: lead.phone || "",
        leadOwner: lead.ownerEmail || "",
        user: user.id,
        created: new Date(),
        updated: new Date(),
    };
    await addAction(lead, action, remark, user, null, { ...state, date, user: user.email });

    //Add reminder
    const message = `Dear ${user.displayName}, 
    \n<b>REMINDER</b> \nAppointment with <b>${lead.name}</b>: \nPhone: ${appointment.clientPhone} 
    \nRemark: ${appointment.remarks}
    \non <b>${displayDate(date)}</b>`;

    const reminderId = await addReminder(user, date, message, "appointment");

    //Add appointment
    await addAppointment(user, appointment, reminderId);

    // Add Label
    const label = {
        label: "Appointment",
        userEmail: user.id,
        fontColor: "#000000",
        name: "Appointment",
        color: "#fef23a",
    };

    if (lead.type === "ASSIGNED_LEAD") {
        const sourceLeadId = lead.id.split(":")[0];
        const docRef = doc(db, "leads", sourceLeadId);
        await updateDoc(docRef, { labels: arrayUnion(label), tags: arrayUnion("appointment") });
    }

    const leadRef = doc(db, "leads", lead.id);
    await updateDoc(leadRef, { labels: arrayUnion(label), tags: arrayUnion("appointment") });

    //Add partners
    partners.forEach(async (partner) => {
        if (!partner.chatId) {
            toast.error(`Please inform ${partner.displayName} to connect his Telegram for notification`);
        }
        const reminderId = await addReminder(partner, date, message, "appointment");
        await addAppointment(partner, appointment, reminderId);
        console.log("Appointment set for " + partner.displayName);
    });
    setLoading(false);

    console.log(remark);
    handleClose(appointment);
}

const formatDateForForm = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = "00";

    return `${year}-${month}-${day}T${hours}:${minutes}`;
};
