import axios from "axios";
import formatDate from "../../functions/common-functions/formatDate";
import addAppointmentV2 from "../../functions/addAppointmentV2";
import editAppointmentV2 from "../../functions/editAppointmentV2";
import deleteAppointmentV2 from "../../functions/deleteAppointmentV2";

// export default async function chatGptFunctions(prompt, setMessages, messages, model) {
const currentDate = formatDate(new Date());
//    let msgs = [
//       {
//             role: "system",
//             content: `You are a helpful assistant who answer questions from the user. Handle appointment requests with precision. Avoid assumptions and clarify ambiguous inputs. Only accept valid 12-hour times (1.00-12.59 AM/PM) and dates in DD/MM/YYYY format. If invalid, prompt for correction without self-correction or data use. Given today's date is ${currentDate}, calculate dates for appointments like "next Thursday" accurately. For instance, from 30 November 2023 (Sunday), "next Thursday" is 7 December 2023. Include logic or pseudocode for date calculations based on user-specified weekdays.`
//         },
//          { role: "user", content: prompt }
//      ];

//     const chatHistory = messages.map((msg) => ({ ...msg }));
//     msgs.splice(1, 0, ...chatHistory);
//     msgs.pop();
//     console.log(msgs);

const functions = [
    {
        name: "get_projects",
        description: "Get the price of a project",
        parameters: {
            type: "object",
            properties: {
                project: {
                    type: "string",
                    description: "The project name",
                },
            },
            required: ["project"],
        },
    },
    {
        name: "send_message",
        description: "Send whatsapp message to a number",
        parameters: {
            type: "object",
            properties: {
                to: {
                    type: "string",
                    description: "Whatsapp number. Has to be a phone number",
                },
                message: {
                    type: "string",
                    description: "The message to be sent",
                },
                name: {
                    type: "string",
                    description: "Name of the message recipient",
                },
            },
            required: ["to", "message", "name"],
        },
    },
];

//     try {
//         const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_KEY;

//         const headers = {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${OPENAI_API_KEY}`
//         };

//         let data = {
//             model: model,
//             messages: msgs,
//             functions: functions,
//             function_call: "auto"
//         };

//         try {
//             const response = await axios.post("https://api.openai.com/v1/chat/completions", data, { headers: headers });
//             console.log(response.data);

//             if (response["data"].choices[0].message.function_call) {
//                 let functionName = response["data"].choices[0].message.function_call.name;
//                 let functionArgs = response["data"].choices[0].message.function_call.arguments;
//                 console.log(functionArgs);

//                 let resolveFunctionResult, rejectFunctionResult;
//                 let functionResult = new Promise((resolve, reject) => {
//                     resolveFunctionResult = resolve;
//                     rejectFunctionResult = reject;
//                 });

//                 if (functionName === "get_projects") {
//                     getProjects()
//                         .then((result) => {
//                             resolveFunctionResult(JSON.stringify(result));
//                         })
//                         .catch((error) => {
//                             rejectFunctionResult(error);
//                         });
//                 }

// if (functionName === "send_message") {
//     const values = JSON.parse(functionArgs);
//     sendMessage(values.message, values.to)
//         .then((result) => {
//             resolveFunctionResult(JSON.stringify(result));
//         })
//         .catch((error) => {
//             rejectFunctionResult(error);
//         });
// }

//                 console.log(await functionResult);

//                 let newMessages = [
//                     ...messages,
//                     {
//                         role: "assistant",
//                         content: null,
//                         function_call: {
//                             name: functionName,
//                             arguments: functionArgs
//                         }
//                     },
//                     {
//                         role: "function",
//                         name: functionName,
//                         content: await functionResult
//                     }
//                 ];

//                 data = { ...data, messages: newMessages };
//                 const response2 = await axios.post("https://api.openai.com/v1/chat/completions", data, {
//                     headers: headers
//                 });
//                 console.log(response2["data"]);
//                 newMessages = [
//                     ...newMessages,
//                     { role: "assistant", content: response2["data"].choices[0].message.content }
//                 ];

//                 setMessages(newMessages);
//             } else {
//                 const message = response["data"].choices[0].message.content;
//                 let newMessages = [
//                     ...messages,
//                     {
//                         role: "assistant",
//                         content: message
//                     }
//                 ];
//                 setMessages(newMessages);
//                 return newMessages;
//             }
//         } catch (err) {
//             console.log(err);
//             return null;
//         }
//     } catch (error) {
//         console.error(error);
//         return null;
//     }
// }

const getProjects = async () => {
    return Promise.resolve([
        { project: "Hugoz", price: "RM1700psf" },
        { project: "Ceylonz", price: "RM1500psf" },
    ]);
};

const sendMessage = async (message, to, from = "keatweichonggmailcom") => {
    console.log(message, to, from);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        number: to,
        message: message,
        from: from,
    });

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };

    try {
        const response = await fetch("https://api.djc.ai/send-message", requestOptions);
        const result = await response.json();
        console.log(result);
        return Promise.resolve({ result: result.status ? "Message sent" : result.message });
    } catch (err) {
        console.log(err);
        return Promise.resolve({ result: "Failed sending message" });
    }
};

const secureAppointment = async (date, time, name) => {
    const message = `Hi ${name}, your appointment is secured on ${date} at ${time}`;
    console.log(message);
    return Promise.resolve({ result: "Appointment secured" });
};

const chatGptFunctionsV2 = async (prompt, setMessages, messages, model, email) => {
    //added by wayne 18-11-2023
    //v2 chatgpt function call to add appointment into firebase collection appointments
    let tempmodel = "gpt-3.5-turbo";
    let systemmsg = [];

    const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
    };
    const currentDate = new Date().toLocaleString("en-US", options);

    console.log("currentDate:", currentDate);

    //everytime come in, it should add system content to it to run, when setMessages, should remove the system content so that it wont display in chatbox.

    systemmsg.push({
        role: "system",
        content: `Don't make assumptions about what values to plug into functions.
    Ask for clarification if a user request is ambiguous.
    When a user provides a date or time, you must ensure that the date or time is valid.
    System must capture date, time, name ,customer contact and description in order to make appointment.
    Do not call function or make appointment when user input is not valid or not available. 
    Do not convert or assume corrections for invalid inputs. 
    Do not use your own data to make, book, create appointment.
    Instead, always request the user to provide the correct information.
    Given the context that the current date is ${currentDate}, your task is to make the ChatGPT model capable of understanding and responding to appointment requests. If the user asks to schedule an appointment for the next week, tommorow, 2 days later or a specific day like next Thursday, the model should be able to calculate the corresponding date by using any programming algorithm if needed.
    For instance, if today is 30 November 2023 and the user requests an appointment for next Thursday, the model should recognize that next Thursday is 7 December 2023. Here's an example instruction for the model:
    - If the user specifies a day (e.g., next Thursday), calculate the date based on the current day. For example, if today is Sunday (30 November 2023), next Thursday would be 7 December 2023 (current date + 4 days).
    Please ensure the model can handle different days of the week and accurately calculate the upcoming dates based on the user's requests. Feel free to include relevant programming logic or pseudocode in your response to showcase how the model should process such inquiries.

`,
    });
    // systemmsg.push({ role: "user", content: prompt });
    systemmsg = [...systemmsg, ...messages];

    const tools = [
        {
            type: "function",
            function: {
                name: "add_appointment",
                description: "create appointment with customer",
                parameters: {
                    type: "object",
                    properties: {
                        date: {
                            type: "string",
                            description: "The date of appointment, in dd-mm-yyyy format.",
                        },
                        time: {
                            type: "string",
                            description: "The time of appointment, in 0:00 am/pm format.",
                        },
                        custName: {
                            type: "string",
                            description: "The customer name for the appointment.",
                        },
                        custContact: {
                            type: "string",
                            description: "The customer contact.",
                        },
                        desc: {
                            type: "string",
                            description: "The description of the appointment.",
                        },
                    },
                    required: ["date", "time", "custName", "custContact", "desc"],
                },
            },
        },
        {
            type: "function",
            function: {
                name: "edit_appointment",
                description: "edit existing appointment details",
                parameters: {
                    type: "object",
                    properties: {
                        custContact: { type: "string", description: "The customer contact." },
                        date: {
                            type: "string",
                            description: "The date of appointment, in dd-mm-yyyy format.",
                        },
                        time: {
                            type: "string",
                            description: "The time of appointment, in 0:00 am/pm format.",
                        },

                        newDate: {
                            type: "string",
                            description: "The new date of appointment, in dd-mm-yyyy format.",
                        },
                        newTime: {
                            type: "string",
                            description: "The new time of appointment, in 0:00 am/pm format.",
                        },
                    },
                    required: ["custContact", "date", "time", "newDate", "newTime"],
                },
            },
        },
        {
            type: "function",
            function: {
                name: "delete_appointment",
                description: "delete an appointment",
                parameters: {
                    type: "object",
                    properties: {
                        custContact: { type: "string", description: "The customer contact." },
                        date: {
                            type: "string",
                            description: "The date of appointment, in dd-mm-yyyy format.",
                        },
                    },
                    required: ["custContact", "date"],
                },
            },
        },
    ];
    const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_KEY;

    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${OPENAI_API_KEY}`,
    };

    let data = {
        model: tempmodel,
        messages: systemmsg,
        tools: tools,
        tool_choice: "auto",
    };

    try {
        let response = await axios.post("https://api.openai.com/v1/chat/completions", data, {
            headers: headers,
        });

        const responseMessage = response.data.choices[0].message;

        // Step 2: check if the model wanted to call a function
        const toolCalls = responseMessage.tool_calls;

        if (responseMessage.tool_calls) {
            // Step 3: call the function
            // Note: the JSON response may not always be valid; be sure to handle errors
            const availableFunctions = {
                add_appointment: addAppointmentV2,
                edit_appointment: editAppointmentV2, // Assuming you have a function named editAppointment
                // delete_appointment: deleteAppointmentV2, // Assuming you have a function named deleteAppointment
            }; 
            systemmsg.push(response.data.choices[0].message); // extend conversation with assistant's reply
            console.log("response.data.choices[0].message:", response.data.choices[0].message);
            for (const toolCall of toolCalls) {
                const functionName = toolCall.function.name;
                const functionToCall = availableFunctions[functionName];
                const functionArgs = JSON.parse(toolCall.function.arguments);
                let functionResponse;
                switch (functionName) {
                    case "add_appointment":
                        functionResponse = await functionToCall(
                            functionArgs.date,
                            functionArgs.time,
                            functionArgs.custName,
                            functionArgs.custContact,
                            functionArgs.desc,
                            email
                        );
                      
                        break;
                    case "edit_appointment":
                        functionResponse = await functionToCall(
                            functionArgs.custContact,
                            functionArgs.date,
                            functionArgs.time,
                            functionArgs.newDate,
                            functionArgs.newTime,
                            email
                        );
                        break;
                    case "delete_appointment":
                        functionResponse = await functionToCall(
                            functionArgs.custContact,
                            functionArgs.date,
                            email
                        );
                        break;
                    default:
                        console.error("Unsupported function call: " + functionName);
                        break;
                }
                systemmsg.push({
                    tool_call_id: toolCall.id,
                    role: "tool",
                    name: functionName,
                    content: functionResponse,
                }); // extend conversation with function response
            }

            let secondData = {
                model: tempmodel,
                messages: systemmsg,
            };
            let test = [...systemmsg];
       
            let secondresponse = await axios.post("https://api.openai.com/v1/chat/completions", secondData, {
                headers: headers,
            });
         
            systemmsg.push(secondresponse.data.choices[0].message);
        } else {
            console.log("no function call needed.");
            systemmsg.push(response.data.choices[0].message);
        }
        systemmsg.shift();
        setMessages(systemmsg);

        return systemmsg;
        // const filteredMessages = systemmsg.filter((msg) => msg.role === "user" || msg.role === "assistant");

        // setMessages(filteredMessages);

        // return filteredMessages;
    } catch (err) {
        console.error("Error when calling API:", err.response ? err.response.data : err.message);
    }
};

export default chatGptFunctionsV2;
