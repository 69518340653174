import { Drawer } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { FloatingButton } from "../components/FloatingButton";
import FloatingToggle from "../components/FloatingToggle";
import { LeadsFlexBox } from "../components/LeadsFlexBox";
import { SideBar } from "../components/SideBar";
import { AuthContext } from "../context/AuthContext";
import { LeadsContext } from "../context/LeadsContext";
import { StoreContext } from "../context/StoreContext";
import getLeads from "../functions/getLeads";
import loadFolders from "../functions/loadFolders";

const now = new Date();
const END_DATE = new Date(now.setHours(23, 59, 59));
const START_DATE = new Date(now.setMonth(now.getMonth() - 12));
START_DATE.setHours(0, 0, 1);

const LEADS_MODE = ["ASSIGNED_LEADS", "SOURCE_LEADS"];

export default function AssignedLeadsPage() {
    const { user } = useContext(AuthContext);
    const { assignedLeads, setAssignedLeads, unsubscribeAssignedLeads, setUnsubscribeAssignedLeads } =
        useContext(LeadsContext);
    const { folderLeads, setFolderLeads, unsubscribeFolderLeads, setUnsubscribeFolderLeads } =
        useContext(LeadsContext);
    const { maxNumberAssignedLeads: maxNumber, setMaxNumberAssignedLeads: setMaxNumber } =
        useContext(LeadsContext);
    const { folders, setFolders, setUnsubscribeStore } = useContext(StoreContext);

    const [loading, setLoading] = useState(false);
    const [startDate] = useState(START_DATE);
    const [endDate] = useState(END_DATE);
    const [leadsMode] = useState(LEADS_MODE[0]);
    const [openSideBar, setOpenSideBar] = useState(false);
    const [folder, setFolder] = useState();
    const [open, setOpen] = useState(true);
    const [isFirstMount, setIsFirstMount] = useState(true);
    const [desktopView, setDesktopView] = useState(user.settings?.desktopView || false);
    const [sortByAssigned, setSortByAssigned] = useState(true);

    const leads = useMemo(() => {
        if (folder) {
            return folderLeads;
        }
        return assignedLeads;
    }, [assignedLeads, leadsMode, folder, folderLeads]);

    useEffect(() => {
        let unsubscribe = () => {};
        if (!folders) {
            unsubscribe = loadFolders(user, setFolders);
            setUnsubscribeStore((array) => {
                if (array) {
                    let newArray = [...array];
                    newArray.push(unsubscribe);
                    setUnsubscribeStore(newArray);
                } else {
                    setUnsubscribeStore([unsubscribe]);
                }
            });
        }
    }, []);

    //   Loading leads
    useEffect(() => {
        let unsubscribe = () => {};

        if (folder) {
            unsubscribeFolderLeads?.();
            unsubscribe = getData();
            setUnsubscribeFolderLeads(() => () => unsubscribe());
            return () => unsubscribe();
        }

        unsubscribe = getData();
        setUnsubscribeAssignedLeads(() => () => unsubscribe());
    }, [leadsMode, folder]);

    // load leads when maxNumber changes
    useEffect(() => {
        if (isFirstMount) {
            setIsFirstMount(false);
            return;
        }
        unsubscribeAssignedLeads?.();
        let unsubscribe = () => {};
        unsubscribe = getData();
        unsubscribeAssignedLeads, setUnsubscribeAssignedLeads;

        return () => {
            unsubscribe();
        };
    }, [maxNumber]);

    const getData = () => {
        let setFunction = () => {};
        if (folder) {
            setFunction = setFolderLeads;
        } else {
            setFunction = setAssignedLeads;
        }
        setLoading(true);
        return getLeads(user, setFunction, startDate, endDate, setLoading, leadsMode, folder, maxNumber);
    };

    const handleClickFolder = (folder) => {
        console.log(folder);
        setFolder((prevState) => {
            if (prevState?.id === folder?.id) {
                return prevState;
            } else {
                triggerSlide();
                return folder;
            }
        });
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setOpenSideBar(open);
    };

    const triggerSlide = () => {
        setOpen((state) => !state);
        setTimeout(() => {
            setOpen((state) => !state);
        }, 100);
    };

    const title = "Assigned Leads";

    return (
        <React.Fragment>
            <FloatingButton toggleDrawer={toggleDrawer} folder={folder} />
            <FloatingToggle desktopView={desktopView} setDesktopView={setDesktopView} />

            <LeadsFlexBox
                leads={leads}
                folder={folder}
                setFolder={setFolder}
                folders={folders}
                title={title}
                setMaxNumber={setMaxNumber}
                setLoading={setLoading}
                loading={loading}
                maxNumber={maxNumber}
                leadsMode={leadsMode}
                triggerSlide={triggerSlide}
                desktopView={desktopView}
                setDesktopView={setDesktopView}
                sortByAssigned={sortByAssigned}
                setSortByAssigned={setSortByAssigned}
            />

            <Drawer anchor={"left"} open={openSideBar} onClose={toggleDrawer(false)}>
                <SideBar
                    toggleDrawer={toggleDrawer}
                    folders={folders}
                    folder={folder}
                    setFolder={setFolder}
                    triggerSlide={triggerSlide}
                    handleClickFolder={handleClickFolder}
                    leadsMode={leadsMode}
                />
            </Drawer>
        </React.Fragment>
    );
}
