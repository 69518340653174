import {
    Autocomplete,
    Box,
    Chip,
    Divider,
    Icon,
    IconButton,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import { collection, deleteDoc, doc, getDocs, onSnapshot, query, updateDoc, where } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Handle, Position } from "reactflow";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import { Name } from "../../themes/themes";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";
import FlowContext from "./FlowContext";

export default function WhatsappFlowNode({ data, isConnectable }) {
    const { user } = useContext(AuthContext);
    const [block, setBlock] = useState();
    const { setUpdateTrigger } = useContext(FlowContext);
    const [flows, setFlows] = useState([]);
    const [currentFlow, setCurrentFlow] = useState(data?.aiTriggerFlowId || "");
    const [searchFlow, setSearchFlow] = useState("");
    const [filteredFlows, setFilteredFlows] = useState([]);
    const [selectedFlow, setSelectedFlow] = useState("");

    useEffect(() => {
        setFilteredFlows(flows.filter((flow) => flow.name.toLowerCase().includes(searchFlow.toLowerCase())));
    }, [flows, searchFlow]);

    // to display all available whatsappflow
    useEffect(() => {
        if (!data?.createdBy) return;

        const { createdBy } = data;
        const colRef = collection(db, "users", createdBy, "whatsappFlows");
        const q = query(colRef, where("status", "==", "active"));
        const unsubscribe = onSnapshot(
            q,
            (snapshot) => {
                const flows = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    name: doc.data().name,
                }));
                setFlows(flows);

                // Update selectedFlow if currentFlow exists
                if (currentFlow) {
                    const selectedFlow = flows.find((flow) => flow.id === currentFlow);
                    if (selectedFlow) {
                        console.log(selectedFlow.name);
                        setSelectedFlow(selectedFlow.name);
                    }
                }
            },
            (error) => {
                console.error("Error fetching data: ", error);
            }
        );

        return () => unsubscribe();
    }, [data?.createdBy, currentFlow]);

    useEffect(() => {
        console.log("data:", data);
        const { blockId, flowId, createdBy } = data;
        const docRef = doc(db, "users", createdBy, "whatsappFlows", flowId, "blocks", blockId);

        const unsubscribe = onSnapshot(docRef, (snap) => {
            const block = mapDocSnapshot(snap);
            setBlock(block);
        });

        return unsubscribe;
    }, [data]);

    useEffect(() => {
        if (flows.length > 0 && data.assignWhatsappFlow) {
            const currentFlow = flows.find((flow) => flow.id === data.assignWhatsappFlow);
            setSelectedFlow(currentFlow || null);
        } else {
            setSelectedFlow(null);
        }
    }, [flows, data.assignWhatsappFlow]);

    useEffect(() => {
        if (block) {
            const newStatus = selectedFlow?.name ? "active" : "inactive";
            if (block.status !== newStatus) {
                const updatedBlockObj = { ...block, status: newStatus };
                setBlock(updatedBlockObj); // Update local state

                const docRef = doc(db, "users", user.id, "whatsappFlows", block.flowId, "blocks", block.id);

                updateDoc(docRef, { status: newStatus }).then(() => {
                    setUpdateTrigger((prev) => prev + 1); // Trigger update in parent component
                });
            }
        }
    }, [selectedFlow]);

    const handleClickStatus = () => {
        const newStatus = block.status === "active" ? "inactive" : "active";
        if (selectedFlow) {
            const updatedBlockObj = { ...block, status: newStatus };
            setBlock(updatedBlockObj); // Update local state

            const docRef = doc(db, "users", user.id, "whatsappFlows", block.flowId, "blocks", block.id);

            updateDoc(docRef, { status: newStatus }).then(() => {
                setUpdateTrigger((prev) => prev + 1); // Trigger update in parent component
            });
        } else {
            toast.error("Flow must be selected before updating status.");
        }
    };

    //rerender status to bring rerender to parent component.
    const triggerRerender = () => {
        setUpdateTrigger((prev) => prev + 1); // Increment trigger to cause re-render
    };

    const confirmation = useConfirmation();

    const handleDelete = async () => {
        if (!block) return;

        const { blockId, flowId, createdBy } = data;
        const docRef = doc(db, "users", createdBy, "whatsappFlows", flowId, "blocks", blockId);

        try {
            const response = await confirmation("Delete block?", "Press OK to delete this block");
            if (!response) return;

            // delete all block relation that targetNodeId === blockId
            const blockRelationRef = collection(
                db,
                "users",
                createdBy,
                "whatsappFlows",
                flowId,
                "blocksRelation"
            );
            const q = query(blockRelationRef, where("targetNodeId", "==", blockId));

            // Execute the query to get the documents
            const querySnapshot = await getDocs(q);
            console.log("Number of documents found:", querySnapshot.size);

            // Iterate over each document and delete it
            for (const doc of querySnapshot.docs) {
                console.log("Document ID:", doc.id);
                // Create a reference to each document to be deleted
                const docRef = doc.ref;
                // Delete the document
                await deleteDoc(docRef);
                console.log(`Document with ID ${doc.id} successfully deleted!`);
            }

            // Get all blocks in the same flowId
            const blocksRef = collection(db, "users", createdBy, "whatsappFlows", flowId, "blocks");
            const allBlocksSnapshot = await getDocs(blocksRef);

            // Iterate through each block
            for (const blockDoc of allBlocksSnapshot.docs) {
                const blockData = blockDoc.data();
                if (Array.isArray(blockData.targetNodeId) && blockData.targetNodeId.includes(blockId)) {
                    // Remove blockId from the targetNodeId array
                    const updatedTargetNodeId = blockData.targetNodeId.filter((id) => id !== blockId);
                    // Update the block with the new targetNodeId array
                    await updateDoc(blockDoc.ref, { targetNodeId: updatedTargetNodeId });
                    console.log(`Updated block ${blockDoc.id} to remove targetNodeId ${blockId}`);
                }
            }

            // Delete the main block
            await deleteDoc(docRef);
            triggerRerender();
            toast.success("Whatsapp Node deleted successfully.");
        } catch (error) {
            console.error(error);
            toast.error("Failed to delete Whatsapp Node.");
        }
    };

    const handleSelectFlow = async (flow) => {
        let triggerFlowId = flow ? flow.id : "";
        setSelectedFlow(flow);

        const { blockId, flowId, createdBy } = data;
        const docRef = doc(db, "users", createdBy, "whatsappFlows", flowId, "blocks", blockId);

        // Check if the selected flow is different from the current assigned flow
        if (triggerFlowId !== data.assignWhatsappFlow) {
            updateDoc(docRef, { assignWhatsappFlow: triggerFlowId })
                .then(() => {
                    console.log(`Updated assignWhatsappFlow to ${triggerFlowId}`);
                })
                .catch((error) => {
                    console.error("Error updating assignWhatsappFlow: ", error);
                });
        }
    };

    return (
        <>
            <style>
                {`
        @keyframes blinkGlow {
            0%, 100% {
                box-shadow: 0 0 40px #f7c652; // Full intensity
            }
            50% {
                box-shadow: 0 0 20px #f7c652; // Reduced intensity
            }
        }
        .glowing {
            animation: blinkGlow 1s ease-in-out infinite;
            z-index: 999; // Added z-index
        }
    `}
            </style>
            <Paper sx={{ width: "300px" }} className={!selectedFlow ? "glowing" : ""}>
                {block?.blockType == "whatsappFlow" && (
                    <Handle
                        type="target"
                        position={Position.Left}
                        isConnectable={isConnectable}
                        style={{
                            background: "#525252",
                            width: "15px",
                            height: "15px",
                            borderRadius: "5px",
                            opacity: 0.5,
                        }}
                    />
                )}

                <Box p={1} display="flex" flexDirection={"column"} gap={1} className="nowheel">
                    <Box display="flex" alignItems={"center"} gap={1} justifyContent={"space-between"}>
                        <Box display={"flex"} gap={1} alignItems={"center"}>
                            <Typography>Whatsapp Flow Node</Typography>
                            <Chip
                                size="small"
                                label={block?.blockType}
                                color={block?.blockType === "whatsappFlow" ? "primary" : "error"}
                                onClick={user?.role === "Super Admin" ? () => console.log(block) : undefined}
                            />
                        </Box>
                        <IconButton size="small" onClick={() => handleClickStatus()}>
                            <Name color={block?.status === "active" ? green[500] : red[500]}>
                                {block?.status || "inactive"}
                            </Name>
                        </IconButton>
                        <IconButton size="small" onClick={handleDelete}>
                            <Icon>delete</Icon>
                        </IconButton>
                    </Box>

                    <>
                        {/* <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center" // Add this line if you want to center horizontally as well
                            gap={1}
                            className="nodrag"
                        >
                            <Button variant="contained">Search Whatsapp Flow</Button>
                        </Box> */}
                        <Divider>Selected Flows</Divider>
                        {/* {selectedFlow ? selectedFlow.name : "No flow selected"} */}
                        <Autocomplete
                            options={filteredFlows}
                            getOptionLabel={(option) => option?.name || ""}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select or search for a flow"
                                    variant="outlined"
                                />
                            )}
                            onChange={(event, newValue) => {
                                handleSelectFlow(newValue);
                            }}
                            value={selectedFlow}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            filterOptions={(options, { inputValue }) =>
                                options.filter((option) =>
                                    option.name.toLowerCase().includes(inputValue.toLowerCase())
                                )
                            }
                            // key is not needed here as it is not a valid prop for Autocomplete
                        />

                        <Divider />
                        {/* <Box
                            display="flex"
                            flexWrap="wrap"
                            gap={1}
                            sx={{
                                maxHeight: "10rem", // Adjust the height as needed
                                overflowY: "auto",
                                padding: "8px",
                            }}
                        >
                            {filteredFlows.map((flow) => (
                                <Tooltip key={flow.id} title={currentFlow === flow.id ? "Selected Flow" : ""}>
                                    <Chip
                                        key={flow.id}
                                        label={flow.name}
                                        // onClick={() => handleChipClick(flow)}
                                        sx={{
                                            backgroundColor: currentFlow === flow.id ? "green" : "gray",
                                            color: "white",
                                            "&:hover": {
                                                backgroundColor: currentFlow === flow.id ? "green" : "gray",
                                            },
                                        }}
                                    />
                                </Tooltip>
                            ))}
                        </Box> */}
                    </>
                </Box>
            </Paper>
        </>
    );
}
