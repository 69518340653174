import axios from "axios";

export default async function getState(clientId, secret) {
    console.log(clientId);
    let status = "disconnected"; // Default to disconnected unless proven otherwise
    let size = 0;

    const data = JSON.stringify({
        clientId: clientId,
        from: clientId,
        secret,
    });

    const configStatus = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://api.djc.ai/check-state",
        headers: {
            "Content-Type": "application/json",
        },
        data: data,
    };

    const configSize = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://api.djc.ai/get-directory-size",
        headers: {
            "Content-Type": "application/json",
        },
        data: data,
    };

    try {
        const [responseStatus, responseSize] = await Promise.all([
            axios.request(configStatus),
            axios.request(configSize),
        ]);

        if (responseStatus.data.status === true) {
            status = "ready";
        } else {
            status = "disconnected";
        }

        size = responseSize.data.size;
    } catch (error) {
        console.log(error);
        // Here, you don't need to change 'status' or 'size' because their defaults are set appropriately
    }

    return { status, size };
}
