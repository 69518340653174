import CancelIcon from "@mui/icons-material/Cancel";
import { Grid, IconButton, Slide, useTheme } from "@mui/material";
import { green, indigo, orange, pink, red } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { tokens } from "../../theme";
import { arrayIsEmpty } from "../../utils-functions/arrayIsEmpty";
import removeDuplicates from "../../utils-functions/removeDuplicates";
import { ButtonC, GridFlexBox, Name } from "../../themes/themes";
import { SOURCE_LEADS } from "../../settings/settings";

export default function SelectComponent2({
    select,
    data,
    setSelect,
    handleDelete,
    handleAssignLeads,
    handleUnassignLeads,
    handleAssignAdmin,
    handleUnassignAdmin,
    handleAddFolder,
    leadsMode,
}) {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {
        if (arrayIsEmpty(select)) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    }, [select]);

    const unselect = () => {
        setSelect([]);
    };

    const selectAll = () => {
        let newSelect = data.map((item) => item.id);
        newSelect = [...select, ...newSelect];
        newSelect = removeDuplicates(newSelect);
        setSelect(newSelect);
    };

    return (
        <Grid
            container
            display={open ? "flex" : "none"}
            position={"fixed"}
            bottom={70}
            left={"5vw"}
            width={"90vw"}
            height="120px"
            style={{ zIndex: 20000000 }}
            overflow={"auto"}
            alignItems={"center"}
        >
            <Slide direction="right" in={open}>
                <Grid
                    container
                    border={"solid 1px"}
                    style={{ backgroundColor: colors.primary[900] }}
                    padding={"8px"}
                >
                    <GridFlexBox xs={10} gap={1}>
                        <Name>{select.length}</Name>
                        <Name>items selected</Name>
                    </GridFlexBox>
                    <GridFlexBox xs={2}>
                        <IconButton onClick={unselect} size="small">
                            <CancelIcon />
                        </IconButton>
                    </GridFlexBox>
                    <GridFlexBox fw gap={1}>
                        <ButtonC bgColor={green[500]} fs10 small onClick={selectAll}>
                            Select All
                        </ButtonC>
                        <Name pr1>|</Name>
                        {leadsMode === `${SOURCE_LEADS}` && (
                            <ButtonC bgColor={red[500]} fs10 small onClick={handleDelete}>
                                Delete
                            </ButtonC>
                        )}
                        <ButtonC bgColor={orange[500]} fs10 small onClick={handleAssignLeads}>
                            Assign Leads
                        </ButtonC>
                        <ButtonC bgColor={pink[500]} fs10 small onClick={handleUnassignLeads}>
                            Unassign Leads
                        </ButtonC>
                        {leadsMode !== "ASSIGNED_LEADS" && (
                            <>
                                <ButtonC bgColor={pink[900]} fs10 small onClick={handleAssignAdmin}>
                                    Assign Admin
                                </ButtonC>
                                <ButtonC bgColor={pink[400]} fs10 small onClick={handleUnassignAdmin}>
                                    Unassign Admin
                                </ButtonC>
                            </>
                        )}
                        <ButtonC bgColor={indigo[500]} fs10 small onClick={handleAddFolder}>
                            Add to Folder
                        </ButtonC>
                    </GridFlexBox>
                </Grid>
            </Slide>
        </Grid>
    );
}
