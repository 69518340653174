import { useTheme } from "@emotion/react";
import { DoneAllOutlined } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button, Grid, IconButton, Link, Paper, styled, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { blue, green, grey, red } from "@mui/material/colors";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import { ExcelRenderer } from "react-excel-renderer";
import useList from "../context-utils/ListContext";
import { tokens } from "../theme";
import { ChipC, GridFlexBox, Name } from "../themes/themes";
import { arrayIsEmpty } from "../utils-functions/arrayIsEmpty";
import convertStringToCamelCase from "../utils-functions/convertStringToCamelCase";
import { notification } from "../utils-functions/notification";
import SelectFile from "./SelectFileComponent";
import UndoComponent2 from "./UndoComponent2";
import * as XLSX from "xlsx";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import convertDate from "./convertDate";

export default function UploadExcelCustomerHeader({
    handleUpload = () => {},
    customHeader = [],
    requiredFields,
    setRequiredFields,
}) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [headerIndex, setHeaderIndex] = useState(0);
    const [headerName, setHeaderName] = useState([]);
    const [originalHeaderName, setOriginalHeaderName] = useState([]);
    const [prevRows, setPrevRows] = useState();
    const [fieldMapping, setFieldMapping] = useState({});
    const [mapFields, setMapFields] = useState(false);
    const [mappedData, setMappedData] = useState([]);
    const [status, setStatus] = useState(false);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const MyInput = styled("input")({
        marginTop: 10,
        marginBottom: 10,
        fontFamily: "Roboto",
        paddingTop: 10,
        paddingBottom: 10,
        width: "100%",
        backgroundColor: "transparent",
        borderColor: grey[500],
        color: colors.grey[600],
        border: "0",
    });

    // useEffect(() => {
    //     console.log(rows);
    //     console.log(columns);
    //     if (!arrayIsEmpty(rows)) {
    //         const headerName = [...Array(columns.length)].map((item, i) => {
    //             const f = requiredFields.find((field) => field.mappedTo === i);
    //             if (f) return f.id;
    //             const camelCase = convertStringToCamelCase(rows[0][i]);
    //             return camelCase;
    //         });
    //         setHeaderName(headerName);
    //         setOriginalHeaderName(headerName);
    //     }
    // }, [rows, columns]);

    useEffect(() => {
        console.log(requiredFields);
    }, [requiredFields]);

    useEffect(() => {
        if (!arrayIsEmpty(rows)) handleSetCustomHeader();
    }, [rows]);

    const handleClickUpload = (files) => {
        // setRequiredFields((prevState) => {
        //     return prevState.map((field) => {
        //         return { ...field, set: false, mappedTo: null };
        //     });
        // });
        // console.log(files);
        fileHandler(files);
    };

    const fileHandler = (files) => {
        let fileObj = files[0];

        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
            const { rows, cols } = resp;
            if (err) {
                console.log(err);
            } else {
                const newRows = [];
                rows.forEach((row) => {
                    if (row[0]) {
                        newRows.push(row);
                    }
                });
                setRows(newRows);
                setColumns(cols);
            }
        });
    };

    const handleCheck = (i) => {
        setHeaderIndex(i);
    };

    const handleSetHeader = () => {
        if (!headerIndex) {
            notification("No header", "Press check the header box", "warning");
        } else {
            const newRows = rows.slice(headerIndex, rows.length);
            setRows((prevState) => {
                if (prevState && prevState === newRows) return prevState;
                setPrevRows(prevState);
                return newRows;
            });
            console.log(newRows);
            setHeaderIndex(0);
        }
    };

    const handleChange = (e) => {
        const { value, id } = e.target;
        const newHeaderName = [...headerName];
        newHeaderName[id] = value;
        setHeaderName(newHeaderName);
    };

    const convertToObject = async () => {
        if (arrayIsEmpty(rows)) {
            notification(
                "Empty array",
                "There is no data to convert. Make sure you uploaded an Excel file",
                "warning"
            );
            return;
        }

        const data = rows.map((row) => {
            let object = {};
            columns.forEach((column) => {
                object[column.field] = row[column.field] || "";
            });
            return object;
        });

        console.log(data);

        if (data) {
            handleUpload(data);

            setRows([]);
        } else {
            console.log("data is empty");
        }
    };

    const handleSetCustomHeader = () => {
        if (arrayIsEmpty(customHeader)) return;
        if (customHeader.length === rows[0].length) {
            setHeaderName(customHeader);
            notification("Success", "Added Custom Header", "success");
        } else {
            notification("Error", "Custom Header length is NOT the same as number of columns", "warning");
        }
    };

    const handleUndo = () => {
        setRows(prevRows);
        setHeaderIndex(0);
        setPrevRows();
    };

    const handleAutoMap = () => {
        headerName.forEach((header, i) => {
            const index = requiredFields.findIndex((field) => field.id === header);
            if (index !== -1) {
                setRequiredFields((prevState) => {
                    const newRequiredFields = [...prevState];
                    newRequiredFields[index].set = true;
                    newRequiredFields[index].mappedTo = i;
                    return newRequiredFields;
                });
            }
        });
    };

    const allMapped = useMemo(() => {
        return requiredFields?.every((item) => item.set);
    }, [requiredFields]);

    const displayRows = useMemo(() => {
        if (rows.length < 41) {
            return rows;
        } else {
            return rows.slice(0, 30).concat(rows.slice(rows.length - 10, rows.length));
        }
    }, [rows]);

    const generateGrid = (data) => {
        const columnHeaders = Object.keys(data[0]).map((header) => header.trim().toLowerCase());
        console.log("columnHeaders:", columnHeaders);

        if (columnHeaders.includes("name") && columnHeaders.includes("phone")) {
            // Define the columns based on the headers
            const columns = columnHeaders.map((key) => ({
                field: key,
                headerName: key,
                minWidth: 150,
                maxWidth: 350,
            }));

            // Process each row to trim strings and convert emails to lowercase
            const processedData = data.map((row) => {
                const newRow = {};
                Object.entries(row).forEach(([key, value]) => {
                    const lowerCaseKey = key.trim().toLowerCase();
                    if (typeof value === "string") {
                        // Trim the string
                        value = value.trim();
                        // Check if the key suggests it might contain an email
                        if (lowerCaseKey.includes("email")) {
                            value = value.toLowerCase();
                        }
                    }
                    newRow[lowerCaseKey] = value;
                });
                return newRow;
            });

            // Filter out rows where the first column is empty or undefined
            const filteredData = processedData.filter(
                (row) => row[columnHeaders[0]] !== undefined && row[columnHeaders[0]] !== ""
            );

            // Assign an ID to each row
            const rows = filteredData.map((row, index) => ({ id: index, ...row }));

            setColumns(columns);
            setRows(rows);
            return true;
        } else {
            console.log("no name or phone field.");
            notification("No header", "Excel header must have name and phone", "warning");
            return false;
        }
    };


    const handleDrop = (files) => {
        const file = files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];

            // Convert the worksheet to JSON starting from the second row
            // The first row will automatically be used as headers
            const jsonData = XLSX.utils.sheet_to_json(worksheet, {
                defval: "", // This sets empty cells to an empty string in your JSON
            });

            const response = generateGrid(jsonData);
            setStatus(response);
        };
        reader.readAsArrayBuffer(file);
    };

    // const handleMap = () => {
    //     const data = [];
    //     rows.forEach((row) => {
    //         const newRow = {};
    //         let stallionzCheck = false;
    //         fieldMapping.forEach((map) => {
    //             if (map.importField) {
    //                 // Check for project === "STALLIONZ"
    //                 if (map.objectField === "project" && row[map.excelField] === "STALLIONZ") {
    //                     stallionzCheck = true; // Set the flag to true if project is STALLIONZ
    //                 }
    //                 if (map.type === "date") {
    //                     if (row[map.excelField]) {
    //                         const newDate = convertDate(row[map.excelField]);

    //                         if (!newDate) {
    //                             console.log("Error converting date");
    //                             console.log(row);
    //                         }
    //                         newRow[map.objectField] = newDate;
    //                     }
    //                 } else if (map.objectField === "voucherNumberStart") {
    //                     const isNumber = isNumeric(row[map.excelField]);

    //                     if (isNumber) {
    //                         const start = parseInt(row[map.excelField]);
    //                         const end = parseInt(row["Voucher Number End"]);
    //                         const numberOfVouchers = end - start + 1;
    //                         newRow.vouchers = Array.from({ length: numberOfVouchers }, (_, i) => ({
    //                             voucherNumber: start + i,
    //                             voucherStatus: "active",
    //                         }));
    //                         newRow.voucherNumberStart = start;
    //                         newRow.voucherNumberEnd = end;
    //                     } else {
    //                         const prefixPattern = /^\D+/; // Matches non-digit characters at the start
    //                         const numberPattern = /\d+$/; // Matches digit characters at the end
    //                         const extractNumberPart = (str) => {
    //                             const match = str.match(numberPattern);
    //                             return match ? match[0] : "0"; // Default to '0' if no numbers are found
    //                         };
    //                         const extractPrefix = (str) => {
    //                             const match = str.match(prefixPattern);
    //                             return match ? match[0] : ""; // Default to empty string if no prefix is found
    //                         };
    //                         const leadingZeros = (num, len) => String(num).padStart(len, "0");

    //                         const startRaw = row[map.excelField];
    //                         const endRaw = row["Voucher Number End"];
    //                         console.log("---------------------");
    //                         console.log(startRaw);
    //                         console.log(endRaw);
    //                         console.log("---------------------");
    //                         const startPrefix = extractPrefix(startRaw);
    //                         const endPrefix = extractPrefix(endRaw);
    //                         const startNumberPart = extractNumberPart(startRaw);
    //                         const endNumberPart = extractNumberPart(endRaw);
    //                         // if (
    //                         //     !startNumberPart ||
    //                         //     !endNumberPart ||
    //                         //     startNumberPart === '0' ||
    //                         //     endNumberPart === '0'
    //                         // ) {
    //                         //     console.log(startNumberPart);
    //                         //     console.log(endNumberPart);

    //                         //     console.log('Invalid voucher number format');
    //                         //     return; // Exit the function or handle the error appropriately
    //                         // }
    //                         const startNumber = parseInt(startNumberPart, 10);
    //                         const endNumber = parseInt(endNumberPart, 10);
    //                         const numberOfVouchers = endNumber - startNumber + 1;
    //                         const numberLength = startNumberPart.length;

    //                         if (stallionzCheck) {
    //                             console.log("startRaw:", startRaw);
    //                             newRow.vouchers = Array.from({ length: numberOfVouchers }, (_, i) => ({
    //                                 voucherNumber: startRaw.replace("/", "_"),
    //                                 voucherStatus: "active",
    //                             }));
    //                             stallionzCheck = false;
    //                         } else {
    //                             newRow.vouchers = Array.from({ length: numberOfVouchers }, (_, i) => ({
    //                                 voucherNumber: `${startPrefix}${leadingZeros(startNumber + i, numberLength)}`,
    //                                 // voucherNumber: startNumber + i,
    //                                 voucherStatus: "active",
    //                             }));
    //                         }

    //                         newRow.voucherNumberStart = `${startPrefix}${leadingZeros(startNumber, numberLength)}`;
    //                         newRow.voucherNumberEnd = `${endPrefix}${leadingZeros(endNumber, numberLength)}`;
    //                     }
    //                 } else {
    //                     newRow[map.objectField] = row[map.excelField] || "";
    //                 }
    //             }
    //         });
    //         if (newRow.vouchers.length > 2) {
    //             console.log("more than 2:", newRow.vouchers);
    //         }
    //         data.push(newRow);
    //     });
    //     console.log(data);
    //     setMappedData(data);
    // };

    return (
        <>
            <Grid container>
                <GridFlexBox>
                    <SelectFile handleClickUpload={handleDrop} />
                </GridFlexBox>
                {/* <GridFlexBox fs>
                    <Name color={allMapped ? green[500] : red[500]}>
                        {allMapped ? "Everything ok" : "Required fields*"}
                    </Name>
                </GridFlexBox> */}

                {/* <GridFlexBox fs gap={1} m1 fw>
                    {requiredFields?.map((item, i) => !item.set && <ChipC key={item.id}>{item.label}</ChipC>)}
                </GridFlexBox> */}

                {/* {!arrayIsEmpty(rows) && (
                    <GridFlexBox fs>
                        <Button variant="contained" onClick={handleAutoMap}>
                            Auto Map
                        </Button>
                    </GridFlexBox>
                )} */}
                {status === true ? (
                    // <GridFlexBox fs>
                    //     <Button variant="contained" onClick={handleAutoMap}>
                    //         Auto Map
                    //     </Button>
                    // </GridFlexBox>
                    ""
                ) : (
                    <Name color={green[500]}>
                        *Excel header must contain &quot;name&quot; and &quot;phone&quot;.
                    </Name>
                )}

                <Paper style={{ overflow: "auto", width: "99vw", height: "40vh" }} variant="outlined">
                    <Box sx={{ height: "100%", width: "100%", overflowY: "auto" }} flex={1}>
                        <DataGrid
                            columns={columns}
                            rows={rows}
                            pageSize={25}
                            rowsPerPageOptions={[25, 50, 100]}
                            slots={{
                                noRowsOverlay: CustomNoRowsOverlay,
                            }}
                            sx={{ "--DataGrid-overlayHeight": "300px" }}
                        />
                    </Box>
                </Paper>
                <GridFlexBox>
                    <Box display="flex" gap={2} alignItems="center" flexWrap={"wrap"} mt={"8px"}>
                        {/* <Button variant="contained" onClick={handleSetHeader} disabled>
                            Set Header
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleSetCustomHeader}
                            color={"info"}
                            disabled={arrayIsEmpty(customHeader)}
                        >
                            Set Custom Header
                        </Button> */}
                        <Button variant="contained" onClick={convertToObject} color={"warning"}>
                            Upload
                        </Button>
                        <Typography variant="body2">Total number of row: {rows.length}</Typography>
                    </Box>
                </GridFlexBox>
                <br />
                <br />
                <br />
                <br />
            </Grid>
            <UndoComponent2 handleUndo={handleUndo} object={prevRows} setObject={setPrevRows} />
        </>
    );
}

const MySelect = ({ requiredFields, setRequiredFields, setHeaderName, j, originalHeaderName, allMapped }) => {
    const list = useList();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const handleClick = async () => {
        console.log("click");
        let objects = requiredFields.filter((field) => !field.set);
        const response = await list(
            [{ label: "Please select", id: "x" }, ...objects],
            "Map fields",
            "label",
            "face",
            false,
            false
        );
        if (response) {
            if (response.id === "x") {
                const index = requiredFields.findIndex((field) => field.mappedTo === j);
                if (index !== -1) {
                    const newRequiredFields = [...requiredFields];
                    setHeaderName((prev) => {
                        const newHeader = [...prev];
                        newHeader[j] = originalHeaderName[j];
                        return newHeader;
                    });
                    newRequiredFields[index].set = false;
                    delete newRequiredFields[index].mappedTo;
                    setRequiredFields(newRequiredFields);
                    return;
                } else {
                    return;
                }
            }
            const findExistingIndex = requiredFields.findIndex((field) => field.mappedTo === j);
            if (findExistingIndex !== -1) {
                setHeaderName((prev) => {
                    const newHeader = [...prev];
                    newHeader[j] = originalHeaderName[j];
                    return newHeader;
                });
                delete requiredFields[findExistingIndex].set;
                delete requiredFields[findExistingIndex].mappedTo;
            }
            const newItem = { ...response, mappedTo: j, set: true };
            const index = requiredFields.findIndex((field) => field.id === response.id);
            const newRequiredFields = [...requiredFields];
            newRequiredFields.splice(index, 1, newItem);
            setRequiredFields(newRequiredFields);
            setHeaderName((prev) => {
                const newHeader = [...prev];
                newHeader[j] = newItem.id;
                return newHeader;
            });
        }
    };

    const [label, set] = useMemo(() => {
        const f = requiredFields.find((field) => field.mappedTo === j);
        if (f) return [f.label, true];
        if (allMapped) return ["", false];
        return ["Please select", false];
    }, [requiredFields, j]);

    return (
        <Box
            onClick={handleClick}
            width={"100%"}
            border="1px solid"
            p={1}
            borderColor={grey[500]}
            display={"flex"}
            sx={{ cursor: "pointer", backgroundColor: set && blue[500] }}
            justifyContent={"space-between"}
            alignItems={"center"}
            color={set && "white"}
        >
            {label}
            {label ? <ArrowDropDownIcon /> : <DoneAllOutlined />}
        </Box>
    );
};

function isNumeric(str) {
    return /^\d+$/.test(str);
}
