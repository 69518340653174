import { CssBaseline } from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "primereact/resources/primereact.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";
import { ReactNotifications } from "react-notifications-component";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { ConfirmationProvider } from "./context-utils/ConfirmationContext";
import { InputProvider } from "./context-utils/InputContext";
import { ListProvider } from "./context-utils/ListContext";
import { NotificationProvider } from "./context-utils/NotificationContext";
import { AuthProvider } from "./context/AuthContext";
import { LeadsProvider } from "./context/LeadsContext";
import { StateProvider } from "./context/StateContext";
import { StoreProvider } from "./context/StoreContext";
import { UsersProvider } from "./context/UsersContext";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/en-gb";

import "./index.css";
import { getToken } from "firebase/messaging";
import { messaging } from "./firebase/firebase-utils";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const host = window.location.hostname;
if (host !== "localhost") {
    Sentry.init({
        dsn: "https://7bb159d11e9f487e98f1acc0c4028ce8@o4504715897470976.ingest.sentry.io/4504715898978304",
        integrations: [new BrowserTracing()],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.1,
    });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.Fragment>
        <ReactNotifications />
        <BrowserRouter>
            <NotificationProvider>
                <GoogleOAuthProvider clientId={clientId}>
                    <ConfirmationProvider>
                        <InputProvider>
                            <ListProvider>
                                <StateProvider>
                                    <UsersProvider>
                                        <StoreProvider>
                                            <LeadsProvider>
                                                <AuthProvider>
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDayjs}
                                                        adapterLocale={"en-gb"}
                                                    >
                                                        <CssBaseline />
                                                        <App />
                                                        <Toaster />
                                                    </LocalizationProvider>
                                                </AuthProvider>
                                            </LeadsProvider>
                                        </StoreProvider>
                                    </UsersProvider>
                                </StateProvider>
                            </ListProvider>
                        </InputProvider>
                    </ConfirmationProvider>
                </GoogleOAuthProvider>
            </NotificationProvider>
        </BrowserRouter>
    </React.Fragment>
);
