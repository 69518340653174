import PropTypes from "prop-types";
import { useCallback, useContext, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import { deleteEvent } from "../api/calendar";

import { Typography } from "@mui/material";
import { LocalizationProvider, MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { addDoc, collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useConfirmation } from "../../../context-utils/ConfirmationContext";
import { db } from "../../../firebase/firebase-utils";
import convertDate from "../../../utils-functions/convertDate";
import mapDocSnapshot from "../../../utils-functions/mapDocSnapshot";

export default function CalendarForm({ onClose, currentEventId }) {
    const [duration, setDuration] = useState(60);
    const { user } = useContext(AuthContext);
    const today = new Date();
    const [appObj, setAppObj] = useState({
        custContact: "",
        custName: "",
        description: "",
        startDate: dayjs(today),
        endDate: dayjs(today).add(duration, "minute"),
        
    });

    const confirmation = useConfirmation();
    
    useEffect(() => {
        console.log("Current event id: ", currentEventId);
        if (!currentEventId) return;
        const unsubscribe = onSnapshot(doc(db, "appointments", currentEventId), (doc) => {
            const currentEvent = mapDocSnapshot(doc);
            if (currentEvent) {
                setAppObj({
                    description: currentEvent?.description || "",
                    startDate: dayjs(convertDate(currentEvent?.startDate)) || dayjs(today),
                    endDate: dayjs(convertDate(currentEvent?.endDate)) || dayjs(today),
                    // paddingBefore: currentEvent?.paddingBefore || 0,
                    // paddingAfter: currentEvent?.paddingAfter || 0,
                    // createdBy: user.id,
                    // date: new Date(), // Set default date to today's date
                    // updatedDate: "",
                    clientId: currentEvent?.clientId,
                    // admins: [user.id],
                    custName: currentEvent?.custName || "",
                    custContact: currentEvent?.custContact || "",
                });
            }
        });
        return unsubscribe;
    }, [currentEventId]);

    useEffect(() => {
        const fetchDuration = () => {
            const docRef = doc(db, "calendarOffDays", user.email);

            return onSnapshot(docRef, (docSnap) => {
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setDuration(data.duration);
                } else {
                    console.log("No such document!");
                }
            });
        };

        fetchDuration();
    }, [user.clientId]);

    const handleSubmit = async () => {
        try {
       console.log("appObj:", appObj);
            const requiredFields = ["description", "startDate", "endDate", "custName", "custContact"];

            const emptyFields = requiredFields.filter(
                (field) => appObj[field] === null || appObj[field] === undefined || appObj[field] === ""
            );

            if (emptyFields.length > 0) {
                toast.error(`Please fill in all required fields: ${emptyFields.join(", ")}`);
                return;
            }

            // Special check for arrays
            // if (!Array.isArray(appObj.admins) || appObj.admins.length === 0) {
            //     toast.error("Admins list cannot be empty");
            //     return;
            // }
            const response = await confirmation("Save appointment", "Confirm to save appointment?");
            if (response) {
                const appointmentsCollection = collection(db, "appointments");
                
                let docRef;
                if (currentEventId) {
                    // Update existing document
                    await updateDoc(doc(appointmentsCollection, currentEventId), {
                        description: appObj.description,
                        custName: appObj.custName,
                        custContact: appObj.custContact,
                        startDate: appObj.startDate.toDate(),
                        endDate: appObj.endDate.toDate(),
                        updateDate: new Date(),
                        updateBy: user.clientId,
                      
                    });
                    toast.success("Appointment updated successfully!");
                } else {
                    const newAppointment = {
                        description: appObj.description,
                        custName: appObj.custName,
                        custContact: appObj.custContact,
                        startDate: appObj.startDate.toDate(),
                        endDate: appObj.endDate.toDate(),
                        clientId: user.clientId,
                        createdBy: user.email,
                        admins: [user.email],
                    };
                    // Add new document
                    docRef = await addDoc(appointmentsCollection, newAppointment);
                    toast.success("Appointment added successfully!");
                }
                onClose();
            }
        } catch (error) {
            toast.error(`Failed to ${currentEventId ? "update" : "add"} appointment: ${error.message}`);
            console.error(error);
        }
    };

    const onDelete = useCallback(async () => {
        try {
            await deleteEvent(`${currentEventId}`);

            onClose();
        } catch (error) {
            console.error(error);
        }
    }, [currentEventId, onClose]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setAppObj((prevAppObj) => ({
            ...prevAppObj,
            [name]: value,
        }));
    };

    const handleStartDateChange = (date) => {
        console.log(date);

        setAppObj((prevAppObj) => ({
            ...prevAppObj,
            startDate: date,
            endDate: date.add(duration, "minute"),
        }));
    };

    const handleEndDateChange = (date) => {
        console.log(date.toDate());
        setAppObj((prevAppObj) => ({
            ...prevAppObj,
            endDate: date,
        }));
    };

    const handlePaddingChange = (event) => {
        const { name, value } = event.target;

        setAppObj((prevAppObj) => ({
            ...prevAppObj,
            [name]: parseInt(value, 10) || 0,
        }));
    };

    const handleDelete = async () => {
        const response = await confirmation("Delete appointment", "Confirm to remove appointment?");
        if (response) {
            onDelete();
        }
    };

    

    return (
        <>
            <Stack spacing={1} sx={{ px: 3 }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
                            <Box sx={{ width: { xs: "100%", md: "100%" } }}>
                                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                    Appointment Start Date Time
                                </Typography>
                                <MobileDateTimePicker
                                    value={appObj.startDate || dayjs(today)}
                                    onChange={handleStartDateChange}
                                    inputFormat="DD MMMM YYYY" // Display format for the date
                                    renderInput={(params) => <TextField {...params} />} // Necessary to render the TextField
                                />
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
                            <Box sx={{ width: { xs: "100%", md: "100%" } }}>
                                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                    Appointment End Date Time
                                </Typography>
                                <MobileDateTimePicker
                                    value={appObj.endDate || dayjs(today)}
                                    onChange={handleEndDateChange}
                                    inputFormat="DD MMMM YYYY" // Display format for the date
                                    renderInput={(params) => <TextField {...params} />} // Necessary to render the TextField
                                />
                            </Box>
                        </Box>
                    </LocalizationProvider>
                </Box>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    Appointment Description
                </Typography>
                <TextField
                    name="description"
                    label="Description"
                    multiline
                    rows={3}
                    value={appObj.description}
                    onChange={handleChange}
                    sx={{ width: "100%" }}
                />
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    Customer Name
                </Typography>
                <TextField
                    name="custName"
                    value={appObj.custName}
                    onChange={handleChange}
                    sx={{ width: "100%" }}
                />
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    Customer Contact
                </Typography>
                <TextField
                    name="custContact"
                    value={appObj.custContact}
                    onChange={handleChange}
                    sx={{ width: "100%" }}
                />

                {/* <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Box sx={{ width: { xs: "100%", md: "48%" } }}>
                        <Typography variant="subtitle1" sx={{ mb: 1 }}>
                            Padding Before (minutes)
                        </Typography>
                        <TextField
                            type="number"
                            name="paddingBefore"
                            value={appObj.paddingBefore}
                            onChange={handlePaddingChange}
                            sx={{ width: "100%", display: "inline-block" }}
                        />
                    </Box>
                    <Box sx={{ width: { xs: "100%", md: "48%" } }}>
                        <Typography variant="subtitle1" sx={{ mb: 1 }}>
                            Padding After (minutes)
                        </Typography>
                        <TextField
                            type="number"
                            name="paddingAfter"
                            value={appObj.paddingAfter}
                            onChange={handlePaddingChange}
                            sx={{ width: "100%", display: "inline-block" }}
                        />
                    </Box>
                </Box> */}
            </Stack>

            <DialogActions>
                {!!currentEventId && (
                    <Tooltip title="Delete Event">
                        <Button variant="contained" onClick={handleDelete}>
                            Delete
                        </Button>
                    </Tooltip>
                )}

                <Button variant="contained" onClick={onClose}>
                    Cancel
                </Button>

                <Button variant="contained" onClick={handleSubmit}>
                    Save
                </Button>
            </DialogActions>
        </>
    );
}

CalendarForm.propTypes = {
    colorOptions: PropTypes.arrayOf(PropTypes.string),
    currentEvent: PropTypes.object,
    onClose: PropTypes.func,
};
