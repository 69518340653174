import DeleteIcon from "@mui/icons-material/Delete";
import { Icon, MenuItem, Select } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Container } from "@mui/system";
import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    orderBy,
    query,
    setDoc,
    updateDoc,
} from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import DataFields from "../components/DataFields";
import { useConfirmation } from "../context-utils/ConfirmationContext";
import { ListContext } from "../context-utils/ListContext";
import { AuthContext } from "../context/AuthContext";
import { LeadsContext } from "../context/LeadsContext";
import { StateContext } from "../context/StateContext";
import { StoreContext } from "../context/StoreContext";
import { UsersContext } from "../context/UsersContext";
import { db } from "../firebase/firebase-utils";
import loadUsersRT from "../functions/loadUsersRT";
import useChooseFriend from "../hooks/useChooseFriend";
import {
    ButtonC,
    GridContainer,
    GridDivider,
    GridFlexBox,
    GridItem,
    Loading,
    Name,
    Title,
} from "../themes/themes";
import addState from "../utils-functions/addState";
import mapDocSnapshot from "../utils-functions/mapDocSnapshot";
import mapSnapshot from "../utils-functions/mapSnapshot";
import { arrayIsEmpty } from "./../utils-functions/arrayIsEmpty";

export default function UserCardPage() {
    const { user, setUser, setSignInAsUser } = useContext(AuthContext);
    const { users, setUsers, setUnsubscribeUsers } = useContext(UsersContext);
    const { openGlobalList } = useContext(ListContext);
    const { state, setState } = useContext(StateContext);
    const { quickAccesses, setQuickAccesses, clearStore } = useContext(StoreContext);
    const { setSourceLeads, unsubscribeSourceLeads, setAssignedLeads, unsubscribeLeads } =
        useContext(LeadsContext);

    const [signInAs, setSignInAs] = useState();
    const [displayUser, setDisplayUser] = useState();
    const [loading, setLoading] = useState(false);

    const { param } = useParams();
    const navigate = useNavigate();
    const confirm = useConfirmation();

    useEffect(() => {
        if (!user) return;
        setDisplayUser(user);
    }, [user]);

    useEffect(() => {
        if (users) return;
        if (user.role === "Super Admin") {
            addState("authUser", user, state, setState);
            if (!users) loadData();
        } else {
            LoadAccessUsers();
        }

        if (param) {
            if (users) {
                const fUser = users.find((user) => user.id === param);
                if (fUser) setDisplayUser(fUser);
            }
        } else {
            setDisplayUser(user);
        }
    }, [user, users]);

    useEffect(() => {
        if (!state?.authUser) return;

        const loadData = async (authUser) => {
            const collectionRef = collection(db, "users", authUser.email, "quickAccesses");
            const q = query(collectionRef, orderBy("lastAccessed", "desc"));
            const snapshot = await getDocs(q);
            const quickAccesses = mapSnapshot(snapshot);
            setQuickAccesses(quickAccesses);
        };

        if (state.authUser) {
            loadData(state.authUser);
        }
    }, [state?.authUser]);

    const friendSelector = useChooseFriend();

    const updateQuickAccess = (user) => {
        const newQuickAccesses = quickAccesses.filter((item) => item.id !== user.id);
        setQuickAccesses([user, ...newQuickAccesses]);
    };

    const LoadAccessUsers = async () => {
        let users = [];
        const accessToIds = user.accessToIds || [];

        await Promise.all(
            accessToIds.map(async (id) => {
                const userRef = doc(db, "users", id);
                const snapshot = await getDoc(userRef);
                const tempUser = mapDocSnapshot(snapshot);
                users.push(tempUser);
            })
        );

        if (!arrayIsEmpty(users)) {
            setUsers([user, ...users]);
        }
    };

    const loadData = () => {
        loadUsersRT(user, setUsers, setUnsubscribeUsers, setLoading);
    };

    const handleClick = async (quickAccess) => {
        console.log(quickAccess);
        if (quickAccess) {
            signInAsFn(quickAccess);
            return;
        }

        const response = await openGlobalList(
            users,
            "Sign in as",
            "displayName",
            "face",
            false,
            true,
            "email"
        );

        if (response) {
            signInAsFn(response);
        }
    };

    const signInAsFn = async (member) => {
        if (!member?.id) return;

        // pull the latest user data
        const userRef = doc(db, "users", member.id);
        const snapshot = await getDoc(userRef);
        const signInUser = mapDocSnapshot(snapshot);

        // Save to quick access
        // user is the original master user
        // member is the user to sign in as
        const docRef = doc(db, "users", user.id, "quickAccesses", member.id);
        await setDoc(docRef, { ...signInUser, lastAccessed: new Date() });

        updateQuickAccess(signInUser);

        setDisplayUser(signInUser);
        setSignInAs(signInUser);
        setSignInAsUser(signInUser);
        setSourceLeads();
        setAssignedLeads();
        clearStore();
        unsubscribeSourceLeads?.();
        unsubscribeLeads?.forEach((fn) => fn?.());
    };

    const handleUpdate = (object, key, value) => {
        if (object.id === user.id) {
            const newUser = { ...user, [key]: value };
            setUser(newUser);
        }
    };

    const handleDeleteQuickAccess = async (id) => {
        const docRef = doc(db, "users", user.id, "quickAccesses", id);
        await deleteDoc(docRef);
        const newQuickAccesses = quickAccesses.filter((item) => item.id !== id);
        setQuickAccesses(newQuickAccesses);
    };

    const handleRemoveTeam = async (email, el) => {
        const response = await confirm(
            "Remove Team Member",
            "Are you sure you want to remove this sub account?"
        );

        if (response) {
            try {
                // Updating subAccount details
                const userRef = doc(db, "users", email);
                await updateDoc(userRef, {
                    superAccount: "none",
                    addOn: "none",
                });

                // Removing subAccount from mainUser's subAccounts
                const mainUserRef = doc(db, "users", user.id);
                const mainUserDoc = await getDoc(mainUserRef);
                if (mainUserDoc.exists()) {
                    const userData = mainUserDoc.data();
                    const subAccounts = userData.subAccounts || [];

                    subAccounts[el - 1] = "";
                    await updateDoc(mainUserRef, { subAccounts });
                    toast.success("Team member removed successfully.");
                } else {
                    toast.error("User document does not exist.");
                }
            } catch (err) {
                console.log("Error removing subAccount or updating mainUser:", err);
            }
        }
    };

    const handleAddTeam = async (el) => {
        const subAccount = await friendSelector();

        if (subAccount) {
            const response = await confirm(
                "Add Team Member",
                "Are you sure you want to add this sub account?"
            );
            if (response) {
                try {
                    // Updating subAccount details
                    const userRef = doc(db, "users", subAccount.id);
                    const subAccountDoc = await getDoc(userRef);
                    if (subAccountDoc.exists()) {
                        const subAccountData = subAccountDoc.data();
                        if (subAccountData.superAccount) {
                            toast.error("This sub-account is already assigned to a super account.");
                            return false;
                        }
                    } else {
                        toast.error("Sub-account document does not exist.");
                        return false;
                    }

                    // Updating subAccount details
                    const superAccount = user.id;
                    const addOn = "whatsappCloud";
                    await updateDoc(userRef, { superAccount, addOn });

                    //subAccount expiry or access should follow superAccount as well. if superAccount expired/unsubscribe/upgrade, subAccounts should be same as well.

                    // Checking and updating mainUser's subAccounts
                    const mainUserRef = doc(db, "users", user.id);
                    const mainUserDoc = await getDoc(mainUserRef);

                    if (mainUserDoc.exists()) {
                        const userData = mainUserDoc.data();
                        const subAccounts = userData.subAccounts || [];

                        if (subAccounts.includes(subAccount.id)) {
                            toast.error("This team member is already part of your sub accounts.");
                            return;
                        }

                        // Ensure the array has enough elements, filling with an empty string if necessary
                        for (let i = 0; i < el; i++) {
                            if (!subAccounts[i]) {
                                subAccounts[i] = ""; // Fill with the desired value, e.g., an empty string
                            }
                        }

                        // Set the specific element at the given index
                        subAccounts[el - 1] = subAccount.id;

                        await updateDoc(mainUserRef, { subAccounts });
                        toast.success("Team member added successfully.");
                    } else {
                        toast.error("User document does not exist.");
                    }
                } catch (err) {
                    console.log("Error updating subAccount or mainUser:", err);
                }
            }
        }
    };

    return (
        <Container maxWidth="sm">
            <GridContainer>
                <GridFlexBox>
                    <Title>User Card</Title>
                </GridFlexBox>
                <Loading loading={loading} />
                <GridDivider />
                <GridFlexBox w="10%" fs>
                    <Icon fontSize="large">face</Icon>
                </GridFlexBox>
                <GridItem w="90%" fs fs20>
                    {displayUser && displayUser.displayName}
                </GridItem>
                <GridDivider />
                <DataFields
                    object={displayUser}
                    collectionId={"users"}
                    handleUpdate={handleUpdate}
                    state={state}
                />

                <GridFlexBox w="30%" fs sx={{ backgroundColor: "red" }}>
                    <Name>Account Type:</Name>
                </GridFlexBox>
                <GridFlexBox w="60%" fs>
                    <Name whiteSpace="nowrap">
                        {displayUser?.addOnItems?.includes("masterAccount")
                            ? "Premium Plan"
                            : "Professional Plan"}
                    </Name>
                </GridFlexBox>
                <GridFlexBox w="10%" fe></GridFlexBox>

                {displayUser?.addOnItems?.includes("masterAccount")
                    ? (() => {
                          const number = displayUser?.numberOfSubAccounts || 2;
                          const array = Array.from({ length: number }, (_, index) => index + 1);

                          return array.map((el) => (
                              <React.Fragment key={el}>
                                  <GridFlexBox w="30%" fs sx={{ backgroundColor: "red" }}>
                                      <Name>{`Team member ${el}:`}</Name>
                                  </GridFlexBox>
                                  <GridFlexBox w="60%" fs>
                                      {Array.isArray(displayUser?.subAccounts) &&
                                      displayUser.subAccounts[el - 1] ? (
                                          ""
                                      ) : (
                                          <IconButton onClick={() => handleAddTeam(el)}>
                                              <Icon>add</Icon>
                                          </IconButton>
                                      )}

                                      <Name whiteSpace="nowrap">
                                          {Array.isArray(displayUser?.subAccounts)
                                              ? displayUser.subAccounts[el - 1] || ""
                                              : ""}

                                          {Array.isArray(displayUser?.subAccounts) &&
                                          displayUser.subAccounts[el - 1] ? (
                                              <IconButton
                                                  onClick={() =>
                                                      handleRemoveTeam(displayUser.subAccounts[el - 1], el)
                                                  }
                                              >
                                                  <Icon>delete</Icon>
                                              </IconButton>
                                          ) : (
                                              ""
                                          )}
                                      </Name>
                                  </GridFlexBox>
                                  <GridFlexBox w="10%" fe></GridFlexBox>
                              </React.Fragment>
                          ));
                      })()
                    : ""}

                <GridDivider />
                <SignInAsMember
                    state={state}
                    signInAs={signInAs}
                    handleClick={handleClick}
                    quickAccesses={quickAccesses}
                    handleClickQuickAccess={handleClick}
                    handleDeleteQuickAccess={handleDeleteQuickAccess}
                />
                <GridFlexBox fs w="50%">
                    <ButtonC color="warning" onClick={() => navigate(-1)} small>
                        Back
                    </ButtonC>
                </GridFlexBox>
                <GridFlexBox fe w="50%">
                    <ButtonC color="error" onClick={() => navigate("/sign-out")} small>
                        Sign Out
                    </ButtonC>
                </GridFlexBox>
                <GridDivider />
            </GridContainer>
        </Container>
    );
}

function SignInAsMember(props) {
    const { signInAs, handleClick, quickAccesses, handleClickQuickAccess, handleDeleteQuickAccess } = props;
    const [selectedQuickAccess, setSelectedQuickAccess] = React.useState("");

    const handleQuickAccessChange = (event) => {
        setSelectedQuickAccess(event.target.value);
        handleClickQuickAccess(event.target.value);
        console.log(event.target.value);
    };

    return (
        <React.Fragment>
            <GridItem fs fs20 w="30%">
                Sign in as:
            </GridItem>
            <GridFlexBox fs w="70%">
                <ButtonC small onClick={() => handleClick()}>
                    {signInAs ? signInAs.displayName : "Select member"}
                </ButtonC>
                <Select
                    value={selectedQuickAccess}
                    onChange={handleQuickAccessChange}
                    displayEmpty
                    size="small"
                    renderValue={(selected) => {
                        if (selected === "") {
                            return <em style={{ color: "#888" }}>Quick Access</em>;
                        }
                        return selected.email;
                    }}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {quickAccesses.map((access) => (
                        <MenuItem key={access.id} value={access}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%",
                                }}
                            >
                                {access.email}
                                <IconButton
                                    size="small"
                                    onClick={(event) => {
                                        event.stopPropagation(); // Prevent the select menu from closing
                                        handleDeleteQuickAccess(access.id);
                                    }}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </div>
                        </MenuItem>
                    ))}
                </Select>
            </GridFlexBox>
            <GridDivider />
        </React.Fragment>
    );
}
