import axios from "axios";

export default async function checkConnection(clientId, apiAddress, secret) {
    try {
        let data = JSON.stringify({
            from: clientId,
            secret,
        });

        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${apiAddress}check-state`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        const response = await axios.request(config);
        return response.data.state;
    } catch (error) {
        console.log(error);
    }
}
