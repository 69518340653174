import React, { useState } from "react";
import FullDialog from "../../components/FullDialog";
import UploadExcel2 from "../../components/UploadExcel2";
import UploadExcelCustomHeader from "../../components/UploadExcelCustomHeader";
import { Button } from "@mui/material";
import { Box } from "@mui/system";

export default function UploadExcelComponentV2({ handleUpload }) {
    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <Box m={"8px"}>
                <Button variant="contained" onClick={() => setOpen(true)}>
                    Upload Excel
                </Button>
                <FullDialog
                    Component={
                        <Box m={"8px"}>
                            <UploadExcelCustomHeader                               
                                handleUpload={(data) => {
                                    handleUpload(data);
                                    setOpen(false);
                                }}
                            />
                        </Box>
                    }
                    open={open}
                    handleClose={() => setOpen(false)}
                    title="Upload Excel"
                />
            </Box>
        </React.Fragment>
    );
}
