import { useTheme } from "@emotion/react";
import CottageIcon from "@mui/icons-material/Cottage";
import EventIcon from "@mui/icons-material/Event";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
} from "@mui/material";
import React, { useContext, useEffect, useMemo } from "react";
import { tokens } from "../theme";
import { useNavigate } from "react-router-dom";
import { loadFolders } from "../functions/loadFolders";
import { StoreContext } from "../context/StoreContext";
import { AuthContext } from "../context/AuthContext";

export const SideBar = ({ toggleDrawer, folder, handleClickFolder, leadsMode }) => {
    const { folders, setFolders } = useContext(StoreContext);
    const { user } = useContext(AuthContext);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    let selectedColor = colors.redAccent[400];

    const navigate = useNavigate();

    useEffect(() => {
        const getData = async () => {
            const folders = await loadFolders(user, setFolders);
            setFolders(folders);
        };
        getData();
    }, []);

    const handleClickFollowUp = () => {
        navigate("/follow-up-leads-page");
    };

    const homeTitle = useMemo(() => {
        if (leadsMode === "SOURCE_LEADS") {
            return "Source Leads";
        }
        if (leadsMode === "ASSIGNED_LEADS") {
            return "Assigned Leads";
        }
    }, [leadsMode]);

    return (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                <ListSubheader>Folders</ListSubheader>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => handleClickFolder()}>
                        <ListItemIcon>
                            <CottageIcon />
                        </ListItemIcon>
                        <ListItemText primary={homeTitle} />
                    </ListItemButton>
                </ListItem>
                {folders?.map((f) => (
                    <ListItem key={f.id} disablePadding>
                        <ListItemButton onClick={() => handleClickFolder(f)}>
                            <ListItemIcon>
                                <FolderOpenIcon style={{ color: f.id === folder?.id && selectedColor }} />
                            </ListItemIcon>
                            <ListItemText
                                primary={f.folderName}
                                style={{ color: f.id === folder?.id && selectedColor }}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
                <Divider style={{ marginBottom: "8px" }} />
                <ListSubheader>Follow up</ListSubheader>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => handleClickFollowUp()}>
                        <ListItemIcon>
                            <EventIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Follow Up Manager"} />
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
        </Box>
    );
};
