import React from "react";
import { Typography, Link } from "@mui/material";

export default function formatWhatsAppStyle(text) {
    // Function to detect and format links
    const formatLinks = (part) => {
        const linkRegex = /https?:\/\/[^\s]+/gi;

        const matches = part.match(linkRegex);
        if (!matches) return part;

        const parts = part.split(linkRegex);
        const formattedParts = [];

        parts.forEach((part, index) => {
            formattedParts.push(part);
            if (matches[index]) {
                formattedParts.push(
                    <Link
                        key={`link-${index}`}
                        href={matches[index]}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {matches[index]}
                    </Link>
                );
            }
        });

        return formattedParts;
    };

    // Split the text into parts by "*" (bold) and "_" (italic)
    const parts = text.split(/(\*.*?\*|_.*?_)/).filter(Boolean);

    return parts.map((part, index) => {
        if (part.startsWith("*") && part.endsWith("*")) {
            // Bold text
            return (
                <Typography key={index} component="span" sx={{ fontWeight: "bold" }}>
                    {formatLinks(part.slice(1, -1))}
                </Typography>
            );
        } else if (part.startsWith("_") && part.endsWith("_")) {
            // Italic text
            return (
                <Typography key={index} component="span" sx={{ fontStyle: "italic" }}>
                    {formatLinks(part.slice(1, -1))}
                </Typography>
            );
        } else {
            // Regular text
            return formatLinks(part);
        }
    });
}
