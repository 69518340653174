import { doc, getDoc } from "firebase/firestore";
import { useEffect } from "react";
import { db } from "../firebase/firebase-utils";
import version from "../settings/version";
import mapDocSnapshot from "../utils-functions/mapDocSnapshot";

const currentVersion = version || "1.0.0";

// Custom hook for checking app version
const useVersionCheck = () => {
    useEffect(() => {
        const checkAppVersion = async () => {
            try {
                const docRef = doc(db, "settings", "version");
                const snapshot = await getDoc(docRef);
                const data = mapDocSnapshot(snapshot);
                console.log("Fetched version:", data.version);
                console.log("Current version:", currentVersion);

                // Compare the fetched version with the current version
                if (data.version !== currentVersion) {
                    console.log("Version mismatch. Refreshing...");
                    // Refresh the page
                    window.location.reload(true);
                } else {
                    console.log("Version is up to date.");
                }
            } catch (error) {
                console.error("Error fetching version:", error);
            }
        };

        checkAppVersion();
        // Removed currentVersion from dependency array to avoid re-running
        // the effect on version change since it's intended to only run on component mount.
        // If currentVersion is expected to change during the component's lifecycle and you
        // wish to re-check the version when it does, you can add it back.
    }, []); // Empty dependency array ensures this runs once on mount
};

export default useVersionCheck;
