import { collection, query, where, getDocs, Timestamp } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

const checkOffDays = async (formattedProposedDateTime,email) => {
    if (!(formattedProposedDateTime instanceof Date && !isNaN(formattedProposedDateTime))) {
        console.error("Invalid date or time provided:");
        return true; // Consider the day off if the datetime is invalid
    }
 
    const dayOfWeek = formattedProposedDateTime.toLocaleDateString("en-US", { weekday: "long" });
    const dateString = formattedProposedDateTime.toISOString().split("T")[0]; // Format as "YYYY-MM-DD"
    const timeString = formattedProposedDateTime.toTimeString().split(" ")[0].substring(0, 5); // Format as "HH:MM"

    const offDaysRef = collection(db, "calendarOffDays");
    const q = query(offDaysRef, where("createdBy", "==", email)); // Query all documents as typically there might be one config document

    const querySnapshot = await getDocs(q);
    let isOffDay = false;
    querySnapshot.forEach((doc) => {
        const data = doc.data();

        // Check if the proposed date is a listed off day
        if (data.offDays && data.offDays.includes(dayOfWeek)) {
            console.log("Match found in offDays:", dayOfWeek);
            isOffDay = true;
        }

        // Check if the proposed date is a listed specific off date
        if (data.offDates && data.offDates.includes(dateString)) {
            console.log("Match found in offDates:", dateString);
            isOffDay = true;
        }

        // Check if the proposed time is outside the available time range
        if (timeString < data.avaiTimeFrom || timeString > data.avaiTimeTill) {         
            console.log("Time outside available hours:", timeString);
            isOffDay = true;
        }
    });

    return isOffDay;
};

export default checkOffDays;
