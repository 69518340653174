import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { toast } from "react-hot-toast";

import { Avatar, Box, Chip, Dialog, Icon, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import convertDate from "../../functions/common-functions/convertDate";
import formatDate from "../../functions/common-functions/formatDate";
import useColors from "../../hooks/useColors";
import FlowComponent from "./FlowComponent";
import formatWhatsAppStyle from "./formatWhatsappStyle";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";

export function ChatRoomComponent({ chatRoom = {}, handleClick }) {
    const [typographyWidth, setTypographyWidth] = useState("auto");
    const [openDialog, setOpenDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const number = chatRoom?.id?.split("-")[0] || "";

    const { user } = useContext(AuthContext);

    const containerRef = useRef(null);

    const colors = useColors();

    useEffect(() => {
        const calculateWidth = () => {
            if (containerRef.current) {
                const containerWidth = containerRef.current.offsetWidth;
                const iconButtonWidth = 52; // Adjust this based on actual IconButton size
                const calculatedWidth = containerWidth - iconButtonWidth - 8 * 2; // Subtract IconButton width and margins
                setTypographyWidth(calculatedWidth);
            }
        };

        // Calculate on mount and window resize
        calculateWidth();
        window.addEventListener("resize", calculateWidth);

        // Cleanup
        return () => window.removeEventListener("resize", calculateWidth);
    });

    const messageToDisplay = () => {
        if (chatRoom?.lastMessageType === "ptt") return "Voice message 🎤";
        if (chatRoom?.lastMessageType === "image") return "Image 📷";

        const lastMessage = chatRoom?.lastMessage;
        if (!lastMessage) return "";

        return lastMessage ? formatWhatsAppStyle(lastMessage) : "";
    };

    const handleClickMore = (event, chatRoom) => {
        setAnchorEl(event.currentTarget);
        console.log("clicked more", chatRoom);
        event.stopPropagation();
    };

    const handleClickFlow = (event) => {
        setAnchorEl(null);
        setOpenDialog(true);
    };

    const handleCloseMenu = (e) => {
        setAnchorEl(null);
        e.stopPropagation();
    };

    const handleDeleteFlow = async () => {
        const docRef = doc(db, "fbMessages", chatRoom.id);
        await updateDoc(docRef, { flowId: "", flowUserId: "", flowName: "" });
        toast.success("Flow deleted successfully");
        setAnchorEl(null);
    };

    const handleToggleFlow = async () => {
        const docRef = doc(db, "fbMessages", chatRoom.id);
        await updateDoc(docRef, { 
            disableDefaultFlow: !chatRoom.disableDefaultFlow 
        });
        toast.success(chatRoom.disableDefaultFlow 
            ? "Default Flow enabled" 
            : "Default Flow disabled");
        setAnchorEl(null);
    };

    const handleClickAssign = async (currentFlowId, currentFlow) => {
        const docRef = doc(db, "fbMessages", chatRoom.id);
        await updateDoc(docRef, { flowId: currentFlowId, flowUserId: user.id, flowName: currentFlow.name });
        toast.success("Flow assigned successfully");
        setOpenDialog(null);
    };

    return (
        <Box
            display="flex"
            flexDirection={"column"}
            width={"100%"}
            sx={{
                borderBottom: `1px solid ${colors.topBarLine}`,
                "&:hover": {
                    backgroundColor: colors.boxHighlight, // Replace 'primary.main' with your desired color
                },
                cursor: "pointer",
            }}
        >
            <Box
                display={"flex"}
                alignItems={"center"}
                width={"100%"}
                onClick={() => handleClick(chatRoom)}
                justifyContent={"space-between"}
                px="4px"
            >
                <Box p="8px" display="flex" width={"100%"} ref={containerRef}>
                    <Box>
                        <Avatar sx={{ width: "45px", height: "45px" }} />
                    </Box>
                    <Box display="flex" flexDirection={"column"} p="0 8px 0 8px" width={"100%"}>
                        <Box
                            display="flex"
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            width={`${typographyWidth}px`}
                        >
                            <Typography
                                variant="body1"
                                flex={1}
                                noWrap
                                sx={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    wordBreak: "break-word", // Break long words
                                }}
                            >
                                {chatRoom?.name || chatRoom?.id.split("-")[1]}
                            </Typography>
                            <Typography variant="caption" color="#71818a">
                                {chatRoom?.date ? formatDate(convertDate(chatRoom.date), true) : ""}
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            width={`${typographyWidth}px`} // Set width to calculated width because nowrap only works with fixed width
                        >
                            <Typography
                                variant="body2"
                                flex={1}
                                noWrap
                                sx={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    wordBreak: "break-word", // Break long words
                                }}
                            >
                                {messageToDisplay()}
                            </Typography>
                            {Boolean(chatRoom.unreadAI) && <Chip label={chatRoom.unreadAI} color="error" />}
                            {Boolean(chatRoom.unread) && <Chip label={chatRoom.unread} color="primary" />}
                            <IconButton size="small" onClick={(event) => handleClickMore(event, chatRoom)}>
                                <ExpandMoreIcon />
                            </IconButton>
                            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                                <MenuItem onClick={handleClickFlow}>
                                    <Box display="flex" gap={1} alignContent={"center"}>
                                        <Icon>assignment_return</Icon>
                                        <Typography>Assign AI Flow</Typography>
                                    </Box>
                                </MenuItem>
                                <MenuItem onClick={handleDeleteFlow}>
                                    <Box display="flex" gap={1} alignContent={"center"}>
                                        <Icon color="error">delete</Icon>
                                        <Typography>Delete AI Flow</Typography>
                                    </Box>
                                </MenuItem>
                                <MenuItem onClick={handleToggleFlow}>
                                    <Box display="flex" gap={1} alignItems="center">
                                        {chatRoom.disableDefaultFlow ? (
                                            <Icon color="primary">speaker_notes</Icon>
                                        ) : (
                                            <SpeakerNotesOffIcon color="error" />
                                        )}
                                        <Typography>
                                            {chatRoom.disableDefaultFlow ? "Enable" : "Disable"} AI Flow
                                        </Typography>
                                    </Box>
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box px={1} pb={1}>
                {chatRoom?.flowName && <Chip label={chatRoom.flowName} color="primary" size="small" />}{" "}
            </Box>
            <Box px={1} pb={1}>
                {chatRoom?.disableDefaultFlow && (
                    <Chip label="Default Flow Disabled" color="error" size="small" />
                )}{" "}
            </Box>
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                PaperProps={{
                    sx: {
                        width: { xs: "99vw", md: "70vw" }, // Adjust breakpoints as needed
                        height: "71vh",
                        maxWidth: "100%", // Ensure the dialog doesn't exceed the screen width
                    },
                }}
            >
                <FlowComponent user={user} onClose={handleClickAssign} />
            </Dialog>
        </Box>
    );
}
