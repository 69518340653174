import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../firebase/firebase-utils";
import { notification } from "../../utils-functions/notification";
import heic2any from "heic2any";
// import { AuthContext } from "../../context/AuthContext";
// import { useContext } from "react";

export async function uploadFileV2(file, fileRef, setProgress, user) {
    //modified by wayne 8-12-2023
    //if user upload the same filename, it will replace existing and will not create a new.
    //once it replaced the existing image, it will regenerate a new acess token hence the previous downloadURL that store in files.downloadURL
    //will be replaced with a new downloadURL+accesstoken, causing component that used the previous downloadURL showing corrupted file.
    //solution : allowing duplicate file by generating random key and append as file name. treat the new file as a different file.

    //     const { user } = useContext(AuthContext);
    // console.log("user:",user);
    try {
        if (!file) {
            return null;
        }

        let newFileName;
        const fileExists = await checkFileExists(fileRef);
        fileRef = fileRef.replace(/\.(heic|HEIC)$/, ".jpg");
        if (fileExists) {
            // If the file exists, generate a new filename with a 2-digit random key
            newFileName = generateNewFilename(fileRef);
        } else {
            // If the file doesn't exist, use the existing filename
            console.log("File does not exist. Using existing filename:", fileRef);
            newFileName = fileRef;
        }

        const storageRef = ref(storage, newFileName);

        const name = user.displayName;
        const phoneNumber = user.phone;
        const modifiedFile = await new Promise((resolve, reject) => {
            modifyImage(file, name, phoneNumber, (result) => {
                resolve(result);
            });
        });

        const uploadTask = uploadBytesResumable(storageRef, modifiedFile);

        await new Promise((resolve, reject) => {
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setProgress(progress);
                },
                (error) => {
                    console.log(error);
                    notification("Opps", error.message, "danger");
                    reject(error);
                },
                () => {
                    resolve();
                }
            );
        });

        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        setProgress(100);
        console.log("result:", downloadURL, newFileName);
        return { downloadURL, newFileName };
    } catch (err) {
        console.log("uploadFileV2:", err);
    }
}

const checkFileExists = async (fileRef) => {
    try {
        const url = await getDownloadURL(ref(storage, fileRef));
        console.log("url:", url);
        return true;
    } catch (error) {
        console.error("Normal Checking for file existence :", error);
        return false;
    }
};
function generateNewFilename(fileRef) {
    // Generate a 2-digit random key
    const randomKey = Math.floor(Math.random() * 100)
        .toString()
        .padStart(2, "0");

    // Extract the file extension (assuming the filename has an extension)
    const fileExtension = fileRef.split(".").pop();

    // Append the random key to the original filename (before the file extension)
    const newFilename = fileRef.replace(`.${fileExtension}`, `_${randomKey}.${fileExtension}`);

    return newFilename;
}

// function drawRoundedRect(ctx, x, y, width, height, radius) {
//     ctx.beginPath();
//     ctx.moveTo(x + radius, y);
//     ctx.arcTo(x + width, y, x + width, y + height, radius);
//     ctx.arcTo(x + width, y + height, x, y + height, radius);
//     ctx.arcTo(x, y + height, x, y, radius);
//     ctx.arcTo(x, y, x + width, y, radius);
//     ctx.closePath();
//     ctx.fill();
// }

// //this part is to add watermark to image before image upload into database.
// async function modifyImage(file, displayName, phone, onComplete) {
//     //to cater .HEIC type images
//     console.log(file, displayName, phone, onComplete);

//     if (file.type === "image/heic" || file.name.toUpperCase().endsWith(".HEIC")) {
//         // Convert HEIC to JPG

//         console.log("Convert HEIC to JPG");
//         const convertedBlob = await heic2any({
//             blob: file,
//             toType: "image/jpeg",
//             quality: 0.5 // Adjust quality as needed
//         });
//         file = new File([convertedBlob], file.name.replace(".HEIC", ".jpg"), { type: "image/jpeg" });
//     }else{
//         console.log("File type not .HEIC");
//     }

//     const reader = new FileReader();
//     reader.onload = async (e) => {
//         const image = new Image();
//         image.onload = async () => {
//             const canvas = document.createElement("canvas");
//             canvas.width = image.width;
//             canvas.height = image.height;
//             const ctx = canvas.getContext("2d");
//             ctx.drawImage(image, 0, 0);

//             // Dynamic font size relative to image size
//             const fontSize = Math.ceil(image.width / 40); // Smaller divider makes font larger
//             const lineHeight = fontSize * 1.2; // Less line height for a more compact look
//             ctx.font = `${fontSize}px Arial`;

//             // Define margins
//             const marginRight = 100;
//             const marginBottom = 100;

//             // Calculate text width and set bar width
//             const nameWidth = ctx.measureText(displayName).width;
//             const phoneWidth = ctx.measureText(phone).width;
//             const maxTextWidth = Math.max(nameWidth, phoneWidth);

//             // Determine the bar width including padding
//             const padding = 20;
//             const barWidth = maxTextWidth + 2 * padding;

//             // Set bar height, considering padding top and bottom
//             const barHeight = lineHeight * 2 + 2 * padding; // 2 lines of text

//             // Bar position (bottom-right corner with margins)
//             const barX = canvas.width - barWidth - marginRight;
//             const barY = canvas.height - barHeight - marginBottom;

//             // Draw the horizontal bar with rounded corners
//             ctx.fillStyle = "rgba(0, 0, 0, 0.3)"; // Slightly less opacity
//             drawRoundedRect(ctx, barX, barY, barWidth, barHeight, 20);
//             ctx.fill();

//             // Draw the name and phone number text inside the bar
//             ctx.fillStyle = "white";
//             ctx.fillText(displayName, barX + padding, barY + lineHeight + padding / 2);
//             ctx.fillText(phone, barX + padding, barY + lineHeight * 2 + padding / 2);

//             // Convert the canvas to a blob
//             canvas.toBlob((blob) => {
//                 onComplete(blob);
//             }, "image/jpeg");
//         };
//         image.src = e.target.result;
//     };

//     reader.readAsDataURL(file);
// }

async function modifyImage(file, displayName, phone, onComplete) {
    console.log(file, displayName, phone, onComplete);

    if (file.type === "image/heic" || file.name.toUpperCase().endsWith(".HEIC")) {
        console.log("Convert HEIC to JPG");
        // Assuming heic2any is already imported and available
        const convertedBlob = await heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 0.5 // Adjust quality as needed
        });
        file = new File([convertedBlob], file.name.replace(".HEIC", ".jpg"), { type: "image/jpeg" });
    } else {
        console.log("File type not .HEIC");
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
        const image = new Image();
        image.onload = async () => {
            const canvas = document.createElement("canvas");
            canvas.width = image.width;
            canvas.height = image.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0);

            // Dynamic font size relative to image size
            const fontSize = Math.ceil(image.width / 40); // Adjust the divider for different font size
            const lineHeight = fontSize * 1.2; // Adjust line height
            ctx.font = `${fontSize}px Arial`;

            // Calculate text width
            const nameWidth = ctx.measureText(displayName).width;
            const phoneWidth = ctx.measureText(phone).width;
            const maxTextWidth = Math.max(nameWidth, phoneWidth);

            // Define bar size and position
            const padding = 20;
            const barWidth = maxTextWidth + 2 * padding; // Bar width based on text width
            const barHeight = lineHeight * 2 + 2 * padding; // 2 lines of text

            // Centering the bar and text in the image
            const barX = (canvas.width - barWidth) / 2; // Center horizontally
            const barY = (canvas.height - barHeight) / 2; // Center vertically

            // Draw the bar with background
            ctx.fillStyle = "rgba(0, 0, 0, 0.3)"; // Slightly transparent
            drawRoundedRect(ctx, barX, barY, barWidth, barHeight, 20); // Assuming drawRoundedRect is defined
            ctx.fill();

            // Draw text over the bar
            ctx.fillStyle = "white";
            ctx.fillText(displayName, barX + padding, barY + lineHeight + padding / 2);
            ctx.fillText(phone, barX + padding, barY + lineHeight * 2 + padding / 2);

            // Convert the canvas to a blob
            canvas.toBlob((blob) => {
                onComplete(blob);
            }, "image/jpeg");
        };
        image.src = e.target.result;
    };

    reader.readAsDataURL(file);
}

// Assuming drawRoundedRect is a function that draws a rounded rectangle on the canvas
function drawRoundedRect(ctx, x, y, width, height, radius) {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();
}
