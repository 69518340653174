import ClearIcon from "@mui/icons-material/Clear";
import { Box, Container, IconButton, TextField, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { StoreContext } from "../context/StoreContext";
import addLabel from "../functions/addLabel";
import deleteLabel from "../functions/deleteLabel";
import loadLabels from "../functions/loadLabels";
import updateLead from "../functions/updateLead";
import { ButtonC, GridContainer, GridDivider, GridFlexBox, IconButtonC, Name, Title } from "../themes/themes";
import toast from "react-hot-toast";

const colors = [
    { color: "#3b93ff", fontColor: "#ffffff" },
    { color: "#5cfaff", fontColor: "#000000" },
    { color: "#c62828", fontColor: "#ffffff" },
    { color: "#00acc1", fontColor: "#000000" },
    { color: "#f48fb1", fontColor: "#000000" },
    { color: "#26a69a", fontColor: "#000000" },
    { color: "#bf360c", fontColor: "#ffffff" },
    { color: "#263238", fontColor: "#ffffff" },
    { color: "#f48fb1", fontColor: "#000000" },
    { color: "#1b5e20", fontColor: "#ffffff" },
    { color: "#ffb74d", fontColor: "#000000" },
    { color: "#546e7a", fontColor: "#ffffff" },
];

const labels = [
    "Appointment",
    "Booked",
    "Converted",
    "Presented",
    "Potential",
    "Details sent",
    "Follow up",
    "Pass Back",
    "No potential",
    "Spam",
];

const labelColors = [
    "#fef23a",
    "#5eff17",
    "#003454",
    "#f46767",
    "#d5a65d",
    "#47e2b8",
    "#3b93ff",
    "#7b7b7b",
    "#3A0000",
    "#353232",
];

const fontColors = [
    "#000000",
    "#000000",
    "#ffffff",
    "#ffffff",
    "#ffffff",
    "#000000",
    "#ffffff",
    "#ffffff",
    "#ffffff",
    "#ffffff",
];

const Label = ({ children, color, fontColor, onClick }) => {
    if (!children) return <div></div>;
    return (
        <Typography
            style={{
                fontSize: 12,
                border: "1px solid",
                borderRadius: 7,
                paddingLeft: 7,
                paddingRight: 7,
                backgroundColor: color,
                color: fontColor,
                borderColor: color,
                cursor: "pointer",
            }}
            onClick={onClick}
        >
            {children}
        </Typography>
    );
};

export default function ManageLabels({ lead, setLead, handleClose }) {
    const [newLabel, setNewLabel] = useState("");
    const { user } = useContext(AuthContext);
    const { store, setStore } = useContext(StoreContext);
    const customLabels = store?.labels;

    useEffect(() => {
        if (!customLabels) {
            loadData();
        }
    }, []);

    const loadData = async () => {
        const customLabels = await loadLabels(user);
        if (customLabels) setStore((store) => ({ ...store, labels: customLabels }));
    };

    const handleChange = (e) => {
        const { value } = e.target;
        setNewLabel(value);
    };

    const handleAdd = async (color, labelName, addToDB) => {
        let newLabels = [];
        if (lead.labels) newLabels = [...lead.labels];
        const newLabel = { ...color, label: labelName, name: labelName, userEmail: user.id };
        newLabels.push(newLabel);
        let newLead = { ...lead };
        newLead["labels"] = newLabels;
        setLead(newLead);

        if (addToDB) {
            await addLabel(user, newLabel);
            setStore((store) => ({ ...store, labels: [...customLabels, { ...newLabel }] }));
        }
    };

    const handleSave = async () => {
        await updateLead(lead);
        toast.success("Lead's label updated");
        handleClose(lead);
    };

    const handleRemove = (i) => {
        let newLabels = [...lead.labels];
        newLabels.splice(i, 1);
        let newLead = { ...lead };
        newLead["labels"] = newLabels;
        setLead(newLead);
    };

    const handleDeleteCustomLabel = async (label) => {
        await deleteLabel(user, label);
        const newLabels = [...customLabels];
        const index = newLabels.findIndex((l) => l.id === label.id);
        if (index !== -1) {
            newLabels.splice(index, 1);
            setStore((store) => ({ ...store, labels: newLabels }));
        }
    };

    return (
        <Container>
            <GridContainer>
                <GridFlexBox>
                    <Title>Manage Labels</Title>
                </GridFlexBox>
                <GridFlexBox>{lead && <Name>{lead.name}</Name>}</GridFlexBox>
                <GridDivider />
                <GridFlexBox fs fw>
                    {lead.labels?.map((label, i) => (
                        <Box display="flex" alignItems="center" key={i}>
                            <Label color={label.color} fontColor={label.fontColor}>
                                {label.label}
                            </Label>
                            <IconButton size="small" onClick={() => handleRemove(i)}>
                                <ClearIcon />
                            </IconButton>
                        </Box>
                    ))}
                    <ButtonC onClick={handleSave} small ml1>
                        Save
                    </ButtonC>
                </GridFlexBox>
                <GridDivider />
                <GridFlexBox fs>
                    <Name>Primary Labels</Name>
                </GridFlexBox>
                <GridFlexBox gap={1} fw fs>
                    {labels.map((primaryLabel, i) => (
                        <Label
                            key={i}
                            color={labelColors[i]}
                            fontColor={fontColors[i]}
                            onClick={() =>
                                handleAdd({ color: labelColors[i], fontColor: fontColors[i] }, primaryLabel)
                            }
                        >
                            {primaryLabel}
                        </Label>
                    ))}
                </GridFlexBox>
                <GridDivider ptb={"20px"} />
                <GridFlexBox fs>
                    <Name>Custom labels</Name>
                </GridFlexBox>
                <GridFlexBox fs gap={1} fw>
                    {customLabels?.map((customLabel) => {
                        const color = customLabel.color || blue[500];
                        const fontColor = customLabel.fontColor || "white";
                        return (
                            <Box display={"flex"} key={customLabel.id}>
                                <Label
                                    key={customLabel.id}
                                    color={color}
                                    fontColor={fontColor}
                                    onClick={() => handleAdd({ color, fontColor }, customLabel.name)}
                                >
                                    {customLabel.name}
                                </Label>
                                <IconButtonC onClick={() => handleDeleteCustomLabel(customLabel)}>
                                    clear
                                </IconButtonC>
                            </Box>
                        );
                    })}
                </GridFlexBox>
                <GridDivider ptb={"20px"} />
                <GridFlexBox fs mtb1>
                    <TextField
                        value={newLabel}
                        onChange={handleChange}
                        label="Add custom label"
                        required={false}
                        fullWidth
                    />
                </GridFlexBox>
                <GridFlexBox fs fw gap={1}>
                    {colors.map((color, i) => (
                        <Label
                            key={i}
                            color={color.color}
                            fontColor={color.fontColor}
                            onClick={() => handleAdd(color, newLabel, true)}
                        >
                            {newLabel}
                        </Label>
                    ))}
                </GridFlexBox>
            </GridContainer>
        </Container>
    );
}
