import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

export default async function updateFlowDesign(user, currentFlow, flowDesign, system) {
    try {
        const docRef = doc(db, "users", user.id, "whatsappFlows", currentFlow.id);
        await updateDoc(docRef, { flowDesign: flowDesign || "", system: system || "" });
        console.log("Updated flow design: ", currentFlow.id);
    } catch (error) {
        console.log(error);
    }
}
