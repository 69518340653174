import { collection, deleteDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

function parseDateTimeForMalaysia(dateStr, timeStr) {
    const [day, month, year] = dateStr.split("-");
    const [time, modifier] = timeStr.split(" ");
    const [hours, minutes] = time.split(":");

    // Convert 12-hour time format to 24-hour based on AM/PM modifier
    let hour24 = parseInt(hours) % 12; // Correctly adjusts 12 AM to 0 hours
    if (modifier.toUpperCase() === "PM") hour24 += 12;

    // Create a new Date object
    // JavaScript Date constructor takes local time; thus we need to consider local timezone offset
    let localDate = new Date(year, month - 1, day, hour24, parseInt(minutes));

    // Assuming the server is in UTC; adjust for Malaysia Time Zone (UTC+8)
    // If the server is not in UTC, adjust this calculation to match the server's timezone
    const utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
    const malaysiaTimeOffset = 8 * 60 * 60 * 1000; // 8 hours in milliseconds
    const malaysiaDate = new Date(utcDate.getTime() + malaysiaTimeOffset);

    return malaysiaDate;
}


const deleteAppointmentV2 = async (custContact, date, time, email) => {
    try {
        const formattedProposedDateTime = parseDateTimeForMalaysia(date, time);

        const appointmentsRef = collection(db, "appointments");
        const q = query(
            appointmentsRef,
            where("appDateTime", "==", formattedProposedDateTime),
            where("custContact", "==", custContact),
            where("owner", "==", email)
        );

        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            return JSON.stringify({
                status: "No matching appointment found.",
            });
        } else {
            let deletePromises = [];
            querySnapshot.forEach((doc) => {
                const appointmentRef = doc.ref; // Changed to use the correct doc reference
                deletePromises.push(deleteDoc(appointmentRef)); // Collect all delete promises
            });
            await Promise.all(deletePromises); // Execute all delete operations concurrently
            return JSON.stringify({
                status: "success",
                message: "Appointment deleted successfully.",
            });
        }
    } catch (error) {
        console.error("Error in deleteAppointmentV2:", error);
        return JSON.stringify({
            status: "error",
            message: error.message,
        });
    }
};

export default deleteAppointmentV2;
