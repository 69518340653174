import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "./AuthContext";
import { collection, getDocs, query, where } from "firebase/firestore";
import mapSnapshot from "../utils-functions/mapSnapshot";

export const LeadsContext = createContext();

const MAX_NUMBER = 250;

export const LeadsProvider = ({ children }) => {
    const [sourceLeads, setSourceLeads] = useState([]);
    const [assignedLeads, setAssignedLeads] = useState([]);

    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth() + 1);

    const [currentPageId, setCurrentPageId] = useState("");
    const [pages, setPages] = useState([]);

    const [folderLeads, setFolderLeads] = useState();
    const [unsubscribeFolderLeads, setUnsubscribeFolderLeads] = useState();
    const [unsubscribeSourceLeads, setUnsubscribeSourceLeads] = useState();
    const [unsubscribeAssignedLeads, setUnsubscribeAssignedLeads] = useState();
    const [maxNumberSourceLeads, setMaxNumberSourceLeads] = useState(MAX_NUMBER);
    const [maxNumberAssignedLeads, setMaxNumberAssignedLeads] = useState(MAX_NUMBER);
    const [reload, setReload] = useState(true);

    useEffect(() => {
        return () => handleUnload();
    }, [unsubscribeFolderLeads, unsubscribeSourceLeads, unsubscribeAssignedLeads]);

    const handleUnload = () => {
        unsubscribeFolderLeads?.();
        unsubscribeSourceLeads?.();
        unsubscribeAssignedLeads?.();
    };

    const values = useMemo(() => {
        return {
            sourceLeads,
            setSourceLeads,
            assignedLeads,
            setAssignedLeads,
            folderLeads,
            //
            year,
            setYear,
            month,
            setMonth,
            //
            currentPageId,
            setCurrentPageId,
            //
            pages,
            setPages,
            //
            setFolderLeads,
            unsubscribeSourceLeads,
            setUnsubscribeSourceLeads,
            unsubscribeAssignedLeads,
            setUnsubscribeAssignedLeads,
            unsubscribeFolderLeads,
            setUnsubscribeFolderLeads,
            maxNumberSourceLeads,
            setMaxNumberSourceLeads,
            maxNumberAssignedLeads,
            setMaxNumberAssignedLeads,
            MAX_NUMBER,
            reload,
            setReload,
        };
    }, [
        sourceLeads,
        assignedLeads,
        folderLeads,
        year,
        month,
        currentPageId,
        pages,
        maxNumberSourceLeads,
        maxNumberAssignedLeads,
        reload,
    ]);

    return <LeadsContext.Provider value={values}>{children}</LeadsContext.Provider>;
};
