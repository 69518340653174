import {
    Backdrop,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    Icon,
    InputLabel,
    ListSubheader,
    Paper,
    Slider,
    TextField,
    Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    query,
    setDoc,
    updateDoc,
    where,
} from "firebase/firestore";
import { onSnapshot } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { AuthContext } from "../../context/AuthContext";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { SELECT_ITEM } from "../ListingPage/SelectConst";
import { IconButton } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { UploadListingFilesButton } from "../ListingPage/UploadListingFilesButton";
import { db, storage } from "../../firebase/firebase-utils";
import { deleteObject, getDownloadURL, getMetadata, ref, uploadBytes } from "firebase/storage";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Tooltip from "@mui/material/Tooltip";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import StarIcon from "@mui/icons-material/Star";
import HeaderPage from "./HeaderPage";
import * as XLSX from "xlsx";
import { CloudUpload, FileDownload } from "@mui/icons-material";

export default function ListingPageV2() {
    const [openHeaderDialog, setOpenHeaderDialog] = useState(false);

    // States for filter options
    const [stateOptions, setStateOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [purposeOptions, setPurposeOptions] = useState([]);
    const [typeOptions, setTypeOptions] = useState([]);

    // States for selected filters
    const [selectedStates, setSelectedStates] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);
    const [selectedPurposes, setSelectedPurposes] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [rangeSliderPrice, setRangeSliderPrice] = useState([0, 1000]); // Initialize with default range
    const [maxPriceValue, setMaxPriceValue] = useState(0);

    const handleIsLoading = () => {
        setIsLoading(true);
    };
    const handleDoneLoading = () => {
        setIsLoading(false);
    };

    // Handler for changing filters
    const handleFilterChange = (event, newFilters, setFunction) => {
        setFunction(newFilters);
    };

    const defaultListingObj = {
        propertyTitle: "",
        propertyName: "",
        propertyPrice: "0",
        propertyState: "",
        propertyCity: "",
        propertyTownship: "",
        propertyPoscode: "",
        propertyAddress: "",
        propertyPurpose: "", // Ensure this matches one of the available options or is an empty string
        propertyType: "",
        landTitle: "", // Ensure this matches one of the available options or is an empty string
        tenure: "", // Ensure this matches one of the available options or is an empty string
        occupancy: "", // Ensure this matches one of the available options or is an empty string
        bumiLot: "", // Ensure this matches one of the available options ('Bumi Lot', 'Non Bumi Lot') or is an empty string
        landAreaSize: "",
        propertyBuildUp: "",
        propertyFurnishes: "",
        propertyBedRoom: "",
        propertyBathRoom: "",
        propertyCarPark: "",
        propertyMaintenanceFee: "",
        facingDirection: "",
        propertyDesc: "",
        propertyRemark: "",
        propertyWhatsappMessage: "",
        propertySEOKeyword: "",
    };
    const [showFilters, setShowFilters] = useState(false);

    const [editableIndex, setEditableIndex] = useState(null);
    const [tempIndex, setTempIndex] = useState("");
    const [imageList, setImageList] = useState([]);

    const [formDataListing, setFormDataListing] = useState(defaultListingObj);
    const [listingDialogOpen, setListingDialogOpen] = useState(false);
    const [listings, setListings] = useState([]);

    const [isEditing, setIsEditing] = useState(false); // New state to track editing mode
    const confirmation = useConfirmation();
    const { user } = useContext(AuthContext);

    const [currentPage, setCurrentPage] = useState(1);
    const [isFeaturedDialogOpen, setIsFeaturedDialogOpen] = useState(false);
    const [customFeaturedText, setCustomFeaturedText] = useState("");
    const [currentListingIdForFeature, setCurrentListingIdForFeature] = useState(null);

    const itemsPerPage = 12; // Define the maximum number of listings per page

    const handleRangeSliderPrice = (event, newValue) => {
        setRangeSliderPrice(newValue);
    };

    const handleHeaderClose = () => {
        setOpenHeaderDialog(false);
    };

    const toggleFiltersVisibility = () => {
        if (showFilters) {
            // Reset selected filters to default (empty) when hiding the filters
            setSelectedStates([]);
            setSelectedCities([]);
            setSelectedPurposes([]);
            setSelectedTypes([]);
        }
        setShowFilters(!showFilters);
    };

    const handleNewImages = async (newImages) => {
        console.log("newImages:", newImages);
        console.log("imageList:", imageList);

        // Fetch full image objects from Firestore using the IDs in newImages
        const fullImageObjects = await Promise.all(
            newImages.map(async (img) => {
                const imageRef = doc(db, "listings", formDataListing.id, "files", img.id);
                const imageSnap = await getDoc(imageRef);
                return imageSnap.exists() ? { id: img.id, ...imageSnap.data() } : null;
            })
        );

        // Filter out any nulls in case some documents were not found
        const validImages = fullImageObjects.filter((image) => image !== null);

        // Update imageList with the fetched full image objects
        setImageList((prevList) => [...prevList, ...validImages]);

        // Ensure propertyImg is an array, even if it's currently undefined
        const currentPropertyImg = Array.isArray(formDataListing.propertyImg)
            ? formDataListing.propertyImg
            : [];

        // Update formDataListing with new image IDs
        const updatedPropertyImg = [...currentPropertyImg, ...newImages.map((img) => img.id)];
        setFormDataListing((prevFormData) => ({
            ...prevFormData,
            propertyImg: updatedPropertyImg,
        }));

        // Check if we are editing an existing listing
        if (formDataListing.id) {
            const listingRef = doc(db, "listings", formDataListing.id);

            // Update the Firestore document with new image IDs
            await updateDoc(listingRef, { propertyImg: updatedPropertyImg })
                .then(() => {
                    toast.success("Images added successfully.");
                })
                .catch((error) => {
                    console.error("Error updating document: ", error);
                    toast.error("Failed to add images.");
                });
        }
    };

    const handleTextFieldChangeForImageIndex = (index, value) => {
        setImageList((prevList) => {
            const newList = [...prevList];
            newList[index].tempIndex = value;
            return newList;
        });
    };

    const handleImageIndexEdit = (index) => {
        setImageList((prevList) => {
            const newList = [...prevList].map((image, idx) => ({
                ...image,
                isEditing: idx === index ? true : image.isEditing,
                tempIndex: idx === index ? `${index + 1}` : image.tempIndex || `${idx + 1}`,
            }));
            return newList;
        });
    };

    const handleImageIndexChange = async (originalIndex) => {
        const newIndex = parseInt(imageList[originalIndex].tempIndex, 10) - 1;
        if (newIndex !== originalIndex && newIndex >= 0 && newIndex < imageList.length) {
            const itemToMove = imageList[originalIndex];
            const updatedList = [...imageList];
            updatedList.splice(originalIndex, 1); // Remove the item from its original position
            updatedList.splice(newIndex, 0, itemToMove); // Insert the item at its new position

            setImageList(
                updatedList.map((img, idx) => ({ ...img, isEditing: false, tempIndex: `${idx + 1}` }))
            ); // Reset editing state and tempIndex

            // Update the propertyImg array in formDataListing to reflect the new order
            const updatedPropertyImgOrder = updatedList.map((img) => img.id);
            setFormDataListing((prevFormData) => ({
                ...prevFormData,
                propertyImg: updatedPropertyImgOrder,
            }));

            // Optionally, update the listing in Firestore if needed
            const listingRef = doc(db, "listings", formDataListing.id);
            await updateDoc(listingRef, { propertyImg: updatedPropertyImgOrder });
        } else {
            setImageList((prevList) =>
                prevList.map((img, idx) => ({ ...img, isEditing: false, tempIndex: `${idx + 1}` }))
            ); // Reset editing state and tempIndex if unchanged
        }
    };

    // Update page function
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleCloseListingDialog = () => {
        setListingDialogOpen(false);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;

        // console.log({ name, value });
        // if (name === "propertyType") {
        //     setFormDataListing({ ...formDataListing, [name]: value });
        // } else {
        setFormDataListing({ ...formDataListing, [name]: value });
        // }
    };

    const handleAddListing = () => {
        setIsEditing(false); // Set editing mode to false
        setFormDataListing(defaultListingObj); // Reset the form data to default
        setImageList([]); // Clear the image list
        setListingDialogOpen(true); // Open the dialog for a new listing
    };

    const handleEditClick = async (listingId) => {
        const docRef = doc(db, "listings", listingId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            setIsEditing(true);
            setListingDialogOpen(true);
            setFormDataListing({ id: listingId, ...docSnap.data() });

            // Fetch image details
            if (docSnap.data().propertyImg && docSnap.data().propertyImg.length > 0) {
                const imagesDataPromises = docSnap.data()?.propertyImg?.map(async (imageId) => {
                    const imageRef = doc(db, "listings", listingId, "files", imageId);
                    const imageSnap = await getDoc(imageRef);
                    if (imageSnap.exists()) {
                        return { id: imageSnap.id, ...imageSnap.data() };
                    }
                    return null;
                });
                const imagesData = await Promise.all(imagesDataPromises);
                const filteredImages = imagesData.filter((image) => image); // Remove null values
                setImageList(filteredImages);
            } else {
                setImageList([]);
                console.log("propertyImg is empty.");
            }
        } else {
            console.log("No such document!");
        }
    };

    // Part of ListingPageV2 component
    const handleSubmitListing = async () => {
        try {
            let currentId = formDataListing.id; // Use existing ID if editing
            console.log("currentId:", currentId);

            if (isEditing && currentId) {
                console.log("if");
                console.log("formDataListing:", formDataListing);
                // Existing listing: Update logic
                await updateDoc(doc(db, "listings", currentId), {
                    ...formDataListing,
                    updateDateTime: new Date(),
                });
                toast.success("Listing updated");
                setListingDialogOpen(false);
                setIsEditing(false);
            } else {
                console.log("else");
                // New listing: Add logic
                const newDocRef = await addDoc(collection(db, "listings"), {
                    ...formDataListing,
                    userId: user.email,
                    createdDate: new Date(),
                    updateDateTime: new Date(),
                    active: true,
                });
                currentId = newDocRef.id; // Obtain new ID for the listing

                // Update formDataListing with the new document ID
                setFormDataListing((prevState) => ({
                    ...prevState,
                    id: newDocRef.id,
                }));

                toast.success("Listing added. You may add images now.");
                setIsEditing(true); // Switch to editing mode for the newly added listing
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleDuplicateListing = async (listingId) => {
        const response = await confirmation("Duplicate listing", "Click OK to duplicate this listing.");
        if (!response) return;
        try {
            // Fetch the existing listing data
            const listingRef = doc(db, "listings", listingId);
            const listingSnap = await getDoc(listingRef);

            if (!listingSnap.exists()) {
                console.error("Listing does not exist!");
                return;
            }

            const listingData = listingSnap.data();

            // Prepare the duplicated data without the 'id' field
            const duplicatedData = {
                ...listingData,
                propertyTitle: ` (Copy) ${listingData.propertyTitle}`,
                userId: user.id, // Assuming 'user' is available in your context
                createdDate: new Date(), // Use the current date for creation
                updateDateTime: new Date(), // And for the last update
                propertyImg: [],
                // Remove the 'id' field if it exists in the original document
            };

            // Remove the id property if it exists to avoid conflicts
            delete duplicatedData.id;

            // Create a new document in the listings collection with the duplicated data
            const newDocRef = await addDoc(collection(db, "listings"), duplicatedData);

            // Now update the new document with its generated ID
            await updateDoc(newDocRef, { id: newDocRef.id });

            toast.success("Listing duplicated.");
        } catch (error) {
            console.error("Failed to duplicate listing:", error);
            toast.error("Failed to duplicate listing");
        }
    };

    const handleDeleteClick = async (listingId) => {
        const response = await confirmation("Delete listing", "Click OK to delete this listing.");
        if (!response) return;

        // Reference to the files sub-collection for the listing
        const filesCollectionRef = collection(db, "listings", listingId, "files");

        // Get all the file documents in the sub-collection
        const filesSnapshot = await getDocs(filesCollectionRef);

        // Loop through each file document
        for (const fileDoc of filesSnapshot.docs) {
            const fileData = fileDoc.data();

            // Assuming fileData contains a fullPath field with the storage path
            if (fileData.fullPath) {
                const fileRef = ref(storage, fileData.fullPath);

                // Delete the file from Firebase Storage
                await deleteObject(fileRef).catch((error) => {
                    console.error("Error deleting image from storage:", error);
                    toast.error("Failed to delete an image.");
                    // Optionally, you might want to stop the deletion process
                    // and not delete the listing if an image fails to delete.
                    // return;
                });
            }

            // Delete the file document from Firestore
            await deleteDoc(doc(db, "listings", listingId, "files", fileDoc.id));
        }

        // After all images are deleted, delete the listing document
        await deleteDoc(doc(db, "listings", listingId));
        toast.success("Listing deleted");

        // Optionally refresh listings here or remove the deleted listing from state
    };

    const requestToggleFeaturedStatus = (listingId, currentStatus) => {
        if (currentStatus) {
            // If already featured, directly toggle off without asking for text
            toggleFeaturedStatus(listingId, "");
        } else {
            // If not featured, ask for text
            setIsFeaturedDialogOpen(true);
            setCurrentListingIdForFeature(listingId); // Save listingId for later
        }
    };

    const toggleActiveStatus = async (listingId, currentStatus) => {
        const listingRef = doc(db, "listings", listingId);
        const newStatus = !currentStatus;

        await updateDoc(listingRef, { active: newStatus });
        toast.success(`Listing ${newStatus ? "activated" : "disabled"}.`);

        // Optionally, refresh the listings or update local state to reflect the change
    };

    const handleIndexEdit = (index) => {
        setEditableIndex(index);
        setTempIndex(index); // directly set to 0-based index, no +1
    };
    
    const handleIndexChange = async (originalPageIndex, newGlobalIndex) => {
        // Convert page-specific index to global index
        const originalGlobalIndex = (currentPage - 1) * itemsPerPage + originalPageIndex;

        // Ensure the new index is within the range
        if (
            newGlobalIndex < 0 ||
            newGlobalIndex >= listings.length ||
            newGlobalIndex === originalGlobalIndex
        ) {
            console.log("Index out of bounds or unchanged");
            setEditableIndex(null);
            setTempIndex("");
            return;
        }

        // Create a copy of the listings
        let updatedListings = [...listings];

        // Move the listing to its new position
        const movedItem = updatedListings.splice(originalGlobalIndex, 1)[0];
        updatedListings.splice(newGlobalIndex, 0, movedItem);

        // Update the local state
        setListings(updatedListings);

        // Reset the editable index and temp index to prevent further unintended updates
        setEditableIndex(null);
        setTempIndex("");

        // Optionally update this new order to Firestore
        await Promise.all(
            updatedListings.map((listing, idx) =>
                updateDoc(doc(db, "listings", listing.id), { position: idx })
            )
        );

        // Reflect the updated indexes in the UI
        console.log("Listings reordered successfully");
    };

    const toggleFeaturedStatus = async (listingId, newStatus) => {
        const listingRef = doc(db, "listings", listingId);
        await updateDoc(listingRef, { featured: newStatus });
        toast.success(`Listing ${newStatus ? "set to Featured" : "set to Common"}.`);
        setIsFeaturedDialogOpen(false); // Close dialog after updating
        setCustomFeaturedText("");
    };

    const handleDeleteImage = async (imageObj) => {
        if (!imageObj.id || !formDataListing.id) {
            console.error("Missing necessary data for deleting the image.");
            return;
        }

        try {
            // Step 1: Delete the image document from Firestore
            const imageDocRef = doc(db, "listings", formDataListing.id, "files", imageObj.id);
            await deleteDoc(imageDocRef);

            // Step 2: Update the propertyImg array in the Firestore listing document
            const updatedPropertyImg = formDataListing.propertyImg.filter((imgId) => imgId !== imageObj.id);
            const listingDocRef = doc(db, "listings", formDataListing.id);
            await updateDoc(listingDocRef, { propertyImg: updatedPropertyImg });

            // Step 3: Update local state to reflect changes
            setImageList((prevImageList) => prevImageList.filter((img) => img.id !== imageObj.id));
            setFormDataListing((prevFormData) => ({
                ...prevFormData,
                propertyImg: updatedPropertyImg,
            }));

            toast.success("Image deleted successfully.");
        } catch (error) {
            console.error("Error deleting image:", error);
            toast.error("Failed to delete image.");
        }
    };

    const filteredListings = listings
        .filter((listing) => {
            const listingPrice = parseFloat(listing.propertyPrice);
            return ((!isNaN(listingPrice) &&
                listingPrice >= rangeSliderPrice[0] &&
                listingPrice <= rangeSliderPrice[1]) &&
                (selectedStates.length === 0 || selectedStates.includes(listing.propertyState)) &&
                (selectedCities.length === 0 || selectedCities.includes(listing.propertyCity)) &&
                (selectedPurposes.length === 0 || selectedPurposes.includes(listing.propertyPurpose)) &&
                (selectedTypes.length === 0 || selectedTypes.includes(listing.propertyType))
            );
        })
        .sort((a, b) => {
            if (a.position !== undefined && b.position !== undefined) {
                return a.position - b.position;
            } else if (a.updateDateTime && b.updateDateTime) {
                const dateA = a.updateDateTime?.toDate ? a.updateDateTime.toDate() : a.updateDateTime;
                const dateB = b.updateDateTime?.toDate ? b.updateDateTime.toDate() : b.updateDateTime;
                return dateB - dateA;
            } else if (a.createdDate && b.createdDate) {
                const createdDateA = a.createdDate?.toDate ? a.createdDate.toDate() : a.createdDate;
                const createdDateB = b.createdDate?.toDate ? b.createdDate.toDate() : b.createdDate;
                return createdDateB - createdDateA;
            }
            return 0; // As a fallback when no fields are available to compare
        });

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentListings = filteredListings.slice(indexOfFirstItem, indexOfLastItem);

    const handleManageHeader = () => {
        setOpenHeaderDialog(true);
    };

    const bulkUploadListings = async (file) => {
        try {
            const data = await file.arrayBuffer();
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            // Convert the sheet to JSON format
            const listings = XLSX.utils.sheet_to_json(sheet, { defval: "" });

            if (!listings.length) {
                toast.error("The uploaded file is empty.");
                return;
            }

            // Define expected headers based on defaultListingObj
            const expectedHeaders = Object.keys(defaultListingObj);

            // Get actual headers from the first row of the sheet
            const actualHeaders = Object.keys(listings[0]);

            // Check if the actual headers match the expected headers
            const missingHeaders = expectedHeaders.filter((header) => !actualHeaders.includes(header));
            const extraHeaders = actualHeaders.filter((header) => !expectedHeaders.includes(header));

            if (missingHeaders.length > 0 || extraHeaders.length > 0) {
                toast.error(
                    `Invalid headers. Please download excel template to fill in data.`
                );
                return;
            }

            // If header validation passes, proceed with the upload
            for (const listing of listings) {
                const formDataListing = {
                    ...defaultListingObj,
                    ...listing,
                    userId: user.email,
                    createdDate: new Date(),
                    updateDateTime: new Date(),
                    active: true,
                };

                await addDoc(collection(db, "listings"), formDataListing);
            }

            toast.success("Bulk upload successful");
        } catch (err) {
            console.error(err);
            toast.error("Bulk upload failed. Please check the console for details.");
        }
    };

    const downloadExcelTemplate = () => {
        // Define the headers based on defaultListingObj
        const headers = [
            "propertyTitle",
            "propertyName",
            "propertyPrice",
            "propertyState",
            "propertyCity",
            "propertyTownship",
            "propertyPoscode",
            "propertyAddress",
            "propertyPurpose",
            "propertyType",
            "landTitle",
            "tenure",
            "occupancy",
            "bumiLot",
            "landAreaSize",
            "propertyBuildUp",
            "propertyFurnishes",
            "propertyBedRoom",
            "propertyBathRoom",
            "propertyCarPark",
            "propertyMaintenanceFee",
            "facingDirection",
            "propertyDesc",
            "propertyRemark",
            "propertyWhatsappMessage",
            "propertySEOKeyword",
        ];

        // Create an empty worksheet
        const ws = XLSX.utils.aoa_to_sheet([headers]);

        // Create a new workbook and append the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "ListingsTemplate");

        // Write the workbook to a binary string
        const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

        // Convert the binary string to an ArrayBuffer
        const buffer = new ArrayBuffer(wbout.length);
        const view = new Uint8Array(buffer);
        for (let i = 0; i < wbout.length; ++i) {
            view[i] = wbout.charCodeAt(i) & 0xff;
        }

        // Create a Blob from the ArrayBuffer
        const blob = new Blob([buffer], { type: "application/octet-stream" });

        // Create a download link and click it programmatically
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "ListingsTemplate.xlsx";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    useEffect(() => {
        // Calculate the maximum price from listings for the Range Slider
        if (listings.length > 0) {
            const prices = listings.map((listing) => parseFloat(listing.propertyPrice));
            const maxPrice = Math.max(0, ...prices.filter((price) => !isNaN(price))); // Exclude NaN and use 0 as a fallback
            setMaxPriceValue(maxPrice);
            // Adjust the range slider max value based on the highest price found
            setRangeSliderPrice([0, maxPrice]);
        } else {
            setMaxPriceValue(0); // Set to 0 if no listings are present
            setRangeSliderPrice([0, 1000]); // Reset to default range if no data
        }
    }, [listings]);

    useEffect(() => {
        console.log("listings:", listings);
        const states = [...new Set(listings.map((item) => item.propertyState).filter((item) => item))].sort();
        const cities = [...new Set(listings.map((item) => item.propertyCity).filter((item) => item))].sort();
        const purposes = [
            ...new Set(listings.map((item) => item.propertyPurpose).filter((item) => item)),
        ].sort();
        const types = [...new Set(listings.map((item) => item.propertyType).filter((item) => item))].sort();

        setStateOptions(states);
        setCityOptions(cities);
        setPurposeOptions(purposes);
        setTypeOptions(types);
    }, [showFilters]);

    useEffect(() => {
        handleIsLoading();
        const unsubscribe = onSnapshot(
            query(collection(db, "listings"), where("userId", "==", user.email)),
            async (snapshot) => {
                const listingsWithImagesPromises = snapshot.docs.map(async (docSnapshot) => {
                    let listingData = { ...docSnapshot.data(), id: docSnapshot.id };

                    if (listingData.propertyImg && listingData.propertyImg.length > 0) {
                        let coverimg = null;

                        for (let i = 0; i < listingData.propertyImg.length && !coverimg; i++) {
                            const imageId = listingData.propertyImg[i];

                            try {
                                const imageDocSnap = await getDoc(
                                    doc(db, "listings", docSnapshot.id, "files", imageId)
                                );
                                if (imageDocSnap.exists()) {
                                    const imageData = imageDocSnap.data();
                                    coverimg = imageData.thumbnailURL
                                        ? imageData.thumbnailURL
                                        : imageData.downloadURL;
                                    break;
                                }
                            } catch (error) {
                                console.error(`Error fetching image with id ${imageId}:`, error);
                            }
                        }

                        if (coverimg) {
                            listingData = { ...listingData, coverImg: coverimg };
                        }
                    }

                    return listingData;
                });

                let listingsWithImages = await Promise.all(listingsWithImagesPromises);

                listingsWithImages.sort((a, b) => {
                    if ("position" in a && "position" in b) {
                        return a.position - b.position;
                    } else if ("createdDate" in a && "createdDate" in b) {
                        return b.createdDate.toDate() - a.createdDate.toDate();
                    }
                    return 0;
                });
                console.log("test");
                setListings(listingsWithImages);
                handleDoneLoading(); // Moved inside onSnapshot to ensure it's called after async operations
            }
        );

        return () => unsubscribe();
    }, [db, user.email]); // Removed 'imageList' from dependency array if it's not used within the effect

    return (
        <>
            <Grid container spacing={1} sx={{ padding: 2 }}>
                <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography variant="h6" sx={{ marginTop: "10px" }}>
                        Property Listing Page Configuration
                    </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Box m="16px" sx={{ display: "flex", gap: "16px", justifyContent: "flex-start" }}>
                        <Button variant="contained" onClick={handleAddListing}>
                            Manage Listing
                        </Button>

                        <Button variant="contained" onClick={handleManageHeader}>
                            Manage Header
                        </Button>

                        <Button variant="contained" onClick={toggleFiltersVisibility}>
                            {showFilters ? "Hide Filters" : "Filter"}
                        </Button>
                    </Box>
                </Grid>
                {showFilters && (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                    <Box sx={{ width: 420, m: "auto" }}>
                                        <RangeSlider
                                            handleRangeSlider={handleRangeSliderPrice}
                                            rangeSliderValue={rangeSliderPrice}
                                            settings={{
                                                label: "Price",
                                                unit: "RM",
                                                minValue: "0",
                                                maxValue: maxPriceValue.toString(),
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
                            >
                                <ToggleButtonGroup
                                    value={selectedStates}
                                    onChange={(event, newFilters) =>
                                        handleFilterChange(event, newFilters, setSelectedStates)
                                    }
                                    sx={{ mb: 2, mr: 3 }}
                                    size="small"
                                    color="primary"
                                    exclusive={false}
                                >
                                    {stateOptions
                                        .filter((option) => option)
                                        .map((option) => (
                                            <ToggleButton key={option} value={option}>
                                                {option}
                                            </ToggleButton>
                                        ))}
                                </ToggleButtonGroup>
                                <ToggleButtonGroup
                                    value={selectedCities}
                                    onChange={(event, newFilters) =>
                                        handleFilterChange(event, newFilters, setSelectedCities)
                                    }
                                    sx={{ mb: 2, mr: 3 }}
                                    size="small"
                                    color="primary"
                                    exclusive={false}
                                >
                                    {cityOptions
                                        .filter((option) => option)
                                        .map((option) => (
                                            <ToggleButton key={option} value={option}>
                                                {option}
                                            </ToggleButton>
                                        ))}
                                </ToggleButtonGroup>
                                <ToggleButtonGroup
                                    value={selectedPurposes}
                                    onChange={(event, newFilters) =>
                                        handleFilterChange(event, newFilters, setSelectedPurposes)
                                    }
                                    sx={{ mb: 2, mr: 3 }}
                                    size="small"
                                    color="primary"
                                    exclusive={false}
                                >
                                    {purposeOptions
                                        .filter((option) => option)
                                        .map((option) => (
                                            <ToggleButton key={option} value={option}>
                                                {option}
                                            </ToggleButton>
                                        ))}
                                </ToggleButtonGroup>
                                <ToggleButtonGroup
                                    value={selectedTypes}
                                    onChange={(event, newFilters) =>
                                        handleFilterChange(event, newFilters, setSelectedTypes)
                                    }
                                    sx={{ mb: 2 }}
                                    size="small"
                                    color="primary"
                                    exclusive={false}
                                >
                                    {typeOptions
                                        .filter((option) => option)
                                        .map((option) => (
                                            <ToggleButton key={option} value={option}>
                                                {option}
                                            </ToggleButton>
                                        ))}
                                </ToggleButtonGroup>
                            </Grid>
                        </Grid>
                    </>
                )}
                <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <Stack spacing={2}>
                        <Pagination
                            count={Math.ceil(filteredListings.length / itemsPerPage)}
                            page={currentPage}
                            onChange={handleChangePage}
                            showFirstButton
                            showLastButton
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography variant="caption">
                        Total Listings: {listings.length} | Page {currentPage} of{" "}
                        {Math.ceil(listings.length / itemsPerPage)}
                    </Typography>
                </Grid>

                <Grid container spacing={1} sx={{ padding: 1 }}>
                    {currentListings.map((listing, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={listing.id}>
                            {" "}
                            {/* Use listing.id as key */} {/* Use listing.id as key */}
                            <Card sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                                <Box position="relative" height="300px">
                                    <CardMedia
                                        component="img"
                                        image={
                                            listing.coverImg ||
                                            "https://storage.googleapis.com/msgsndr/O0pBoKzeg5ds0Mryvu3e/media/65ffd2c10013adf0d1ad7769.jpeg"
                                        }
                                        alt={listing.propertyName}
                                        sx={{ cursor: "pointer", height: "100%", width: "100%" }}
                                        onClick={() => handleEditClick(listing.id)}
                                    />
                                    {listing.featured && (
                                        <Box
                                            sx={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                height: "30px", // Height of the yellow bar
                                                backgroundColor: "rgba(255, 223, 0, 0.5)", // Yellow with 50% opacity
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                zIndex: 2, // Ensure it's above the image
                                            }}
                                        >
                                            <Typography
                                                variant="body2"
                                                sx={{ color: "black", fontWeight: "bold" }}
                                            >
                                                {listing.featured}
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                                <CardContent sx={{ flexGrow: 1 }}>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            display: "-webkit-box",
                                            overflow: "hidden",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp: 3,
                                            height: "72px", // Adjust based on your line-height and font-size
                                            lineHeight: "24px", // Example for a line-height that fits 3 lines of text
                                        }}
                                    >
                                        {listing.propertyTitle}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        sx={{
                                            display: "-webkit-box",
                                            overflow: "hidden",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp: 3,
                                            height: "54px", // Adjust based on your line-height and font-size for body2 variant
                                            lineHeight: "18px", // Example for a line-height that fits 3 lines of text for body2 variant
                                        }}
                                    >
                                        {listing.propertyName}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        sx={{
                                            height: "18px", // Adjust based on your line-height and font-size for body2 variant
                                            lineHeight: "18px", // Example for a line-height that fits 3 lines of text for body2 variant
                                        }}
                                    >
                                        RM
                                        {Number(listing.propertyPrice)
                                            .toFixed(2)
                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        sx={{
                                            height: "18px", // Adjust based on your line-height and font-size for body2 variant
                                            lineHeight: "18px", // Example for a line-height that fits 3 lines of text for body2 variant
                                        }}
                                    >
                                        {listing.position}
                                    </Typography>
                                </CardContent>

                                <CardActions>
                                    <Box sx={{ display: "flex", width: "100%" }}>
                                        {/* Left-aligned part for the index */}
                                        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                            {editableIndex === index ? (
                                                <TextField
                                                    size="small"
                                                    value={tempIndex}
                                                    onChange={(e) => setTempIndex(e.target.value)} // Just store the input for now
                                                    onBlur={() =>
                                                        handleIndexChange(index, parseInt(tempIndex, 10) - 1)
                                                    } // Parse and adjust for 0-based index
                                                    autoFocus
                                                    type="number"
                                                    sx={{ width: "60px" }}
                                                    inputProps={{ min: "1", max: listings.length }} // Control input range
                                                />
                                            ) : (
                                                <Tooltip title="Position">
                                                    <Typography
                                                        sx={{
                                                            cursor: "pointer",
                                                            minWidth: "20px",
                                                            textAlign: "center",
                                                        }}
                                                        onClick={() => handleIndexEdit(index)}
                                                    >
                                                        {(currentPage - 1) * itemsPerPage + index + 1}
                                                    </Typography>
                                                </Tooltip>
                                            )}
                                        </Box>

                                        {/* Right-aligned part for the icons */}
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 2,
                                                marginLeft: "auto",
                                            }}
                                        >
                                            <Tooltip title="Edit">
                                                <ModeEditIcon
                                                    onClick={() => handleEditClick(listing.id)}
                                                    sx={{ cursor: "pointer", height: "30px", width: "30px" }}
                                                />
                                            </Tooltip>
                                            {listing.active ? (
                                                <Tooltip title="Active">
                                                    <EventAvailableIcon
                                                        onClick={() =>
                                                            toggleActiveStatus(listing.id, listing.active)
                                                        }
                                                        sx={{
                                                            cursor: "pointer",
                                                            height: "30px",
                                                            width: "30px",
                                                            color: "green",
                                                        }}
                                                    />
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title="Inactive">
                                                    <EventBusyIcon
                                                        onClick={() =>
                                                            toggleActiveStatus(listing.id, listing.active)
                                                        }
                                                        sx={{
                                                            cursor: "pointer",
                                                            height: "30px",
                                                            width: "30px",
                                                            color: "red",
                                                        }}
                                                    />
                                                </Tooltip>
                                            )}
                                            {listing.featured ? (
                                                <Tooltip title="Featured">
                                                    <StarIcon
                                                        onClick={() =>
                                                            requestToggleFeaturedStatus(
                                                                listing.id,
                                                                listing?.featured
                                                            )
                                                        }
                                                        sx={{
                                                            cursor: "pointer",
                                                            height: "30px",
                                                            width: "30px",
                                                            color: "gold",
                                                        }}
                                                    />
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title="Common">
                                                    <StarIcon
                                                        onClick={() =>
                                                            requestToggleFeaturedStatus(
                                                                listing.id,
                                                                listing?.featured
                                                            )
                                                        }
                                                        sx={{
                                                            cursor: "pointer",
                                                            height: "30px",
                                                            width: "30px",
                                                        }}
                                                    />
                                                </Tooltip>
                                            )}
                                            <Tooltip title="Duplicate">
                                                <ContentCopyIcon
                                                    onClick={() => handleDuplicateListing(listing.id)}
                                                    sx={{ cursor: "pointer", height: "30px", width: "30px" }}
                                                />
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                                <DeleteForeverIcon
                                                    onClick={() => handleDeleteClick(listing.id)}
                                                    sx={{ cursor: "pointer", height: "30px", width: "30px" }}
                                                />
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <Stack spacing={2}>
                        <Pagination
                            count={Math.ceil(filteredListings.length / itemsPerPage)}
                            page={currentPage}
                            onChange={handleChangePage}
                            showFirstButton
                            showLastButton
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography variant="caption">
                        Total Listings: {listings.length} | Page {currentPage} of{" "}
                        {Math.ceil(listings.length / itemsPerPage)}
                    </Typography>
                </Grid>
            </Grid>

            <Dialog open={listingDialogOpen} maxWidth="md" fullWidth>
                <DialogTitle>{isEditing ? "Edit Listing" : "Add New Listing"}</DialogTitle>
                <DialogContent>
                    {Object.keys(defaultListingObj).map((key) => {
                        if (key === "propertyType") {
                            return (
                                <>
                                    <FormControl fullWidth key={key}>
                                        <InputLabel htmlFor="grouped-native-select">Property Type</InputLabel>
                                        <Select
                                            native
                                            name="propertyType"
                                            id="grouped-native-select"
                                            label="Property Type"
                                            margin="dense"
                                            value={formDataListing[key]}
                                            onChange={handleChange}
                                        >
                                            <option aria-label="None" value="" />
                                            {Object.entries(SELECT_ITEM.propertyType).map(
                                                ([category, types]) => (
                                                    <optgroup label={category} key={category}>
                                                        {types.map((type) => (
                                                            <option value={type} key={type}>
                                                                {type}
                                                            </option>
                                                        ))}
                                                    </optgroup>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </>
                            );
                        } else {
                            if (key === "propertyFurnishes") {
                                return (
                                    <TextField
                                        select
                                        key={key}
                                        name={key}
                                        margin="dense"
                                        label={key
                                            .replace(/([A-Z])/g, " $1")
                                            .trim()
                                            .replace(/^./, (str) => str.toUpperCase())}
                                        fullWidth
                                        variant="outlined"
                                        value={formDataListing[key]}
                                        onChange={handleChange}
                                    >
                                        {SELECT_ITEM["furnish"].sort().map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {" "}
                                                {/* Ensure option is unique and used as key */}
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                );
                            } else {
                                if (SELECT_ITEM[key]) {
                                    // Check if SELECT_ITEM[key] is defined and is an array
                                    return (
                                        <TextField
                                            select
                                            key={key}
                                            margin="dense"
                                            name={key}
                                            label={key
                                                .replace(/([A-Z])/g, " $1")
                                                .trim()
                                                .replace(/^./, (str) => str.toUpperCase())}
                                            fullWidth
                                            variant="outlined"
                                            value={formDataListing[key]}
                                            onChange={handleChange}
                                        >
                                            {SELECT_ITEM[key].sort().map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    );
                                } else {
                                    return (
                                        <TextField
                                            key={key}
                                            margin="dense"
                                            name={key}
                                            label={key
                                                .replace(/([A-Z])/g, " $1")
                                                .trim()
                                                .replace(/^./, (str) => str.toUpperCase())}
                                            type={
                                                key === "propertyPrice" ||
                                                key === "propertyBedRoom" ||
                                                key === "propertyBathRoom" ||
                                                key === "propertyCarPark"
                                                    ? "number"
                                                    : "text"
                                            }
                                            multiline={
                                                key.includes("Desc") ||
                                                key.includes("Remark") ||
                                                key.includes("Address") ||
                                                key.includes("SEO") ||
                                                key.includes("WhatsappMessage")
                                            }
                                            rows={
                                                key.includes("Desc") ||
                                                key.includes("Remark") ||
                                                key.includes("Address")
                                                    ? 4
                                                    : key.includes("propertyTitle") ||
                                                        key.includes("SEO") ||
                                                        key.includes("WhatsappMessage")
                                                      ? 2
                                                      : 1
                                            }
                                            fullWidth
                                            variant="outlined"
                                            value={formDataListing[key]}
                                            onChange={handleChange}
                                        />
                                    );
                                }
                            }
                        }
                    })}

                    <Box sx={{ marginTop: "8px" }}>
                        {isEditing ? (
                            <UploadListingFilesButton
                                handleListingFile={handleNewImages}
                                handleDeleteImage={handleDeleteImage}
                                label="Add Images"
                                currentListing={formDataListing}
                            />
                        ) : (
                            ""
                        )}
                    </Box>

                    <Box
                        sx={{
                            overflowX: "auto",
                            display: "flex",
                            gap: 2,
                            marginTop: "8px",
                            "&::-webkit-scrollbar": {
                                height: "10px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "darkgrey",
                                borderRadius: "10px",
                            },
                            // Set a max width to prevent the dialog from exceeding its size
                            maxWidth: "100%", // This ensures the container does not exceed the dialog width
                        }}
                    >
                        {imageList.map((image, index) => (
                            <Box
                                key={`${image.id}-${index}`}
                                sx={{
                                    position: "relative",
                                    minWidth: 300, // Ensure each image box has a minimum width
                                    height: 300,
                                    overflow: "hidden",
                                }}
                            >
                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        zIndex: 2,
                                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                                        color: "white",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        padding: "0 8px",
                                        height: "35px",
                                    }}
                                >
                                    {image.isEditing ? (
                                        <TextField
                                            size="small"
                                            value={image.tempIndex}
                                            onChange={(e) =>
                                                handleTextFieldChangeForImageIndex(
                                                    index,
                                                    Math.max(0, e.target.value)
                                                )
                                            }
                                            onBlur={() => handleImageIndexChange(index)}
                                            autoFocus
                                            type="number"
                                            inputProps={{ min: "0" }} // Add this line
                                            sx={{
                                                input: {
                                                    color: "white",
                                                    "&::placeholder": { color: "white" },
                                                    width: "50px", // Make the TextField shorter
                                                },
                                                minWidth: "50px", // Ensure the container of the input also gets smaller
                                            }}
                                        />
                                    ) : (
                                        <Typography
                                            sx={{ cursor: "pointer", minWidth: "20px", textAlign: "center" }}
                                            onClick={() => handleImageIndexEdit(index)}
                                        >
                                            {index + 1}
                                        </Typography>
                                    )}

                                    <IconButton
                                        size="small"
                                        onClick={() => handleDeleteImage(image.id)}
                                        sx={{ color: "white" }}
                                    >
                                        <Icon>close</Icon>
                                    </IconButton>
                                </Box>

                                <img
                                    src={image.thumbnailURL || image.downloadURL}
                                    alt="thumbnail"
                                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                />
                            </Box>
                        ))}
                    </Box>
                </DialogContent>

                <DialogActions>
                    {isEditing ? (
                        <UploadListingFilesButton
                            handleListingFile={handleNewImages}
                            handleDeleteImage={handleDeleteImage}
                            label="Add Images"
                            currentListing={formDataListing}
                        />
                    ) : (
                        ""
                    )}
                    {!isEditing && (
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<FileDownload />}
                                    onClick={downloadExcelTemplate}
                                >
                                    Download Excel Template
                                </Button>
                            </Grid>
                            <Grid item>
                                <label htmlFor="bulk-upload-file">
                                    <input
                                        id="bulk-upload-file"
                                        type="file"
                                        accept=".xlsx, .xls"
                                        style={{ display: "none" }}
                                        onChange={(e) => bulkUploadListings(e.target.files[0])}
                                    />
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        component="span"
                                        startIcon={<CloudUpload />}
                                    >
                                        Upload Excel File
                                    </Button>
                                </label>
                            </Grid>
                        </Grid>
                    )}
                    <Button onClick={handleCloseListingDialog}>Cancel</Button>
                    <Button onClick={handleSubmitListing}>{isEditing ? "Update" : "Add"}</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={isFeaturedDialogOpen} onClose={() => setIsFeaturedDialogOpen(false)}>
                <DialogTitle>Feature Text</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Featured Text (max 30 chars)"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={customFeaturedText}
                        onChange={(e) => setCustomFeaturedText(e.target.value)}
                        inputProps={{
                            maxLength: 30,
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsFeaturedDialogOpen(false)}>Cancel</Button>
                    <Button
                        onClick={() => toggleFeaturedStatus(currentListingIdForFeature, customFeaturedText)}
                    >
                        Feature
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openHeaderDialog} onClose={handleHeaderClose} maxWidth="md" fullWidth>
                <DialogTitle>Manage Header</DialogTitle>
                <DialogContent>
                    <HeaderPage />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenHeaderDialog(false)}>Close</Button>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                onClick={handleDoneLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}


const RangeSlider = ({ handleRangeSlider, rangeSliderValue, settings }) => {
    const { label, unit, minValue, maxValue } = settings;
    return (
        <div>
            <Typography id="range-slider" gutterBottom variant="caption">
                {label}({unit}) {rangeSliderValue[0].toLocaleString()} -{" "}
                {rangeSliderValue[1].toLocaleString()}
            </Typography>
            <Slider
                value={rangeSliderValue}
                onChange={handleRangeSlider}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                min={Number(minValue)}
                max={Number(maxValue)}
                getAriaValueText={(value) => `${value} ${unit}`}
                valueLabelFormat={(value) => {
                    if (unit === "RM") {
                        return `${unit} ${value.toLocaleString()}.00`;
                    } else {
                        return `${value.toLocaleString()} ${unit}`;
                    }
                }}
            />
        </div>
    );
};