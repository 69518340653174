import { Box, Container, Dialog, Grid, Icon, IconButton, Typography } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase/firebase-utils";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";

export default function RecordPage() {
    const [record, setRecord] = useState();
    const { recordId } = useParams();
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        const getData = async () => {
            console.log("recordId", recordId);
            const docRef = doc(db, "records", recordId);
            const snap = await getDoc(docRef);
            const record = mapDocSnapshot(snap);
            console.log(record);
            setRecord(record);
        };
        getData();
    }, [recordId]);

    const handleClickOpen = (image) => {
        setSelectedImage(image);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedImage(null);
    };

    const handleDownload = () => {
        window.open(selectedImage, "_blank");
    };

    return (
        <Container>
            {record && (
                <Box p={1}>
                    <Grid container spacing={2}>
                        {record?.files?.map((file, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                <Box p={1}>
                                    {file.contentType.startsWith("image/") ? (
                                        <img
                                            src={file.downloadURL}
                                            width="100%"
                                            alt={`image-${index}`}
                                            onClick={() => handleClickOpen(file.downloadURL)}
                                            style={{ cursor: "pointer" }}
                                        />
                                    ) : file.contentType.includes("pdf") ? (
                                        <embed
                                            src={file.downloadURL}
                                            width="100%"
                                            height="200"
                                            type="application/pdf"
                                            onClick={() => handleClickOpen(file.downloadURL)}
                                            style={{ cursor: "pointer" }}
                                        />
                                    ) : file.contentType.startsWith("video/") ? (
                                        <video
                                            src={file.downloadURL}
                                            width="100%"
                                            controls
                                            onClick={() => handleClickOpen(file.downloadURL)}
                                            style={{ cursor: "pointer" }}
                                        />
                                    ) : (
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            height="200px"
                                            border="1px solid #ccc"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => handleClickOpen(file.downloadURL)}
                                        >
                                            <Typography variant="body2" color="textSecondary">
                                                Unsupported file type
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}

            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <Box position="relative">
                    <img src={selectedImage} width="100%" alt="Selected" />
                    <IconButton onClick={handleDownload} style={{ position: "absolute", top: 8, right: 8 }}>
                        <Icon>download</Icon>
                    </IconButton>
                </Box>
            </Dialog>
        </Container>
    );
}
