import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { Box, Checkbox, Container, Drawer, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import React, { useContext, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { FloatingButton } from "../../components/FloatingButton";
import FullDialog from "../../components/FullDialog";
import ManageFolders from "../../components/ManageFolders";
import ManageLabels from "../../components/ManageLabels";
import QuickLeadAdd from "../../components/QuickLeadAdd";
import { SideBar } from "../../components/SideBar";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import useList from "../../context-utils/ListContext";
import { NotificationContext } from "../../context-utils/NotificationContext";
import { AuthContext } from "../../context/AuthContext";
import { LeadsContext } from "../../context/LeadsContext";
import { StoreContext } from "../../context/StoreContext";
import assignAdmins from "../../functions/assignAdmins";
import assignLeads from "../../functions/assignLeads";
import { deleteLeads } from "../../functions/deleteLeads";
import unassignAdmins from "../../functions/unassignAdmins";
import useChooseFriend from "../../hooks/useChooseFriend";
import useWindowSize from "../../hooks/useWindowSize";
import { EMPTY, LEADS_HEADER, ROWS_PER_PAGE } from "../../settings/settings";
import { arrayIsEmpty } from "../../utils-functions/arrayIsEmpty";
import getAdminList from "../../utils-functions/getAdminList";
import LeadCardPage from "../LeadCardPage";
import { checkItem } from "../LeadsTablePage/checkItem";
import getLeadsByPageId from "../LeadsTablePageV3/getLeadsByPageId";
import LeadsBoxHeader from "./LeadsBoxHeader";
import { LeadsFlexBoxComponent } from "./LeadsFlexBoxComponent";
import SelectComponent2 from "./SelectComponent2";
import fixAssignedLeads from "./fixAssignedLeads";
import { collection, getDocs, query, where } from "firebase/firestore";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import { db } from "../../firebase/firebase-utils";
import { Name } from "../../themes/themes";
import getAssignList from "../../utils-functions/getAssignList";
import unassignLeads from "../../functions/unassignLeads";

const LEADS_MODE = ["ASSIGNED_LEADS", "SOURCE_LEADS"];

export default function LeadsFlexboxPage() {
    const { user } = useContext(AuthContext);
    const { sourceLeads, setSourceLeads } = useContext(LeadsContext);
    const { assignedLeads, setAssignedLeads, currentPageId, setCurrentPageId, pages, setPages } =
        useContext(LeadsContext);
    const { year, setYear, month, setMonth } = useContext(LeadsContext);
    const { openNotification } = useContext(NotificationContext);
    const { folders } = useContext(StoreContext);

    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [leadsMode, setLeadsMode] = useState(LEADS_MODE[1]);
    const [openSideBar, setOpenSideBar] = useState(false);
    const [folder, setFolder] = useState();
    const [open, setOpen] = useState(true);

    const [openQuickAddLeadCard, setOpenQuickAddLeadCard] = useState(false);
    const [sortingOptions, setSortingOptions] = useState();
    const [page, setPage] = useState(1);
    const [searchAssign, setSearchAssign] = useState("");
    const [searchLabel, setSearchLabel] = useState();
    const [select, setSelect] = useState([]);
    const [openLabel, setOpenLabel] = useState(false);
    const [openCard, setOpenCard] = useState(false);
    const [openFolder, setOpenFolder] = useState(false);
    const [lead, setLead] = useState();
    const [showActions, setShowActions] = useState(true);
    const [search, setSearch] = useState("");
    const [search2, setSearch2] = useState([]);
    const [selectData, setSelectData] = useState([]);
    const [hideAssigned, setHideAssigned] = useState(true);

    const windowSize = useWindowSize();
    const isMobile = windowSize?.width < 800 ? true : false;

    useEffect(() => {
        if (!user) return;

        const loadPages = async () => {
            const collectionRef = collection(db, "pages");
            const q = query(collectionRef, where("admins", "array-contains", user.id));
            const snapshot = await getDocs(q);
            const pages = mapSnapshot(snapshot);
            setPages([{ name: "[none]", id: "" }, { name: "[ALL]", id: "all" }, ...pages]);
        };
        loadPages();
        if (!currentPageId) setCurrentPageId("all");
    }, []);

    useEffect(() => {
        const fixLeads = async () => {
            if (!user.fixedAssignedLeads) {
                await fixAssignedLeads(user);
            }
        };
        fixLeads();

        if (!startDate || !endDate) return;

        let setLeads;
        if (leadsMode === "SOURCE_LEADS") {
            setLeads = setSourceLeads;
        } else {
            // Assigned Leads
            setLeads = setAssignedLeads;
        }

        const getLeads = async () => {
            setLoading(true);
            const leads = await getLeadsByPageId(
                currentPageId,
                user,
                false,
                leadsMode,
                null,
                startDate,
                endDate,
                folder
            );
            setLeads(leads);
            setLoading(false);
            console.log(leads);
        };

        getLeads();
    }, [user, startDate, endDate, leadsMode, folder, currentPageId]);

    useEffect(() => {
        // get startDate and endDate from year and month
        const newStartDate = new Date(year, month - 1, 1);
        const newEndDate = new Date(year, month, 0, 23, 59, 59);

        setStartDate(newStartDate);
        setEndDate(newEndDate);
    }, [year, month]);

    const handleBack = () => {
        let newPage = page - 1;
        if (newPage < 1) return;
        setPage(newPage);
        window.scrollTo(0, 0);
    };

    const handleForward = () => {
        let leads;
        if (leadsMode === "SOURCE_LEADS") leads = sourceLeads;
        else leads = assignedLeads;

        let newPage = page + 1;
        let totalPages = Math.ceil(leads.length / ROWS_PER_PAGE);
        if (newPage > totalPages) return;
        setPage(newPage);
        window.scrollTo(0, 0);
    };

    const [totalPages, displayLeads] = useMemo(() => {
        let displayLeads = [];
        if (leadsMode === "ASSIGNED_LEADS") {
            if (!arrayIsEmpty(assignedLeads)) {
                displayLeads = assignedLeads;
            }
        } else if (!arrayIsEmpty(sourceLeads)) {
            displayLeads = sourceLeads;
        }

        let totalPages = 1;

        if (displayLeads) {
            if (searchAssign) {
                displayLeads = displayLeads.filter((lead) => {
                    let display = false;
                    lead.assignments?.forEach((assignment) => {
                        if (assignment.assign?.name?.toLowerCase().includes(searchAssign.toLowerCase())) {
                            display = true;
                        }
                    });
                    return display;
                });
            }

            // Search leads by Labels
            if (searchLabel) {
                displayLeads = displayLeads.filter((lead) => {
                    let display = false;
                    display = searchLabel.every((sLabel) => {
                        if (leadsMode === "ASSIGNED_LEADS") {
                            return lead.labels?.some((label) => {
                                if (label.userEmail === user.id) {
                                    if (label.label.toLowerCase().trim() === sLabel.label.toLowerCase()) {
                                        return true;
                                    }
                                    return false;
                                }
                                return false;
                            });
                        } else if (leadsMode === "SOURCE_LEADS") {
                            return lead.labels?.some((label) => {
                                if (label.label.toLowerCase().trim() === sLabel.label.toLowerCase()) {
                                    return true;
                                }
                                return false;
                            });
                        }
                    });
                    return display;
                });
            }

            // Search leads by name, phone or email
            if (search) {
                displayLeads = displayLeads.filter((lead) => {
                    if (checkAndConvertToLower(lead.name).includes(search.toLowerCase())) {
                        console.log("OK");
                        return true;
                    }
                    if (checkAndConvertToLower(lead.phone).includes(search.toLowerCase())) {
                        return true;
                    }
                    if (checkAndConvertToLower(lead.email).includes(search.toLowerCase())) {
                        return true;
                    }
                    return false;
                });
            }

            if (!arrayIsEmpty(search2)) {
                for (let s of search2) {
                    if (s.id && s.value && s.mode) {
                        const h = LEADS_HEADER.find((item) => item.id === s.id);
                        const type = h.type || "text";

                        if (s.mode === "contain") {
                            if (s.value === EMPTY) {
                                displayLeads = displayLeads.filter(
                                    (po) => checkItem(po[s.id], type, user) === ""
                                );
                            } else {
                                console.log("LeadsFlexBox------------------------");
                                displayLeads = displayLeads.filter((po) => {
                                    return checkItem(po[s.id], type, user)
                                        .toString()
                                        .toLowerCase()
                                        .includes(s.value.toLowerCase());
                                });
                            }
                        } else {
                            if (s.value === EMPTY) {
                                displayLeads = displayLeads.filter(
                                    (po) => checkItem(po[s.id], type, user) !== ""
                                );
                            } else {
                                displayLeads = displayLeads.filter(
                                    (po) =>
                                        !checkItem(po[s.id], type, user)
                                            .toLowerCase()
                                            .includes(s.value.toLowerCase().trim())
                                );
                            }
                        }
                    }
                }
            }

            if (!arrayIsEmpty(sortingOptions)) {
                displayLeads = displayLeads.sort((a, b) => {
                    for (let option of sortingOptions) {
                        const { id, mode } = option;
                        const h = LEADS_HEADER.find((item) => item.id === id);
                        const type = h.type || "text";
                        const itemA = checkItem(a[id], type, user);
                        const itemB = checkItem(b[id], type, user);
                        console.log("itemA: ", itemA, " itemB: ", itemB);

                        // For numeric or alphanumeric sorting
                        if (typeof itemA === "number" && typeof itemB === "number") {
                            console.log("Sorting numeric or alphanumeric ");

                            if (mode === "ascd") {
                                if (itemA < itemB) return -1;
                                if (itemA > itemB) return 1;
                            } else {
                                if (itemA < itemB) return 1;
                                if (itemA > itemB) return -1;
                            }
                        }

                        // For string sorting
                        else {
                            console.log("Sorting string");
                            const comparison = itemA.localeCompare(itemB);
                            if (comparison !== 0) {
                                return mode === "ascd" ? comparison : -comparison;
                            }
                        }
                    }

                    return 0;
                });
            }

            totalPages = Math.ceil(displayLeads.length / ROWS_PER_PAGE);
            displayLeads = displayLeads.slice((page - 1) * ROWS_PER_PAGE, page * ROWS_PER_PAGE);
        }
        return [totalPages, displayLeads];
    }, [
        page,
        searchAssign,
        searchLabel,
        search,
        search2,
        ROWS_PER_PAGE,
        sortingOptions,
        sourceLeads,
        assignedLeads,
        leadsMode,
        hideAssigned,
    ]);

    const handleClickSelect = (lead) => {
        if (select?.includes(lead.id)) {
            const index = select.findIndex((s) => s === lead.id);
            const newSelect = [...select];
            const newSelectData = [...selectData];
            newSelect.splice(index, 1);
            newSelectData.splice(index, 1);
            setSelect(newSelect);
            setSelectData(newSelectData);
        } else {
            const newSelect = [...select];
            const newSelectData = [...selectData];
            newSelect.push(lead.id);
            newSelectData.push(lead);
            setSelect(newSelect);
            setSelectData(newSelectData);
        }
    };

    const handleClickLabel = (lead) => {
        setLead(lead);
        setOpenLabel(true);
    };

    const handleLabels = () => {
        setOpenLabel(false);
    };

    const handleClickOpen = (lead) => {
        console.log(lead);
        setLead(lead);
        setOpenCard(true);
    };

    const chooseFriend = useChooseFriend();

    const handleAssignLeads = async () => {
        const response = await chooseFriend();
        if (!response) return;

        const setLeads = leadsMode === "SOURCE_LEADS" ? setSourceLeads : setAssignedLeads;
        await assignLeads(user, response, selectData, openNotification, setLeads);
        setSelect([]);
        setSelectData([]);
    };

    const handleUnassignLeads = async () => {
        const assignList = getAssignList(selectData);

        const response = await list(assignList, "Unassign leads", "name", "face", false, true);

        const setLeads = leadsMode === "SOURCE_LEADS" ? setSourceLeads : setAssignedLeads;
        console.log(response);
        if (response) {
            await unassignLeads(selectData, response.email, setLeads, sourceLeads);
            setSelect([]);
            setSelectData([]);
        }
    };

    const confirmation = useConfirmation();

    const handleDelete = async () => {
        console.log(select);
        const response = await confirmation("Delete leads", "Press OK to confirm");
        if (response) {
            await deleteLeads(select);
            setSelect([]);
        }
    };

    const list = useList();

    const handleUpdateLead = (lead, deleteLead) => {
        if (!lead) return;

        let setLeads;
        if (leadsMode === "SOURCE_LEADS") {
            setLeads = setSourceLeads;
        } else {
            setLeads = setAssignedLeads;
        }

        if (deleteLead) {
            setLeads((prev) => {
                return prev.filter((l) => l.id !== lead.id);
            });
            return;
        }

        setLeads((prev) => {
            const index = prev.findIndex((l) => l.id === lead.id);
            const newLeads = [...prev];
            newLeads.splice(index, 1, lead);
            return newLeads;
        });

        setOpenLabel(false);
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setOpenSideBar(open);
    };

    const triggerSlide = () => {
        setOpen((state) => !state);
        setTimeout(() => {
            setOpen((state) => !state);
        }, 100);
    };

    const handleClickFolder = (folder) => {
        console.log(folder);
        setFolder((prevState) => {
            if (prevState?.id === folder?.id) {
                return prevState;
            } else {
                triggerSlide();
                return folder;
            }
        });
    };

    const handleCloseAddLead = (lead) => {
        setOpenQuickAddLeadCard(false);
        let setLead;
        if (leadsMode === "SOURCE_LEADS") {
            setLead = setSourceLeads;
        } else {
            setLead = setAssignedLeads;
        }
        if (lead) {
            setSourceLeads((prev) => {
                return [lead, ...prev];
            });
        }
    };

    const handleAssignAdmin = async () => {
        const response = await chooseFriend();
        await assignAdmins(user, response, selectData, openNotification);
    };

    const handleUnassignAdmin = async () => {
        const selectData = getDataFromSelect(select, displayLeads);

        const adminList = getAdminList(selectData);
        const response = await list(adminList, "Unassign admin", "email", "face", false, true);
        console.log(response);
        if (response) {
            await unassignAdmins(selectData, response.email);
            toast.success("Admin unassigned successfully");
        }
    };

    const handleAddFolder = async () => {
        setOpenFolder(true);
    };

    const handleFolders = () => {
        setOpenFolder(false);
    };

    const handleChangePage = (e) => {
        setCurrentPageId(e.target.value);
    };

    // jsx start
    return (
        <Container disableGutters>
            <FloatingButton
                toggleDrawer={toggleDrawer}
                folder={folder}
                setOpenQuickAddLeadCard={setOpenQuickAddLeadCard}
            />
            <Box sx={{ width: "100%", typography: "body1" }} display="flex" alignItems={"center"} gap={1}>
                <TabContext value={leadsMode}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList onChange={(e, newValue) => setLeadsMode(newValue)}>
                            <Tab label="Source Leads" value="SOURCE_LEADS" />
                            <Tab label="Assigned Leads" value="ASSIGNED_LEADS" />
                        </TabList>
                    </Box>
                </TabContext>
                {leadsMode === "SOURCE_LEADS" && (
                    <Box display="flex">
                        <Name fs12>Hide Assigned</Name>
                        <Checkbox checked={hideAssigned} onChange={() => setHideAssigned((prev) => !prev)} />
                    </Box>
                )}
            </Box>
            <LeadsBoxHeader
                handleBack={handleBack}
                page={page}
                totalPages={totalPages}
                handleForward={handleForward}
                displayLeads={displayLeads}
                leads={leadsMode === "SOURCE_LEADS" ? sourceLeads : assignedLeads}
                setPage={setPage}
                searchAssign={searchAssign}
                searchLabel={searchLabel}
                setSearchLabel={setSearchLabel}
                setSearchAssign={setSearchAssign}
                showActions={showActions}
                setShowActions={setShowActions}
                search={search}
                setSearch={setSearch}
                setSearch2={setSearch2}
                user={user}
                sortingOptions={sortingOptions}
                setSortingOptions={setSortingOptions}
                month={month}
                setMonth={setMonth}
                year={year}
                setYear={setYear}
                folder={folder}
                pages={pages}
                currentPageId={currentPageId}
                handleChangePage={handleChangePage}
                leadsMode={leadsMode}
            />
            <LeadsFlexBoxComponent
                displayLeads={displayLeads}
                setSearchAssign={setSearchAssign}
                setSearchLabel={setSearchLabel}
                showActions={showActions}
                search={search}
                setSearch={setSearch}
                loading={loading}
                select={select}
                handleClickSelect={handleClickSelect}
                user={user}
                leadsMode={leadsMode}
                handleClickLabel={handleClickLabel}
                isMobile={isMobile}
                enableSelect={true}
                handleClickOpen={handleClickOpen}
                limitActions={5}
                setLead={setLead}
                setOpenCard={setOpenCard}
                displayOpenCard={true}
                search2={search2}
                setSearch2={setSearch2}
                hideAssigned={hideAssigned}
                handleUpdateLead={handleUpdateLead}
            />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Box display="flex" justifyContent={"center"}>
                Happy Calling
            </Box>
            <br />
            <br />
            <FullDialog
                open={openLabel}
                handleClose={handleLabels}
                title={"Labels"}
                Component={<ManageLabels lead={lead} setLead={setLead} handleClose={handleUpdateLead} />}
            />
            <SelectComponent2
                select={select}
                setSelect={setSelect}
                handleDelete={handleDelete}
                data={displayLeads}
                handleAssignLeads={handleAssignLeads}
                handleUnassignLeads={handleUnassignLeads}
                handleAssignAdmin={handleAssignAdmin}
                handleUnassignAdmin={handleUnassignAdmin}
                handleAddFolder={handleAddFolder}
                leadsMode={leadsMode}
            />
            <FullDialog
                open={openCard}
                handleClose={() => setOpenCard(false)}
                title={"Lead Card"}
                Component={
                    <LeadCardPage
                        lead={lead}
                        handleClose={() => setOpenCard(false)}
                        leadsMode={leadsMode}
                        handleUpdateLead={handleUpdateLead}
                    />
                }
            />

            <FullDialog
                open={openQuickAddLeadCard}
                handleClose={() => setOpenQuickAddLeadCard(false)}
                title={"Quick Lead Add Card"}
                Component={<QuickLeadAdd handleClose={handleCloseAddLead} />}
            />

            <Drawer anchor={"left"} open={openSideBar} onClose={toggleDrawer(false)}>
                <SideBar
                    toggleDrawer={toggleDrawer}
                    folder={folder}
                    folders={folders}
                    setFolder={setFolder}
                    handleClickFolder={handleClickFolder}
                    triggerSlide={triggerSlide}
                    leadsMode={leadsMode}
                />
            </Drawer>

            <FullDialog
                open={openFolder}
                handleClose={handleFolders}
                title={"Folders"}
                Component={
                    <ManageFolders
                        lead={lead}
                        handleClose={handleFolders}
                        select={select}
                        selectData={selectData}
                    />
                }
            />
        </Container>
    );
}

const checkAndConvertToLower = (variable) => {
    if (typeof variable === "string") {
        return variable.toLowerCase().trim();
    } else if (typeof variable === "number") {
        return variable.toString();
    } else {
        return "";
    }
};

export const removeDuplicatedAssignments = (assignments) => {
    let array = [];
    assignments?.forEach((assignment) => {
        const found = array.find((a) => a.assign.email === assignment.assign.email);
        if (found) return;
        array.push(assignment);
    });
    return array;
};
export const getDataFromSelect = (select, leads) => {
    let array = [];
    select.forEach((id) => {
        const lead = leads.find((lead) => lead.id === id);
        array.push(lead);
    });
    return array;
};
