import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Divider, IconButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { doc, getDoc, setDoc } from "firebase/firestore";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { AuthContext } from "../../../context/AuthContext";
import { db } from "../../../firebase/firebase-utils";
import ClearIcon from "@mui/icons-material/Clear";

export default function CalendarUnavailable({ onClose, refresh, setRefresh }) {
    const { user } = useContext(AuthContext);
    const [offDays, setOffDays] = useState({
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: false,
        Sunday: false,
    });
    const [timeRange, setTimeRange] = useState({
        from: "",
        to: "",
    });
    const [appPaddingTime, setAppPaddingTime] = useState({ before: 0, after: 0 });
    const [offDates, setOffDates] = useState([""]);
    const [duration, setDuration] = useState(60);

    useEffect(() => {
        const fetchData = async () => {
            const docRef = doc(db, "calendarOffDays", user.email);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                setOffDays({
                    Monday: data.offDays.includes("Monday"),
                    Tuesday: data.offDays.includes("Tuesday"),
                    Wednesday: data.offDays.includes("Wednesday"),
                    Thursday: data.offDays.includes("Thursday"),
                    Friday: data.offDays.includes("Friday"),
                    Saturday: data.offDays.includes("Saturday"),
                    Sunday: data.offDays.includes("Sunday"),
                });
                setTimeRange({
                    from: data.avaiTimeFrom,
                    to: data.avaiTimeTill,
                });
                setOffDates(data.offDates.length > 0 ? data.offDates : [""]);
                setAppPaddingTime({
                    before: data.paddingBefore,
                    after: data.paddingAfter,
                });
                setDuration(data.duration);
            } else {
                console.log("No such document!");
            }
        };

        fetchData();
    }, [user.email]);

    const handleDurationChange = (value) => {
        setDuration(value);
    };

    const handleAppPaddingTimeChange = (field, value) => {
        setAppPaddingTime((prev) => ({ ...prev, [field]: parseInt(value, 10) }));
    };

    const handleSwitchChange = (day) => {
        setOffDays((prev) => ({ ...prev, [day]: !prev[day] }));
    };

    const handleTimeChange = (field, value) => {
        setTimeRange((prev) => ({ ...prev, [field]: value }));
    };

    const handleDateChange = (index, value) => {
        const newDates = [...offDates];
        newDates[index] = value;
        setOffDates(newDates);
    };

    const addDatePicker = () => {
        setOffDates([...offDates, ""]);
    };

    const removeDatePicker = (index) => {
        setOffDates((prev) => prev.filter((_, i) => i !== index));
    };

    const handleSubmit = async () => {
        const dataToSave = {
            offDays: Object.entries(offDays)
                .filter(([_, value]) => value)
                .map(([key]) => key),
            duration,
            avaiTimeFrom: timeRange.from,
            avaiTimeTill: timeRange.to,
            offDates: offDates.filter((date) => date !== ""),
            createdBy: user.email,
            paddingBefore: appPaddingTime.before,
            paddingAfter: appPaddingTime.after,
            updatedDateTime: new Date(),
            clientId: user.clientId,
        };
        console.log("dataToSave:", dataToSave);
        try {
            const docRef = doc(db, "calendarOffDays", user.email);
            await setDoc(docRef, dataToSave, { merge: true });
            console.log("Saved successfully!");
            toast.success("Update successfully.");
            setRefresh(!refresh);
        } catch (error) {
            console.error("Error adding document: ", error);
            toast.error("Error adding document.");
        }
    };

    const handleClearTime = (key) => {
        handleTimeChange(key, ""); // Clear the time by setting an empty string
    };


    return (
        <>
            <Stack spacing={1} sx={{ px: 3, py: 0 }} direction="column">
                <Typography variant="h6">Set Default Duration</Typography>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <TextField
                        type="number"
                        label="Default Duration (minutes)"
                        value={duration}
                        onChange={(e) => handleDurationChange(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ min: 0, max: 480 }}
                    />
                </Box>
                <Divider />
                <Typography variant="h6">Set Padding Time</Typography>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <TextField
                        type="number"
                        label="Padding Before Appointment (minutes)"
                        value={appPaddingTime.before}
                        onChange={(e) => handleAppPaddingTimeChange("before", e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ min: 0, max: 240 }}
                    />
                    <TextField
                        type="number"
                        label="Padding After Appointment (minutes)"
                        value={appPaddingTime.after}
                        onChange={(e) => handleAppPaddingTimeChange("after", e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ min: 0, max: 480 }}
                    />
                </Box>
                <Divider />
                <Typography variant="h6">Set Working Hours</Typography>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
                        <TextField
                            fullWidth
                            type="time"
                            label="From"
                            value={timeRange.from}
                            onChange={(e) => handleTimeChange("from", e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ step: 300 }}
                        />
                        <IconButton onClick={() => handleClearTime("from")} aria-label="clear from time">
                            <ClearIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
                        <TextField
                            fullWidth
                            type="time"
                            label="To"
                            value={timeRange.to}
                            onChange={(e) => handleTimeChange("to", e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ step: 300 }}
                        />
                        <IconButton onClick={() => handleClearTime("to")} aria-label="clear to time">
                            <ClearIcon />
                        </IconButton>
                    </Box>
                </Box>

                <Divider />
                <Typography variant="h6">Set Off Days</Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap", flexDirection: "row", gap: 2 }}>
                    {Object.keys(offDays).map((day) => (
                        <FormControlLabel
                            key={day}
                            control={
                                <Switch checked={offDays[day]} onChange={() => handleSwitchChange(day)} />
                            }
                            label={day}
                        />
                    ))}
                </Box>

                <Divider />
                <Typography variant="h6">Set Off Dates</Typography>
                {offDates.map((date, index) => (
                    <Box key={index} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <TextField
                            type="date"
                            value={date}
                            onChange={(e) => handleDateChange(index, e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        />
                        {index === offDates.length - 1 && (
                            <AddIcon onClick={addDatePicker} sx={{ cursor: "pointer" }} />
                        )}
                        {offDates.length > 1 && (
                            <RemoveIcon onClick={() => removeDatePicker(index)} sx={{ cursor: "pointer" }} />
                        )}
                    </Box>
                ))}
                <Divider />
                <DialogActions>
                    <Button variant="contained" onClick={onClose}>
                        Close
                    </Button>
                    <Button variant="contained" onClick={handleSubmit}>
                        Save Changes
                    </Button>
                </DialogActions>
            </Stack>
        </>
    );
}

CalendarUnavailable.propTypes = {
    colorOptions: PropTypes.arrayOf(PropTypes.string),
    currentEvent: PropTypes.object,
    onClose: PropTypes.func,
};
