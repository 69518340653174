import { useTheme } from "@mui/material";
import { useMemo } from "react";
import bg from "../assets/whatsappBackground.jpg";
import bgDark from "../assets/whatsappBackgroundDark.jpg";

export default function useColors(platform) {
    const theme = useTheme();
    const colorMode = theme.palette.mode;

    const colors = useMemo(() => {
        const topBar = colorMode === "dark" ? "#202c33" : "#f0f2f5";
        const topBarLine = colorMode === "dark" ? "#202c33" : "#ccc";
        const searchBox = colorMode === "dark" ? "#202c33" : "#f0f2f5";
        const boxHighlight = colorMode === "dark" ? "#202c33" : "#f0f2f5";
        const bottomBar = colorMode === "dark" ? "#202c33" : "#f0f2f5";
        const textInput = colorMode === "dark" ? "#2a3942" : "#ffffff";
        let textBoxBackgroundClient = colorMode === "dark" ? "#202c33" : "#ffffff";
        let textBoxBackgroundMe = colorMode === "dark" ? "#005c4b" : "#DCF8C6";
        let selectedBoxBackground = colorMode === "dark" ? "#005c4b" : "#bed6c5";
        //
        const textBoxMessengerBackgroundClient = colorMode === "dark" ? "#f0f0f0" : "#f0f0f0";
        const textBoxMessengerBackgroundMe = colorMode === "dark" ? "#1585fc" : "#1585fc";
        //
        const whatsappBackground = colorMode === "dark" ? bgDark : bg;
        //
        const clientBar = colorMode === "dark" ? "#141B2C" : "#ffffff";
        //
        let textColorClient = colorMode === "dark" ? "#f0f0f0" : "#000000";
        let textColorMe = colorMode === "dark" ? "#f0f0f0" : "#484848";
        //
        let textColorMessengerClient = colorMode === "dark" ? "#484848" : "#484848";
        let textColorMessengerMe = colorMode === "dark" ? "#ffffff" : "#ffffff";
        //
        let chipColor = colorMode === "dark" ? "#555555" : "#f0f0f0";

        if (platform === "messenger") {
            textBoxBackgroundClient = textBoxMessengerBackgroundClient;
            textBoxBackgroundMe = textBoxMessengerBackgroundMe;
            textColorClient = textColorMessengerClient;
            textColorMe = textColorMessengerMe;
        }

        return {
            topBar,
            topBarLine,
            searchBox,
            boxHighlight,
            bottomBar,
            textInput,
            whatsappBackground,
            textBoxBackgroundClient,
            textBoxBackgroundMe,
            clientBar,
            textColorMe,
            textColorClient,
            selectedBoxBackground,
            //
            chipColor,
        };
    }, [colorMode]);

    return colors;
}
