import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import IosShareIcon from "@mui/icons-material/IosShare";
import SearchIcon from "@mui/icons-material/Search";
import {
    Box,
    Button,
    Chip,
    FormControl,
    Icon,
    IconButton,
    InputAdornment,
    InputLabel,
    Menu,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { collection, doc, getDoc, getDocs, onSnapshot } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import FilterDialogBox from "../../components/FilterDialogBox";
import SearchBar from "../../components/SearchBar";
import SortDialogBox from "../../components/SortDialogBox";
import { db } from "../../firebase/firebase-utils";
import exportToExcel from "../../functions/common-functions/exportToExcel";
import { LEADS_HEADER } from "../../settings/settings";
import { GridContainer, GridFlexBox, Name } from "../../themes/themes";
import formatDate from "../../utils-functions/formatDate";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import { convertDate } from "../FacebookConnectPage/convertDate";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";

const MONTH_ARRAY = [
    { value: 0, label: "* Last December" },
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
    { value: 13, label: "* Next January" },
];

export default function LeadsBoxHeader({
    handleBack,
    page,
    totalPages,
    handleForward,
    displayLeads,
    leads,
    setPage,
    searchAssign,
    searchLabel,
    setSearchAssign,
    setSearchLabel,
    showActions,
    setShowActions,
    search,
    setSearch,
    setSearch2,
    user,
    sortingOptions,
    setSortingOptions,
    month,
    setMonth,
    year,
    setYear,
    folder,
    pages,
    handleChangePage,
    currentPageId,
    leadsMode,
}) {
    const [openSearch, setOpenSearch] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [leadsCounter, setLeadsCounter] = useState();

    const currentYear = new Date().getFullYear();
    const yearsArray = Array.from({ length: 6 }, (_, i) => currentYear - i);

    useEffect(() => {
        const docRef = doc(db, "leadsCounter", user.id);
        const unsubscribe = onSnapshot(docRef, (snapshot) => {
            const data = mapDocSnapshot(snapshot);
            setLeadsCounter(data);
        });

        return unsubscribe;
    }, [user]);

    const monthsArray = useMemo(() => {
        const newMonthsArray = MONTH_ARRAY.map((month) => ({ ...month }));
        newMonthsArray.forEach((month, index) => {
            if (currentPageId === "") currentPageId = "null";
            const key = `${leadsMode}:${currentPageId}:${year}-${month.value}`;
            const counter = leadsCounter?.[key] || "";
            if (counter) month.label = `${MONTH_ARRAY[index].label} (${counter})`;
        });

        return newMonthsArray;
    }, [leadsCounter, leadsMode, currentPageId, year]);

    const handleSearch = (e) => {
        e.preventDefault();
        const { value } = e.target;
        setSearch(value);
        // setFilterValue(value);//added by wayne 17-1-24 : usecontext to pass search value back to getLeadsWithSearch.js
    };

    const handleClearSearch = () => {
        setSearch("");
        // setFilterValue("");//added by wayne 17-1-24 : usecontext to pass search value back to getLeadsWithSearch.js
    };

    const handleDownloadAll = async () => {
        console.log("Export all leads: ", leads);
        const exportedLeads = await Promise.all(
            leads.map(async (lead) => {
                const { created, name, phone, email } = lead;
                const labels = lead.labels?.map((label) => `[${label.label}]`)?.join(" ");
                const date = formatDate(convertDate(created));

                if (leads.length > 1000) {
                    return { date, name, phone, email, labels };
                } else {
                    const collectionRef = collection(db, "leads", lead.id, "actions");
                    const snapshot = await getDocs(collectionRef);
                    const actions = mapSnapshot(snapshot);
                    const actionsString = actions
                        .map(
                            (action) =>
                                `${formatDate(convertDate(action.created))} ${action.userName}: ${action.action} ${
                                    action.remarks || ""
                                }`
                        )
                        .join("\n");

                    return { date, name, phone, email, labels, actionsString };
                }
            })
        );
        exportToExcel(exportedLeads, ["date", "name", "phone", "email", "labels", "actionsString"]);
    };

    const leadsLoaded = useMemo(() => {
        if (!leads) return [];

        if (leadsMode === "SOURCE_LEADS") {
            const filteredLeads = leads.filter((lead) => lead.type !== "ASSIGNED_LEAD");
            return filteredLeads?.length;
        }
        return leads?.length;
    }, [leads]);

    const handleClickPage = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleMonthChange = (e) => {
        if (e.target.value === 0) {
            setMonth(12);
            setYear(year - 1);
            return;
        }

        if (e.target.value === 13) {
            setMonth(1);
            setYear(year + 1);
            return;
        }

        setMonth(e.target.value);
    };
    const handleYearChange = (e) => {
        setYear(e.target.value);
    };

    return (
        <Box m={1}>
            <GridContainer>
                <GridFlexBox xs={12} md={12} gap={1} fs>
                    <GridContainer>
                        <GridFlexBox xs={12} fs>
                            <Name pr1>Displayed: {displayLeads?.length}</Name>
                            <Name pr1>|</Name>
                            <Name pr1>Loaded: {leadsLoaded}</Name>
                            <Box display="flex" alignItems={"center"}>
                                <IconButton onClick={() => setPage(1)}>
                                    <HomeOutlinedIcon />
                                </IconButton>
                                <IconButton onClick={handleBack} disabled={page === 1}>
                                    <Icon>arrow_back</Icon>
                                </IconButton>
                                <Name onClick={handleClickPage} cp>
                                    Page {page} of {totalPages}
                                </Name>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={() => setAnchorEl(null)}
                                >
                                    {Array.from({ length: totalPages }, (_, index) => (
                                        <MenuItem
                                            key={index}
                                            onClick={() => {
                                                setPage(index + 1); // Pass the selected page number to the handler
                                                setAnchorEl(null); // Close the menu
                                            }}
                                        >
                                            Page {index + 1}
                                        </MenuItem>
                                    ))}
                                </Menu>
                                <IconButton onClick={handleForward} disabled={page === totalPages}>
                                    <Icon>arrow_forward</Icon>
                                </IconButton>
                            </Box>
                        </GridFlexBox>
                        {!folder && (
                            <GridFlexBox style={{ paddingTop: "8px" }}>
                                <FormControl fullWidth sx={{ paddingRight: "8px" }}>
                                    <InputLabel>Month</InputLabel>
                                    <Select
                                        value={month}
                                        label="Month"
                                        onChange={handleMonthChange}
                                        size="small"
                                    >
                                        {monthsArray.map((month) => (
                                            <MenuItem key={month.value} value={month.value}>
                                                {month.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth sx={{ paddingRight: "8px" }}>
                                    <InputLabel>Year</InputLabel>
                                    <Select
                                        value={year}
                                        label="Year"
                                        onChange={handleYearChange}
                                        size="small"
                                    >
                                        {yearsArray.map((year) => (
                                            <MenuItem key={year} value={year}>
                                                {year}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel>Page</InputLabel>
                                    <Select
                                        value={currentPageId}
                                        label="Year"
                                        onChange={handleChangePage}
                                        size="small"
                                    >
                                        {pages?.map((page) => (
                                            <MenuItem key={page.id} value={page.id}>
                                                {page.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </GridFlexBox>
                        )}

                        <GridFlexBox xs={12} fs>
                            {/* Filter button component */}
                            <FilterDialogBox
                                LEADS_HEADER={LEADS_HEADER}
                                setSearch2={setSearch2}
                                user={user}
                                leads={leads}
                            />

                            {/* Sort button component */}
                            <SortDialogBox
                                LEADS_HEADER={LEADS_HEADER}
                                sortingOptions={sortingOptions}
                                setSortingOptions={setSortingOptions}
                            />

                            {/* Export all loaded leads button */}
                            <Button
                                variant="text"
                                startIcon={<IosShareIcon />}
                                color="success"
                                size="small"
                                id="exportAll-button"
                                onClick={handleDownloadAll}
                            >
                                Export All
                            </Button>
                        </GridFlexBox>
                    </GridContainer>
                </GridFlexBox>
                <GridFlexBox fs xs={8} md={8}>
                    <TextField
                        value={search}
                        onChange={handleSearch}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        label="Seach name or phone"
                        InputProps={{
                            endAdornment: search && (
                                <InputAdornment position="end" onClick={handleClearSearch}>
                                    <Icon style={{ cursor: "pointer" }}>clear</Icon>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <IconButton>
                        <SearchIcon />
                    </IconButton>
                </GridFlexBox>
                <GridFlexBox fe xs md>
                    <IconButton onClick={() => setShowActions((state) => !state)} style={{ padding: 0 }}>
                        <Chip label="Actions" color={showActions ? "primary" : "default"} />
                    </IconButton>
                </GridFlexBox>
            </GridContainer>
            <SearchBar
                searchAssign={searchAssign}
                openSearch={openSearch}
                searchLabel={searchLabel}
                setSearchAssign={setSearchAssign}
                setSearchLabel={setSearchLabel}
            />
        </Box>
    );
}
