import { useState } from "react";

import { Box, Button, Dialog, Icon, IconButton, Paper } from "@mui/material";

export default function ImageIndicator({ image, setImage, width, height = 60 }) {
    const [open, setOpen] = useState(false);

    const display = () => {
        if (image?.type.includes("image")) {
            return (
                <Box
                    component="img"
                    src={image.thumbnailURL || image.downloadURL}
                    sx={{
                        ...(Boolean(width) && { width }), // Adjust the size of the image as needed (width and height are optional props
                        ...(Boolean(height) && { height }), // Adjust the size of the image as needed
                        borderRadius: "6px",
                        cursor: "pointer",
                    }}
                    onClick={() => setOpen(true)}
                />
            );
        }

        if (image?.type.includes("pdf")) {
            return (
                <Box
                    sx={{
                        borderRadius: "6px",
                        cursor: "pointer",
                    }}
                    onClick={() => window.open(image.downloadURL, "_blank")}
                >
                    {renderPdfIcon()}
                </Box>
            );
        }
    };

    const handleDownload = () => {
        window.open(image.downloadURL, "_blank");
    };

    if (!image) return null;

    return (
        <Box sx={{ position: "relative", marginRight: "8px" }}>
            {display()}
            {setImage && (
                <IconButton
                    onClick={() => setImage(null)}
                    size="small"
                    sx={{
                        position: "absolute",
                        top: -5,
                        right: -10,
                        color: "red",
                        backgroundColor: "white",
                        "&:hover": {
                            backgroundColor: "white",
                        },
                        padding: 0, // Reduce padding to make the button itself smaller
                        width: 20, // Custom width to make the button smaller
                        height: 20, // Custom height to make the button smaller
                    }}
                >
                    <Icon
                        sx={{
                            fontSize: 16, // Custom font size for the icon
                        }}
                    >
                        close
                    </Icon>
                </IconButton>
            )}
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                PaperProps={{ sx: { width: ["100vw", "90vw"] } }}
            >
                <Paper elevation={3}>
                    <Box m={1}>
                        <Box display="flex" alignItems="center" gap={1} justifyContent="flex-end" my={2}>
                            <Button variant="contained" onClick={handleDownload} size="small">
                                Download
                            </Button>
                        </Box>
                        <Box
                            component="img"
                            src={image.thumbnailURL || image.downloadURL}
                            sx={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                            }}
                        />
                    </Box>
                </Paper>
            </Dialog>
        </Box>
    );
}

const renderPdfIcon = () => (
    <Box
        component="svg"
        viewBox="0 0 24 24"
        sx={{
            height: 60, // Adjust the size as needed
            borderRadius: "6px",
        }}
    >
        <rect width="24" height="24" fill="#FF0000" />
        <text x="4" y="17" fill="#FFFFFF" fontSize="8" fontFamily="Arial, Helvetica, sans-serif">
            PDF
        </text>
    </Box>
);
