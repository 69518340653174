import { Avatar, Box, IconButton, Typography, Chip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import convertDate from "../../functions/common-functions/convertDate";
import formatDate from "../../functions/common-functions/formatDate";
import useColors from "../../hooks/useColors";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { db, storage } from "../../firebase/firebase-utils";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { deleteObject, ref } from "firebase/storage";
import MenuComponent from "./MenuComponent";

export function ChatRoomComponent({ chatRoom = {}, handleClick }) {
    const [typographyWidth, setTypographyWidth] = useState("auto");

    const number = chatRoom?.id?.split("-")[1] || "";

    const containerRef = useRef(null);

    const colors = useColors();
    const confirmation = useConfirmation();

    useEffect(() => {
        const calculateWidth = () => {
            if (containerRef.current) {
                const containerWidth = containerRef.current.offsetWidth;
                const iconButtonWidth = 52; // Adjust this based on actual IconButton size
                const calculatedWidth = containerWidth - iconButtonWidth - 8 * 2; // Subtract IconButton width and margins
                setTypographyWidth(calculatedWidth);
            }
        };

        // Calculate on mount and window resize
        calculateWidth();
        window.addEventListener("resize", calculateWidth);

        // Cleanup
        return () => window.removeEventListener("resize", calculateWidth);
    });

    const messageToDisplay = () => {
        if (chatRoom?.lastMessage?.mimeType?.includes("image")) return "Image 📷";

        const lastMessage = chatRoom?.lastMessage;
        if (!lastMessage) return "";

        return lastMessage.body ? lastMessage.body : "";
    };

    const handleMenuItemClick = async (e, item) => {
        if (item === "Delete Chat Room") {
            const response = await confirmation(
                "Delete Chat Room",
                "Are you sure you want to delete this chat room?"
            );
            if (!response) return;
            console.log("chatRoom.id:", chatRoom.id);
            const messagesCollection = collection(db, `whatsappMessages/${chatRoom.id}/messages`);

            // Retrieve all messages
            const messagesSnapshot = await getDocs(messagesCollection);
            const deletionPromises = messagesSnapshot.docs.map(async (docSnapshot) => {
                const message = docSnapshot.data();
                if (message.downloadUrl) {
                    console.log(message.downloadUrl);
                    // If there's a download URL, delete the file from storage
                    const fileRef = ref(storage, message.downloadUrl);
                    await deleteObject(fileRef).catch((error) =>
                        console.error("Failed to delete storage item:", error)
                    );
                }
                // Delete the document
                return deleteDoc(docSnapshot.ref);
            });

            // Wait for all messages to be deleted
            await Promise.all(deletionPromises);

            // Delete the chat room itself
            const chatRoomRef = doc(db, "whatsappMessages", chatRoom.id);
            await deleteDoc(chatRoomRef);
            console.log("Chat room deleted successfully");
        } else if (item === "Clear Chat") {
            const response = await confirmation("Clear Chat", "Are you sure you want to clear this chat?");
            if (!response) return;
            console.log("chatRoom.id:", chatRoom.id);
            const messagesCollection = collection(db, `whatsappMessages/${chatRoom.id}/messages`);

            // Retrieve all messages
            const messagesSnapshot = await getDocs(messagesCollection);
            const deletionPromises = messagesSnapshot.docs.map(async (docSnapshot) => {
                const message = docSnapshot.data();
                if (message.downloadUrl) {
                    console.log(message.downloadUrl);
                    // If there's a download URL, delete the file from storage
                    const fileRef = ref(storage, message.downloadUrl);
                    await deleteObject(fileRef).catch((error) =>
                        console.error("Failed to delete storage item:", error)
                    );
                }
                // Delete the document
                return deleteDoc(docSnapshot.ref);
            });

            // Wait for all messages to be deleted
            await Promise.all(deletionPromises);
        } else {
            e.stopPropagation();
        }
    };

    return (
        <Box
            display={"flex"}
            alignItems={"center"}
            width={"100%"}
            onClick={() => handleClick(chatRoom)}
            justifyContent={"space-between"}
            sx={{
                borderBottom: `1px solid ${colors.topBarLine}`,
                "&:hover": {
                    backgroundColor: colors.boxHighlight, // Replace 'primary.main' with your desired color
                },
                cursor: "pointer",
            }}
            p="4px"
        >
            <Box p="8px" display="flex" width={"100%"} ref={containerRef}>
                <Box>
                    <Avatar sx={{ width: "45px", height: "45px" }} src={chatRoom?.profilePicUrl || ""} />
                </Box>
                <Box display="flex" flexDirection={"column"} p="0 8px 0 8px" width={"100%"}>
                    <Box
                        display="flex"
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        width={`${typographyWidth}px`}
                    >
                        <Typography
                            variant="body1"
                            flex={1}
                            noWrap
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                wordBreak: "break-word", // Break long words
                            }}
                        >
                            {chatRoom?.isGroup ? chatRoom?.groupName || number : chatRoom?.name || number}
                        </Typography>
                        <Typography variant="caption" color="#71818a">
                            {chatRoom?.date ? formatDate(convertDate(chatRoom.date), true) : ""}
                        </Typography>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width={`${typographyWidth}px`} // Set width to calculated width because nowrap only works with fixed width
                    >
                        <Typography
                            variant="body2"
                            flex={1}
                            noWrap
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                wordBreak: "break-word", // Break long words
                            }}
                        >
                            {messageToDisplay()}
                        </Typography>
                        {Boolean(chatRoom.unreadAI) && <Chip label={chatRoom.unreadAI} color="error" />}
                        {Boolean(chatRoom.unread) && <Chip label={chatRoom.unread} color="primary" />}
                        <IconButton size="small">
                            <MenuComponent
                                menuItems={["Clear Chat", "Delete Chat Room"]}
                                type="dropdown"
                                onMenuItemClick={handleMenuItemClick}
                            />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
