import { Box, Paper } from "@mui/material";
import { Handle, Position } from "@xyflow/react";

function CustomNode({ data }) {
    return (
        <>
            <Handle type="target" position={Position.Left} />
            <Paper sx={{ width: "200px", height: "400px", border: "1px solid grey" }}>
                <Box p={1}>{data.label}</Box>
            </Paper>
            <Handle type="source" position={Position.Right} id="b" />
        </>
    );
}

export default CustomNode;
