import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Menu, MenuItem } from "@mui/material";
import React from "react";

export default function MenuComponent({ menuItems = [], type = "", onMenuItemClick = () => {} }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        event.stopPropagation();

        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e, item) => {
        setAnchorEl(null);
        onMenuItemClick(e, item);
    };

    return (
        <Box>
            <Box onClick={handleClick}>
                {type === "dropdown" ? (
                    <ExpandMoreIcon fontSize="medium" />
                ) : (
                    <MoreVertIcon fontSize="medium" />
                )}
            </Box>
            <Menu anchorEl={anchorEl} open={open} onClose={(e) => handleClose(e)}>
                {menuItems.map((item) => {
                    return (
                        <MenuItem
                            key={item}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleClose(e, item);
                            }}
                        >
                            {item}
                        </MenuItem>
                    );
                })}
            </Menu>
        </Box>
    );
}
