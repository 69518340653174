import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, Chip, Grid, Icon, IconButton, InputAdornment, Paper, TextField } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { green, red, yellow } from "@mui/material/colors";
import { Container } from "@mui/system";
import {
    arrayRemove,
    arrayUnion,
    collection,
    deleteDoc,
    doc,
    getDocs,
    query,
    updateDoc,
    where,
} from "firebase/firestore";
import { encode } from "gpt-tokenizer";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import FullDialog from "../../components/FullDialog";
import ImageComponent from "../../components/Image/ImageComponent";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { useInput } from "../../context-utils/InputContext";
import { db } from "../../firebase/firebase-utils";
import formatPhoneNumber from "../../functions/common-functions/formatPhoneNumber";
import removeSpecialChars from "../../functions/common-functions/removeSpecialChars";
import useChooseFriend from "../../hooks/useChooseFriend";
import { GridContainer, GridDivider, Loading, addAdornment } from "../../themes/themes";
import { arrayIsEmpty } from "../../utils-functions/arrayIsEmpty";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import { notification } from "../../utils-functions/notification";
import QASystemPage from "../QASystemPage/QASystemPage";
import ReactFlowPage from "../ReactFlowPage/ReactFlowPage";
import ConnectWhatsappComponent from "../WhatsappWebPage/ConnectWhatsappComponent";
import { ExcelLikeInput } from "../WhatsappWebPage/WhatsappBroadcastPage";
import { AuthContext } from "./../../context/AuthContext";
import { Name } from "./../../themes/themes";
import FollowUpDesign from "./FollowUpDesign";
import SettingsDialog from "./SettingsDialog";
import WhatsappLinkGenerator from "./WhatsappLinkGenerator";
import addAssignedNumber from "./addAssignedNumber";
import addFlow from "./addFlow";
import addKeyword from "./addKeyword";
import deleteAssignedNumber from "./deleteAssignedNumber";
import deleteFlow from "./deleteFlow";
import loadAssignedNumbers from "./loadAssignedNumbers";
import loadFlows from "./loadFlows";
import shareFlowDesign from "./shareFlowDesign";
import updateAiDelayReply from "./updateAiDelayReply";
import updateDisableAI from "./updateDisableAI";
import updateDisableTelegramNotify from "./updateDisableTelegramNotify";
import updateFlowDesign from "./updateFlowDesign";
import updateFlowStatus from "./updateFlowStatus";
import updateImages from "./updateImages";
import updateKeywords from "./updateKeywords";
import updateModel from "./updateModel";
import updateSaveLead from "./updateSaveLead";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import TelegramIcon from "@mui/icons-material/Telegram";
import InstagramIcon from "@mui/icons-material/Instagram";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";

export default function WhatsappFlowPage() {
    const [loading, setLoading] = React.useState(false);
    const [keyword, setKeyword] = React.useState("");
    const [flows, setFlows] = React.useState([]);
    const [flow, setFlow] = React.useState("");
    const [currentFlow, setCurrentFlow] = React.useState({});
    const [flowDesign, setFlowDesign] = React.useState("");
    const [assignedNumbers, setAssignedNumbers] = React.useState([]);
    const [model, setModel] = React.useState("gemini-1.5-flash");
    const [whatsappPhone, setWhatsappPhone] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [system, setSystem] = useState(
        "You are a real estate agent. Your goal is to secure appointment to view the sales galery. Do not mention you are an AI."
    );
    const [edited, setEdited] = useState(false);
    const [openFollowUp, setOpenFollowUp] = useState(false);
    const [openFollowUpReactFlow, setOpenFollowUpReactFlow] = useState(false); //added by wayne 12-1-24 : temporary add a button for development purpose.
    const [tokens, setTokens] = useState(0);
    const [openSettings, setOpenSettings] = useState(false);
    const [searchFlow, setSearchFlow] = useState("");
    const [openQA, setOpenQA] = useState(false);
    const [searchAssignedNumber, setSearchAssignedNumber] = useState("");
    const [searchBlockedNumber, setSearchBlockedNumber] = useState("");
    const [aiDelayReply, setAiDelayReply] = useState(0);
    const [selectedObjects, setSelectedObjects] = useState(["whatsapp", "page", "telegram", "instagram"]);

    const { user } = useContext(AuthContext);

    const confirmation = useConfirmation();

    const REQUIRED_FIELDS = [
        { label: "Name", id: "name" },
        { label: "Phone", id: "phone" },
    ];

    useEffect(() => {
        const unsubscribe = loadFlows(user, setFlows, setLoading);
        return unsubscribe;
    }, []);

    useEffect(() => {
        if (currentFlow.id) {
            const newCurrentFlow = flows.find((flow) => {
                if (flow.id) {
                    return flow.id === currentFlow.id;
                } else {
                    return false;
                }
            });
            setCurrentFlow(newCurrentFlow || {});
            console.log(newCurrentFlow);
            const model = newCurrentFlow?.model || "gpt-3.5-turbo";
            setModel(model);
            setAiDelayReply();
        }
    }, [flows]);

    useEffect(() => {
        const unsubscribe = loadAssignedNumbers(user, setAssignedNumbers, setLoading);
        return unsubscribe;
    }, []);

    useEffect(() => {
        if (currentFlow.id) {
            updateModel(user, currentFlow, model);
        }
    }, [model]);

    useEffect(() => {
        if (!flowDesign) {
            setTokens(0);
            return;
        }
        const tokens = encode(flowDesign);
        setTokens(tokens.length);
    }, [flowDesign]);

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleObjectToggle = (event, newObjects) => {
        setSelectedObjects(newObjects);
    };
    const getIcon = (type) => {
        switch (type) {
            case "page":
                return <FacebookIcon />;
            case "telegram":
                return <TelegramIcon />;
            case "instagram":
                return <InstagramIcon />;
            default:
                return <WhatsAppIcon />;
        }
    };

    const handleAddKeyword = async () => {
        if (!currentFlow.id) {
            notification("Error", "Please select a flow or create one", "danger");
            return;
        }

        if (keyword && !arrayIsEmpty(flows)) {
            for (let i = 0; i < flows.length; i++) {
                let found = false;
                if (flows[i].keywords) {
                    flows[i].keywords.every((existingKeyword) => {
                        if (keyword.toLowerCase().trim() === existingKeyword.toLowerCase().trim()) {
                            notification("Error", "Keyword already exists in " + flows[i]["name"], "danger");
                            found = true;
                            return false;
                        }
                        return true;
                    });
                }
                if (found) return;
            }

            await addKeyword(keyword.toLowerCase().trim(), user, currentFlow);
            setKeyword("");
        }
    };

    const handleDeleteKeyword = async (index) => {
        const response = await confirmation("Delete keyword?", "Press ok to confirm");
        if (response) {
            const newKeywords = currentFlow.keywords.filter((keyword, i) => i !== index);
            await updateKeywords(user, currentFlow, newKeywords);
        }
    };

    const handleClickAddFlow = async () => {
        if (!flow) return;
        const clientId = removeSpecialChars(user.id);
        const flowId = await addFlow(flow, user, clientId);
        setCurrentFlow({ id: flowId, name: flow });
        setFlow("");
        setFlowDesign("");
    };

    const handleDeleteFlow = async (flow) => {
        setLoading(true);
        const response = await confirmation("Delete flow?", "Press ok to confirm");
        if (response) {
            await deleteFlow(flow, user);
        }

        // delete all the related records
        const recordsRef = collection(db, "records");
        const q = query(recordsRef, where("flowId", "==", flow.id));
        const snapshot = await getDocs(q);
        const records = mapSnapshot(snapshot);
        await Promise.all(
            records.map(async (record) => {
                const docRef = doc(db, "records", record.id);
                await deleteDoc(docRef);
                console.log("Record deleted: ", record.id);
            })
        );
        toast.success("Flow deleted successfully");
        setLoading(false);
    };

    const handleClickFlow = (flow) => {
        setCurrentFlow(flow);
        setFlowDesign(flow.flowDesign || "");
        setModel(flow.model || "gpt-3.5-turbo");
        setSystem(flow.system || "");
        console.log(flow);
    };

    const handleSaveFlowDesign = async () => {
        if (!currentFlow.id) {
            notification("Error", "Please select a flow or create one", "danger");
            return;
        }

        if (flowDesign.trim() === currentFlow.flowDesign && system.trim() === currentFlow.system) {
            // No changes
            return;
        }

        await updateFlowDesign(user, currentFlow, flowDesign.trim(), system.trim());
        setEdited(false);
        notification("Success", "Flow design saved", "success");
    };

    const handleDeleteAssignedNumber = async (number) => {
        //
        console.log(number);

        await deleteAssignedNumber(number);

        const docRef = doc(db, "users", currentFlow.userId, "whatsappFlows", currentFlow.id);
        const client = number.chatRoomId.split("-")[1];

        await updateDoc(docRef, { blockedUsers: arrayUnion(client) });

        const newCurrentFlow = { ...currentFlow };

        if (!newCurrentFlow?.blockedUsers) {
            newCurrentFlow.blockedUsers = [client];
        } else {
            newCurrentFlow.blockedUsers.push(client);
        }

        setCurrentFlow(newCurrentFlow);

        const chatRoomRef = doc(db, "whatsappMessages", number.chatRoomId);
        await updateDoc(chatRoomRef, { triggeredBlockIds: [] });
    };

    const handleClickStatus = async (flow) => {
        const newStatus = flow.status === "active" ? "inactive" : "active";
        await updateFlowStatus(user, flow, newStatus);
    };

    const displayAssignedNumbers = useMemo(() => {
        let numbers = assignedNumbers.filter((number) => number.whatsappFlowId === currentFlow?.id);

        numbers = numbers.filter((aNumber) => {
            if (
                !searchAssignedNumber &&
                (selectedObjects.length === 0 || selectedObjects.includes(aNumber.object || "whatsapp"))
            ) {
                return true;
            }
            const name = aNumber?.name?.toLowerCase() ?? "";
            const clientNumber = getClientNumber(aNumber.chatRoomId).toLowerCase();
            const searchAssignedNumberLower = searchAssignedNumber.toLowerCase();

            const nameMatchesSearch = name.includes(searchAssignedNumberLower);
            const clientNumberMatchesSearch = clientNumber.includes(searchAssignedNumberLower);
            const objectMatches =
                selectedObjects.length === 0 || selectedObjects.includes(aNumber.object || "whatsapp");

            return (nameMatchesSearch || clientNumberMatchesSearch) && objectMatches;
        });

        return numbers;
    }, [assignedNumbers, currentFlow, searchAssignedNumber, selectedObjects]);

    const handleFile = async (files) => {
        if (currentFlow.images) {
            let newImages = [...currentFlow.images, ...files];
            let uniqueImages = Array.from(new Set(newImages));
            await updateImages(uniqueImages, user, currentFlow);
        } else {
            const newImages = [...files];
            await updateImages(newImages, user, currentFlow);
        }
    };

    const handleDeleteImage = async (i) => {
        let images = [...currentFlow.images];
        images.splice(i, 1);
        await updateImages(images, user, currentFlow);
    };

    const chooseFriend = useChooseFriend();

    const handleShareFlowDesign = async (flow) => {
        const friend = await chooseFriend();
        if (friend) {
            await shareFlowDesign(user, flow, friend);
        }
    };

    const input = useInput();

    const handleClickTag = async (i) => {
        console.log(i);
        const response = await input(
            "Tag",
            "Enter a tag for the file",
            "tag",
            currentFlow?.images[i]?.tag || ""
        );
        if (response) {
            const images = [...currentFlow.images];
            images[i].tag = response.trim().toLowerCase();
            await updateImages(images, user, currentFlow);
        }
    };

    const handleChangeFlowDesign = (e) => {
        const newContent = e.target.value;
        const tokens = encode(newContent).length;
        console.log(tokens.length);

        if (tokens <= 1400) {
            setFlowDesign(newContent);
            setEdited(true);
            setErrorMessage("");
        } else {
            setErrorMessage("Flow design cannot exceed 1400 words");
        }
    };

    const handleChangeSystem = (e) => {
        const newContent = e.target.value;
        const tokens = encode(newContent).length;

        if (tokens <= 500) {
            setSystem(newContent);
            setEdited(true);
            setErrorMessage("");
        } else {
            setErrorMessage("System message cannot exceed 500 tokens");
        }
    };

    const handleChangeSaveLead = async (e) => {
        const checked = e.target.checked;
        await updateSaveLead(user, currentFlow, checked);
    };

    const handleChangeDisableAI = async (e) => {
        const checked = e.target.checked;
        await updateDisableAI(user, currentFlow, !checked);
    };

    const handleChangeAppointment = async (e) => {
        const checked = e.target.checked;
        const docRef = doc(db, "users", user.id, "whatsappFlows", currentFlow.id);
        updateDoc(docRef, { appointment: checked });
        console.log("updated appointment", checked);
    };

    const handleChangeSendAudio = async (e) => {
        const checked = e.target.checked;
        const docRef = doc(db, "users", user.id, "whatsappFlows", currentFlow.id);
        updateDoc(docRef, { sendAudio: checked });
        console.log("updated send audio", checked);
    };

    const handleDeleteBlockedUser = async (item) => {
        //
        const chatRoomId = formatPhoneNumber(whatsappPhone) + "-" + item;

        const docRef = doc(db, "users", currentFlow.userId, "whatsappFlows", currentFlow.id);

        await updateDoc(docRef, { blockedUsers: arrayRemove(item) });

        const newCurrentFlow = { ...currentFlow };
        newCurrentFlow.blockedUsers = newCurrentFlow.blockedUsers.filter((user) => user !== item);
        setCurrentFlow(newCurrentFlow);

        const chatRoomRef = doc(db, "whatsappMessages", chatRoomId);
        await updateDoc(chatRoomRef, { triggeredBlockIds: [] });
    };

    const initialState = {
        name: "",
        phone: "",
        countrycode: "60",
    };

    const [userInput, setUserInput] = useState(initialState);

    const handleChange = (event) => {
        const { id, value } = event.target;
        setUserInput((prevInput) => ({
            ...prevInput,
            [id]: value,
        }));
    };

    const handleAddSingle = async () => {
        console.log("addsingle");

        // if (whatsappPhone === '') {
        //     alert(`Please fill in the following field(s): whatsapp phone`);
        // }
        // else
        if (userInput.name === "" || userInput.phone === "" || userInput.countrycode === "") {
            alert(`Please fill in the following field(s): name,phone,country code`);
        } else {
            let userPhone = userInput.countrycode.replace(/\D/g, "") + userInput.phone.replace(/\D/g, "");
            let inputobj = { ...userInput, phone: userPhone };
            await addAssignedNumber(whatsappPhone, user, inputobj, currentFlow);
            setUserInput(initialState);
        }
    };

    const handlePasteData = async (data) => {
        try {
            let names = data.map((item) => {
                return { name: item[0], phone: item[1], status: "" };
            });
            if (!names[names.length - 1].name) names.pop();

            await Promise.all(
                names.map(async (contact) => {
                    await addAssignedNumber(whatsappPhone, user, contact, currentFlow, contact);
                })
            );
        } catch (err) {
            console.log("Error handlePasteData.");
        }
    };

    const handleClickIndex = async () => {
        if (!currentFlow.id) return;
        const docRef = doc(db, "users", user.id, "whatsappFlows", currentFlow.id);
        await updateDoc(docRef, { runVectorIndexing: true });
        console.log("updated runVectorIndexing", true);
        toast.success("Indexing set to true");
    };

    const handleEditName = async (flow) => {
        if (!flow.id) return;

        const response = await input("Edit Flow Name", "Enter a new name for the flow", "name", flow.name);
        if (response) {
            const docRef = doc(db, "users", user.id, "whatsappFlows", flow.id);
            await updateDoc(docRef, { name: response });
            toast.success("Flow name updated");
        }
    };

    const handleSearchChange = (event) => {
        setSearchFlow(event.target.value);
    };

    const handleSearchAssignedNumber = (event) => {
        setSearchAssignedNumber(event.target.value);
    };

    const handleSearchBlockedNumber = (event) => {
        setSearchBlockedNumber(event.target.value);
    };

    const clearSearchField = () => {
        setSearchFlow("");
        setSearchAssignedNumber("");
    };

    const isMasterOrDefault = (flow) => {
        let types = [];
        if (flow.keywords) {
            flow.keywords.some((keyword) => {
                if (keyword.toLowerCase() === "_master_") {
                    types.push("_master_");
                } else if (keyword.toLowerCase() === "_default_") {
                    types.push("_default_");
                }
            });
        }
        return types;
    };

    const getName = (item) => {
        if (isAllNumbers(item.name)) {
            if (item.pushname) return item.pushname;
            return item.name;
        } else {
            return item.name;
        }
    };

    const handleChangeAiDelayReply = async (e) => {
        await updateAiDelayReply(user, currentFlow, e);
        setAiDelayReply(e);
    };

    const handleUnresolveTelegramNotify = async (e) => {
        const checked = e.target.checked;
        await updateDisableTelegramNotify(user, currentFlow, checked);
    };

    const getIconColor = (platform, isSelected) => {
        if (!isSelected) return "inherit";
        switch (platform) {
            case "whatsapp":
                return "#25D366";
            case "page":
                return "#1877F2";
            case "telegram":
                return "#0088cc";
            case "instagram":
                return "#E4405F";
            default:
                return "inherit";
        }
    };

    return (
        <Container maxWidth="none">
            <Grid container display="flex" spacing={1}>
                <Loading loading={loading} />
                <Grid item display="flex" xs={12} md={4}>
                    <Grid container display="flex" spacing={1} alignContent={"flex-start"}>
                        <Grid item display="flex" xs={12} md={12} flexDirection={"column"}>
                            <Paper sx={{ width: "100%", padding: "8px" }}>
                                <Name color={currentFlow?.name ? green[500] : yellow[800]}>
                                    Current flow: {currentFlow?.name || "Please select a flow"}
                                </Name>
                            </Paper>
                        </Grid>
                        <Grid item display="flex" xs={12} md={12} flexDirection={"column"}>
                            <Paper sx={{ width: "100%", padding: "8px" }}>
                                <Box display="flex" width="100%" gap={"8px"} justifyContent={"space-between"}>
                                    <Name>Whatsapp Flows:</Name>
                                    <TextField
                                        placeholder="Flow name"
                                        size="small"
                                        InputProps={addAdornment(handleClickAddFlow)}
                                        value={flow}
                                        onChange={(e) => setFlow(e.currentTarget.value)}
                                    />
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item display="flex" xs={12} md={12} flexDirection={"column"}>
                            <Paper sx={{ width: "100%", padding: "8px" }}>
                                <Box
                                    display="flex"
                                    width="100%"
                                    gap={"8px"}
                                    justifyContent={"space-between"}
                                    alignItems="center"
                                >
                                    <Name>Search flow:</Name>
                                    <TextField
                                        sx={{ flexGrow: 1 }} // Use flexGrow to take available space
                                        placeholder="Flow name"
                                        size="small"
                                        value={searchFlow}
                                        onChange={handleSearchChange}
                                    />
                                    <Typography
                                        sx={{
                                            cursor: "pointer",
                                            marginLeft: "4px",
                                            alignSelf: "center",
                                        }}
                                        onClick={clearSearchField}
                                    >
                                        Clear
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>

                        <Grid item display="flex" xs={12} md={12} flexDirection={"column"}>
                            <Paper
                                sx={{
                                    width: "100%",
                                    padding: "8px",
                                    height: "30vh",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                }}
                            >
                                {flows
                                    .filter(
                                        (flow) =>
                                            flow.name.toLowerCase().includes(searchFlow.toLowerCase()) ||
                                            !searchFlow
                                    )
                                    .map((flow) => (
                                        <Box
                                            display={"flex"}
                                            width={"100%"}
                                            key={flow.id}
                                            sx={{
                                                border: flow.id === currentFlow?.id ? "1px grey solid" : "",
                                            }}
                                        >
                                            <Grid
                                                item
                                                xs={7}
                                                alignSelf={"center"}
                                                onClick={() => handleClickFlow(flow)}
                                                sx={{
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <Box display="flex" alignItems={"center"} gap={1} pl={1}>
                                                    <Name
                                                        bold={flow.id === currentFlow?.id ? true : false}
                                                        color={
                                                            flow.id === currentFlow?.id
                                                                ? green[500]
                                                                : "inherit"
                                                        }
                                                    >
                                                        {flow.name}
                                                    </Name>
                                                    {isMasterOrDefault(flow).map((type) => (
                                                        <Chip
                                                            key={type}
                                                            label={type}
                                                            color="primary"
                                                            size="small"
                                                        />
                                                    ))}
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={3}
                                                alignSelf={"center"}
                                                onClick={() => handleClickStatus(flow)}
                                                sx={{
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <Button
                                                    sx={{
                                                        color:
                                                            flow.status === "active" ? green[500] : red[500],
                                                    }}
                                                >
                                                    {flow.status}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <IconButton onClick={() => handleEditName(flow)}>
                                                    <Icon>edit</Icon>
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <IconButton onClick={() => handleShareFlowDesign(flow)}>
                                                    <Icon>share</Icon>
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <IconButton onClick={() => handleDeleteFlow(flow)}>
                                                    <Icon>delete</Icon>
                                                </IconButton>
                                            </Grid>
                                        </Box>
                                    ))}
                            </Paper>
                        </Grid>
                        <Grid item display="flex" xs={12} md={12} flexDirection={"column"}>
                            <Paper sx={{ width: "100%", padding: "8px" }}>
                                <Box display="flex" width="100%" gap={"8px"} justifyContent={"space-between"}>
                                    <Name>Trigger keywords: </Name>
                                    <TextField
                                        disabled={!currentFlow.id}
                                        placeholder="Trigger keyword"
                                        value={keyword}
                                        multiline
                                        size="small"
                                        onChange={(e) => setKeyword(e.currentTarget.value)}
                                        InputProps={addAdornment(handleAddKeyword)}
                                    />
                                </Box>
                                <GridDivider />
                                {currentFlow?.keywords?.map((keyword, i) => (
                                    <Box display={"flex"} width={"100%"} key={i}>
                                        <Grid item xs={11} alignSelf={"center"}>
                                            <Name>{keyword}</Name>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton onClick={() => handleDeleteKeyword(i)}>
                                                <Icon>delete</Icon>
                                            </IconButton>
                                        </Grid>
                                    </Box>
                                ))}
                                <Box display={"flex"} width={"100%"}></Box>
                            </Paper>
                        </Grid>
                        <Grid item display="flex" xs={12} md={12} flexDirection={"column"}>
                            <Paper sx={{ width: "100%", padding: "8px" }}>
                                <WhatsappLinkGenerator whatsappPhone={whatsappPhone} />
                            </Paper>
                        </Grid>
                        <Grid item display="flex" xs={12} md={12} flexDirection={"column"}>
                            <Paper sx={{ width: "100%", padding: "8px" }}>
                                <ConnectWhatsappComponent
                                    whatsappUser={user}
                                    setWhatsappPhone={setWhatsappPhone}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item display="flex" xs={12} md={5}>
                    <Grid container display="flex" spacing={1} alignContent={"flex-start"}>
                        <Grid item display="flex" flexDirection={"column"} xs={12}>
                            <Paper sx={{ width: "100%", padding: "8px" }}>
                                <Box display={"flex"} gap="8px" alignItems={"center"}>
                                    <Name>Flow design:</Name>
                                    <SettingsDialog
                                        currentFlow={currentFlow}
                                        handleChangeAppointment={handleChangeAppointment}
                                        handleChangeDisableAI={handleChangeDisableAI}
                                        handleChangeSaveLead={handleChangeSaveLead}
                                        handleChangeSendAudio={handleChangeSendAudio}
                                        handleChangeAiDelayReply={handleChangeAiDelayReply}
                                        handleUnresolveTelegramNotify={handleUnresolveTelegramNotify}
                                        aiDelayReply={aiDelayReply}
                                        model={model}
                                        open={openSettings}
                                        setModel={setModel}
                                        setOpen={setOpenSettings}
                                        user={user}
                                    />
                                    <Button variant="contained" size="small" onClick={() => setOpenQA(true)}>
                                        Q&A Index Design
                                    </Button>
                                </Box>

                                <GridDivider />
                                <TextField
                                    multiline
                                    disabled={!currentFlow.id}
                                    rows={6}
                                    fullWidth
                                    placeholder="System message"
                                    value={system}
                                    onBlur={handleSaveFlowDesign}
                                    onChange={handleChangeSystem}
                                    sx={{ marginBottom: "8px" }}
                                />
                                <TextField
                                    multiline
                                    rows={15}
                                    fullWidth
                                    disabled={!currentFlow.id}
                                    placeholder="Design your flow here"
                                    value={flowDesign}
                                    onBlur={handleSaveFlowDesign}
                                    onChange={handleChangeFlowDesign}
                                />
                                <Box display="flex" gap="8px" justifyContent={"space-between"}>
                                    <Box display="flex" gap="8px">
                                        <Button
                                            variant="contained"
                                            sx={{ marginTop: "8px" }}
                                            onClick={handleSaveFlowDesign}
                                        >
                                            Save
                                        </Button>
                                        <Name color={red[500]}>{errorMessage}</Name>
                                        <Name>{`${tokens} / 1400 tokens`} </Name>
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item display="flex" flexDirection={"column"} xs={12}>
                            <Paper sx={{ width: "100%", padding: "8px" }}>
                                <Grid item xs={12}>
                                    <Name>Files: </Name>
                                </Grid>
                                <GridDivider />
                                {currentFlow.images?.map((image, i) => (
                                    <GridContainer key={image.downloadURL}>
                                        <Grid item display="flex" xs={4}>
                                            <Box display="flex" gap="8px">
                                                <ImageComponent image={image} width={["100px", "150px"]} />
                                            </Box>
                                        </Grid>
                                        <Grid item display="flex" xs={4}>
                                            <Name>{image.name}</Name>
                                        </Grid>
                                        <Grid item display="flex" xs={3}>
                                            <Button
                                                variant="outlined"
                                                onClick={() => {
                                                    handleClickTag(i);
                                                }}
                                            >
                                                {image.tag || "tag"}
                                            </Button>
                                        </Grid>
                                        <Grid item display="flex" xs={1}>
                                            <IconButton onClick={() => handleDeleteImage(i)}>
                                                <Icon>delete</Icon>
                                            </IconButton>
                                        </Grid>
                                        <GridDivider />
                                    </GridContainer>
                                ))}
                                {/* {currentFlow.id && (
                                    <Box sx={{ marginTop: "8px" }}>
                                        <UploadFilesButton handleFile={handleFile} label="Add" />
                                    </Box>
                                )} */}
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item display="flex" xs={12} md={3}>
                    <Grid container display="flex" spacing={1} alignContent={"flex-start"}>
                        {currentFlow.id && (
                            <Grid item xs={12} display="flex" flexDirection={"column"}>
                                <Paper sx={{ width: "100%", padding: "8px" }}>
                                    <Grid container display="flex" spacing={1}>
                                        <Grid item display="flex" xs={12}>
                                            <Name>Follow up sequence design:</Name>
                                        </Grid>
                                        <Grid item display="flex">
                                            {/* <Button onClick={() => setOpenFollowUp(true)} variant="contained">
                                                Start Design
                                            </Button> */}
                                            {/*added by wayne : 12-1-24 temporary add button for development purpose */}
                                            <Button
                                                onClick={() => setOpenFollowUpReactFlow(true)}
                                                variant="contained"
                                                sx={{ marginLeft: "10px" }}
                                            >
                                                Flow Design
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        )}
                        <Grid item display="flex" flexDirection={"column"} xs={12}>
                            <Paper
                                sx={{
                                    width: "100%",
                                    padding: "8px",
                                    height: "40vh",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                }}
                            >
                                <Grid container alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <Name>Assigned Numbers:</Name>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            placeholder="Search number"
                                            size="small"
                                            value={searchAssignedNumber}
                                            onChange={handleSearchAssignedNumber}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="clear search field"
                                                            onClick={clearSearchField}
                                                            edge="end"
                                                        >
                                                            <ClearIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    {currentFlow.id ? (
                                        <Grid item>
                                            <Button variant="contained" onClick={handleClickOpen}>
                                                Add
                                            </Button>
                                        </Grid>
                                    ) : (
                                        <></>
                                    )}
                                </Grid>

                                <GridDivider />
                                <Grid item xs={12} display="flex" justifyContent="center">
                                    <ToggleButtonGroup
                                        value={selectedObjects}
                                        onChange={handleObjectToggle}
                                        aria-label="platform"
                                    >
                                        <ToggleButton value="whatsapp" aria-label="whatsapp">
                                            <WhatsAppIcon
                                                style={{
                                                    color: getIconColor(
                                                        "whatsapp",
                                                        selectedObjects.includes("whatsapp")
                                                    ),
                                                }}
                                            />
                                        </ToggleButton>
                                        <ToggleButton value="page" aria-label="facebook">
                                            <FacebookIcon
                                                style={{
                                                    color: getIconColor(
                                                        "page",
                                                        selectedObjects.includes("page")
                                                    ),
                                                }}
                                            />
                                        </ToggleButton>
                                        <ToggleButton value="telegram" aria-label="telegram">
                                            <TelegramIcon
                                                style={{
                                                    color: getIconColor(
                                                        "telegram",
                                                        selectedObjects.includes("telegram")
                                                    ),
                                                }}
                                            />
                                        </ToggleButton>
                                        <ToggleButton value="instagram" aria-label="instagram">
                                            <InstagramIcon
                                                style={{
                                                    color: getIconColor(
                                                        "instagram",
                                                        selectedObjects.includes("instagram")
                                                    ),
                                                }}
                                            />
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>

                                {displayAssignedNumbers.map((item) => (
                                    <Box
                                        display={"flex"}
                                        width={"100%"}
                                        key={item.id}
                                        onClick={() => console.log(item)}
                                    >
                                        <Grid
                                            item
                                            xs={6}
                                            alignSelf={"center"}
                                            display="flex"
                                            alignItems="center"
                                        >
                                            {getIcon(item.object)}
                                            <Box ml={1}>{getName(item)}</Box>
                                        </Grid>
                                        <Grid item xs={5} alignSelf={"center"}>
                                            {getClientNumber(item.chatRoomId)}
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton onClick={() => handleDeleteAssignedNumber(item)}>
                                                <Icon>delete</Icon>
                                            </IconButton>
                                        </Grid>
                                    </Box>
                                ))}
                            </Paper>
                        </Grid>
                        <Grid item display="flex" flexDirection={"column"} xs={12}>
                            <Paper
                                sx={{
                                    width: "100%",
                                    padding: "8px",
                                    height: "40vh",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                }}
                            >
                                <Name>Blocked Numbers:</Name>
                                <TextField
                                    placeholder="Search blocked number"
                                    size="small"
                                    value={searchBlockedNumber}
                                    onChange={handleSearchBlockedNumber}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="clear search field"
                                                    onClick={clearSearchField}
                                                    edge="end"
                                                >
                                                    <ClearIcon onClick={() => setSearchBlockedNumber("")} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <GridDivider />
                                {currentFlow.blockedUsers &&
                                    currentFlow.blockedUsers
                                        .filter((blockedUser) => {
                                            console.log(blockedUser); // Log the blocked user

                                            if (!searchBlockedNumber) {
                                                // If searchBlockedNumber is empty, return true to include all blocked users
                                                return true;
                                            }

                                            // Directly compare the blockedUser string with searchBlockedNumber (case-insensitive)
                                            return blockedUser
                                                .toLowerCase()
                                                .includes(searchBlockedNumber.toLowerCase());
                                        })
                                        .map((item, index) => (
                                            <Box display="flex" width="100%" key={index}>
                                                <Grid item xs={6} alignSelf="center">
                                                    {item} {/* Display relevant user information */}
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <IconButton onClick={() => handleDeleteBlockedUser(item)}>
                                                        <Icon>delete</Icon>
                                                    </IconButton>
                                                </Grid>
                                            </Box>
                                        ))}
                            </Paper>
                        </Grid>
                        <GridDivider />
                    </Grid>
                </Grid>
            </Grid>
            <FullDialog
                handleClose={() => setOpenFollowUp(false)}
                open={openFollowUp}
                title="Follow up Design"
                Component={<FollowUpDesign flow={currentFlow} />}
            />
            {/*added by wayne 12-1-2024
            temporay add button for development purpose.*/}
            <FullDialog
                handleClose={() => setOpenFollowUpReactFlow(false)}
                open={openFollowUpReactFlow}
                title="Follow up Sequences Design"
                Component={<ReactFlowPage flow={currentFlow} />}
            />
            {/*added by wayne 9-11-2023
            additional dialog for user to manual add in single phone num or batch upload*/}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Phone Number</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ marginBottom: "16px" }}>
                        Choose to either add single phone number or batch add phone numbers.
                    </DialogContentText>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Add Single Phone Number</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <TextField
                                id="name"
                                label="Name"
                                variant="outlined"
                                type="text"
                                value={userInput.name}
                                onChange={handleChange}
                            />
                            <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
                                <TextField
                                    id="countrycode"
                                    label="Code"
                                    variant="outlined"
                                    type="text"
                                    value={userInput.countrycode}
                                    onChange={handleChange}
                                    style={{ width: "60px" }} // Set a fixed width for the country code field
                                />
                                <TextField
                                    id="phone"
                                    label="Phone Number"
                                    variant="outlined"
                                    type="tel"
                                    value={userInput.phone}
                                    onChange={handleChange}
                                    style={{ flex: 1 }} // Let the phone number field take the remaining width
                                />
                            </div>
                            <Button onClick={handleAddSingle} variant="contained">
                                SAVE
                            </Button>
                            <div style={{ fontSize: "9px" }}>
                                *Phone format should be country code and your phone number.
                            </div>
                            <div style={{ fontSize: "9px" }}>
                                Example: Malaysia phone number 012 8765 4321{" "}
                            </div>
                            <div style={{ fontSize: "9px" }}>Code : 60 Phone number : 1287654321</div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography>Batch Add Phone Numbers</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
                            <ExcelLikeInput handlePasteData={handlePasteData} />
                        </AccordionDetails>
                    </Accordion>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
            <FullDialog
                Component={<QASystemPage flowId={currentFlow?.id || ""} />}
                open={openQA}
                title={`QA Design Page - ${currentFlow?.name || ""}`}
                handleClose={() => setOpenQA(false)}
            />
        </Container>
    );
}

function isAllNumbers(text) {
    // Use a regular expression to check if the text contains only digits, spaces, or a plus sign
    const regex = /^[\d\s+]+$/;
    return regex.test(text);
}

function getClientNumber(chatRoomId) {
    return chatRoomId.split("-")[1];
}
