import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    Stack,
    TextField,
    useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getCountFromServer,
    getDoc,
    getDocs,
    limit,
    onSnapshot,
    orderBy,
    query,
    startAfter,
    updateDoc,
    where
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import useNotification from "../../context-utils/NotificationContext";
import { db } from "../../firebase/firebase-utils";


export default function UnresolvedQueriesPage({ user, handleRefresh }) {
    // const theme = useTheme();

    const [unresolvedQueries, setUnresolvedQueries] = useState([]);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [newCatalogName, setNewCatalogName] = useState("");
    const [creatingCatalog, setCreatingCatalog] = useState(false);
    const [newCatalogDialogOpen, setNewCatalogDialogOpen] = useState(false);

    const confirmation = useConfirmation();
    const notification = useNotification();

    const [currentPage, setCurrentPage] = useState(1);
    const [totalDocuments, setTotalDocuments] = useState(0);
    const [lastVisibleDocs, setLastVisibleDocs] = useState([]); // Store the last visible doc for each page
    const [render,setRender] = useState(false);
    const pageSize = 10; // Number of records per page

    useEffect(() => {
        if (!user || !user.clientId) return;

        const unresolvedQueryCollection = collection(db, "unresolvedQueries");
        const q = query(
            unresolvedQueryCollection,
            where("clientId", "==", user.clientId),
            where("status", "==", "pending")
        );

        const unsubscribe = onSnapshot(q, (snapshot) => {
            setTotalDocuments(snapshot.size); // Update total count with the size of the snapshot
            handleRefresh(); // Optionally call handleRefresh if you need it to refresh other things
        });

        return () => {
            unsubscribe(); // Cleanup the listener on unmount
        };
    }, [user]);


    useEffect(() => {
        if (!user || !user.clientId) return;

        const unresolvedQueryCollection = collection(db, "unresolvedQueries");

        const fetchQueries = () => {
            let q = query(
                unresolvedQueryCollection,
                where("clientId", "==", user.clientId),
                where("status", "==", "pending"),
                orderBy("date", "desc"),
                limit(pageSize)
            );

            // If not on the first page, start after the last visible document from the previous page
            if (currentPage > 1 && lastVisibleDocs[currentPage - 2]) {
                q = query(q, startAfter(lastVisibleDocs[currentPage - 2]));
            }

            const unsubscribe = onSnapshot(q, async (snapshot) => {
                if (!snapshot.empty) {
                    const lastDoc = snapshot.docs[snapshot.docs.length - 1];

                    // Store the last visible document for the current page
                    setLastVisibleDocs((prevDocs) => {
                        const newDocs = [...prevDocs];
                        newDocs[currentPage - 1] = lastDoc;
                        return newDocs;
                    });

                    const queries = await Promise.all(
                        snapshot.docs.map(async (item) => {
                            const queryData = item.data();

                            // Fetch the corresponding WhatsApp Flow Name
                            const whatsappFlowRef = doc(
                                db,
                                "users",
                                user.email,
                                "whatsappFlows",
                                queryData.whatsappFlowId
                            );
                            const whatsappFlowDoc = await getDoc(whatsappFlowRef);
                            const whatsappFlowName = whatsappFlowDoc.exists()
                                ? whatsappFlowDoc.data().name
                                : "Unknown";

                            // Fetch catalogs based on whatsappFlowId
                            const catalogsQuery = query(
                                collection(db, "catalogs"),
                                where("flowId", "==", queryData.whatsappFlowId)
                            );

                            const catalogsSnapshot = await getDocs(catalogsQuery);
                            const catalogs = catalogsSnapshot.docs.map((doc) => ({
                                id: doc.id,
                                title: doc.data().title,
                            }));

                            return {
                                id: item.id,
                                ...queryData,
                                whatsappFlowName,
                                catalogs, // Populated with fetched catalogs
                            };
                        })
                    );

                    setUnresolvedQueries(queries);
                } else {
                    setUnresolvedQueries([]);
                }
            });

            return unsubscribe; // Cleanup the subscription on unmount
        };

        const unsubscribe = fetchQueries();

        return () => {
            unsubscribe(); // Cleanup the listener when the component unmounts or user/clientId changes
        };
    }, [user, currentPage]);


    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleDeleteQuery = async (id) => {
        const response = await confirmation("Delete Query?", "Click ok to confirm");
        if (response) {
            try {
                const queryRef = doc(db, "unresolvedQueries", id);
                await deleteDoc(queryRef);

                // Update the state immediately
                setUnresolvedQueries((prevQueries) => prevQueries.filter((query) => query.id !== id));

                console.log("Query successfully deleted.");
                notification("Query deleted.", "success");
            } catch (error) {
                console.error("Error deleting query: ", error);
                notification("Error deleting.", "error");
            }
        }
    };

    const handleNewCatalogClick = (queryId) => {
        setCreatingCatalog(queryId); // Set the ID of the query where the catalog is being created
        setNewCatalogName("");
        setNewCatalogDialogOpen(true); // Open the dialog
    };

    const handleNewCatalogChange = (e) => {
        setNewCatalogName(e.target.value);
    };
    const handleSaveNewCatalog = async (queryId, whatsappFlowId) => {
        if (newCatalogName.trim()) {
            try {
                const catalogRef = await addDoc(collection(db, "catalogs"), {
                    title: newCatalogName,
                    flowId: whatsappFlowId,
                    admins: [user.email],
                    date: new Date(),
                });

                const newCatalog = {
                    id: catalogRef.id,
                    title: newCatalogName,
                };

                // Update the unresolvedQueries state with the new catalog
                setUnresolvedQueries((prevQueries) =>
                    prevQueries.map((query) => {
                        if (query.id === queryId) {
                            return {
                                ...query,
                                catalogs: [...query.catalogs, newCatalog],
                                selectedCatalog: catalogRef.id, // Automatically select the new catalog
                            };
                        }
                        return query;
                    })
                );

                notification("Catalog successfully created.", "success");
                setCreatingCatalog(false);
                setNewCatalogDialogOpen(false); // Close the dialog after saving
                setRender(!render);
            } catch (error) {
                console.error("Error creating catalog: ", error);
                notification("Error creating catalog.", "error");
            }
        }
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setCreatingCatalog(false);
        setNewCatalogDialogOpen(false); // Close the new catalog dialog
    };

    const handleQueryChange = (id, field, value) => {
        setUnresolvedQueries((prevQueries) =>
            prevQueries.map((query) => (query.id === id ? { ...query, [field]: value } : query))
        );
    };

    const handleSaveQuery = async (id, whatsappFlowId) => {
        const queryToUpdate = unresolvedQueries.find((query) => query.id === id);

        // Validation check for catalog, question, and answer
        if (!queryToUpdate.selectedCatalog || !queryToUpdate.question || !queryToUpdate.answer) {
            notification("Please fill in the catalog, question, and answer.", "warning");
            return;
        }

        const response = await confirmation("Update Query?", "Click ok to confirm");
        if (response) {
            try {
                // Find the catalog name
                const selectedCatalog = queryToUpdate.catalogs.find(
                    (catalog) => catalog.id === queryToUpdate.selectedCatalog
                );
                const catalogName = selectedCatalog ? selectedCatalog.title : "Unknown";

                // Update unresolvedQueries
                const queryRef = doc(db, "unresolvedQueries", id);
                const updatedFields = {
                    question: queryToUpdate.question,
                    answer: queryToUpdate.answer,
                    selectedCatalog: queryToUpdate.selectedCatalog,
                    resolvedAt: new Date(),
                    resolvedBy: user.clientId,
                    status: "resolved",
                };

                await updateDoc(queryRef, updatedFields);
                // update catalogs
                const catalogRef = doc(db, "catalogs", queryToUpdate.selectedCatalog);

                // Fetch the existing catalog data
                const catalogDoc = await getDoc(catalogRef);

                if (catalogDoc.exists()) {
                    const existingContent = catalogDoc.data().content || "";
                    const updatedCatalogFields = {
                        content: `${existingContent}\n Q: ${queryToUpdate.question}\n A: ${queryToUpdate.answer}`,
                    };
                    // Update the document with the appended content
                    await updateDoc(catalogRef, updatedCatalogFields);
                } else {
                    console.error("Catalog does not exist.");
                }

                // Update records
                const recordsRef = collection(db, "records");
                await addDoc(recordsRef, {
                    catalogId: queryToUpdate.selectedCatalog,
                    flowId: whatsappFlowId,
                    admins: [user.email],
                    input: `Title: ${catalogName}\nQ: ${queryToUpdate.question}\nA: ${queryToUpdate.answer}`,
                    createdBy: "user",
                    date: new Date(),
                });

                notification("Query successfully updated.", "success");
            } catch (error) {
                console.error("Error updating query: ", error);
                notification("Error updating.", "error");
            }
        }
    };

    return (
        <>
            <Stack
                spacing={2}
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "20px",
                }}
            >
                <Typography variant="h6">Unresolved Queries ({totalDocuments} pending items)</Typography>
            </Stack>
            <Stack
                spacing={2}
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "20px",
                }}
            >
                <Pagination
                    count={Math.ceil(totalDocuments / pageSize)} // Total pages calculated based on totalDocuments and pageSize
                    page={currentPage}
                    onChange={handlePageChange}
                    showFirstButton
                    showLastButton
                />
            </Stack>
            <Box
                sx={{ paddingLeft: "30px", paddingRight: "30px", paddingTop: "10px", paddingBottom: "10px" }}
            >
                {unresolvedQueries.map((query, index) => {
                    return (
                        <Accordion key={index}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                {query.subject}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body2">
                                    <strong>Client Name:</strong> {query.clientName}
                                </Typography>
                                <Typography variant="body2">
                                    <strong>Client Number:</strong> {query.clientNumber}
                                </Typography>
                                <Typography variant="body2">
                                    <strong>Question:</strong> {query.userInquiry}
                                </Typography>

                                <Typography variant="body2" sx={{ mt: 1 }}>
                                    <strong>Analysis:</strong> {query.analysis}
                                </Typography>
                                <Typography variant="body2" sx={{ mt: 1 }}>
                                    <strong>WhatsApp Flow Name:</strong> {query.whatsappFlowName} (
                                    {query.whatsappFlowId})
                                </Typography>
                                <Typography variant="body2" sx={{ mt: 2 }}>
                                    <strong>Select Catalog:</strong>
                                </Typography>

                                <Select
                                    value={query.selectedCatalog || ""}
                                    onChange={(e) =>
                                        handleQueryChange(query.id, "selectedCatalog", e.target.value)
                                    }
                                    fullWidth
                                >
                                    {query?.catalogs?.map((catalog) => (
                                        <MenuItem key={catalog.id} value={catalog.id}>
                                            {catalog.title}
                                        </MenuItem>
                                    ))}
                                    <MenuItem value="new" onClick={() => handleNewCatalogClick(query.id)}>
                                        + Create New Catalog
                                    </MenuItem>
                                </Select>

                                <TextField
                                    label="Question"
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    rows={4}
                                    value={query.question}
                                    onChange={(e) => handleQueryChange(query.id, "question", e.target.value)}
                                />
                                <TextField
                                    label="Answer"
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    multiline
                                    rows={4}
                                    placeholder="Enter your answer here"
                                    value={query.answer}
                                    onChange={(e) => handleQueryChange(query.id, "answer", e.target.value)}
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        marginTop: "16px",
                                        gap: "9px",
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        color="error"
                                        startIcon={<DeleteIcon />}
                                        onClick={() => handleDeleteQuery(query.id)}
                                    >
                                        Delete
                                    </Button>
                                    {/* <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleDialogClose()}
                                        >
                                            Cancel
                                        </Button> */}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleSaveQuery(query.id, query.whatsappFlowId)}
                                    >
                                        Update
                                    </Button>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Box>
            <Stack
                spacing={2}
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Pagination
                    count={Math.ceil(totalDocuments / pageSize)} // Total pages calculated based on totalDocuments and pageSize
                    page={currentPage}
                    onChange={handlePageChange}
                    showFirstButton
                    showLastButton
                />
            </Stack>
            <Dialog open={newCatalogDialogOpen} onClose={handleDialogClose} fullWidth maxWidth="sm">
                <DialogTitle>Create New Catalog</DialogTitle>
                <DialogContent dividers>
                    <TextField
                        value={newCatalogName}
                        onChange={handleNewCatalogChange}
                        label="New Catalog Name"
                        fullWidth
                        variant="outlined"
                    />
                    <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                        <Button
                            onClick={() => setNewCatalogDialogOpen(false)}
                            variant="contained"
                            sx={{ mr: 2 }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                handleSaveNewCatalog(
                                    creatingCatalog,
                                    unresolvedQueries.find((query) => query.id === creatingCatalog)
                                        .whatsappFlowId
                                );
                                setNewCatalogDialogOpen(false);
                            }}
                            variant="contained"
                        >
                            Save
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}
