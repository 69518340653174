import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useTheme } from "@mui/material";
import { green, grey } from "@mui/material/colors";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useConfirmation } from "../context-utils/ConfirmationContext";
import { InputContext } from "../context-utils/InputContext";
import { ListContext } from "../context-utils/ListContext";
import { NotificationContext } from "../context-utils/NotificationContext";
import { AuthContext } from "../context/AuthContext";
import { useGetFriends } from "../context/StoreContext";
import addFriend from "../functions/addFriend";
import assignAdmins from "../functions/assignAdmins";
import assignLeads from "../functions/assignLeads";
import convertDate from "../functions/common-functions/convertDate";
import exportToExcel from "../functions/common-functions/exportToExcel";
import { deleteLeads } from "../functions/deleteLeads";
import unassignAdmins from "../functions/unassignAdmins";
import unassignLeads from "../functions/unassignLeads";
import updateName from "../functions/updateName";
import useWindowSize from "../hooks/useWindowSize";
import LeadCardPage from "../pages/LeadCardPage";
import { checkItem } from "../pages/LeadsTablePage/checkItem";
import LeadsTablePageV2 from "../pages/LeadsTablePageV2/LeadsTablePageV2.jsx";
import { EMPTY, LEADS_HEADER, ROWS_PER_PAGE } from "../settings/settings";
import { tokens } from "../theme";
import { arrayIsEmpty } from "../utils-functions/arrayIsEmpty";
import getAdminList from "../utils-functions/getAdminList";
import getAssignList from "../utils-functions/getAssignList";
import FullDialog from "./FullDialog";
import { LeadsFlexBoxComponent, getDataFromSelect } from "./LeadsFlexBoxComponent";
import ManageFolders from "./ManageFolders";
import ManageLabels from "./ManageLabels";
import SelectComponent2 from "./SelectComponent2";

export function LeadsFlexBox({
    folder,
    leads,
    title,
    setMaxNumber,
    maxNumber,
    loading,
    leadsMode,
    setLoading,
    triggerSlide,
    displayHeader = true,
    enableSelect = true,
    followUpPage = false,
    limitActions = 3,
    displayOpenCard = true,
    desktopView,
    setDesktopview,
    sortByAssigned,
    setSortByAssigned,
    setStartDate,
    setEndDate,
    month,
    setMonth,
    year,
    setYear,
}) {
    const [searchAssign, setSearchAssign] = useState("");
    const [searchLabel, setSearchLabel] = useState();
    const [page, setPage] = useState(1);
    const [select, setSelect] = useState([]);
    const [openLabel, setOpenLabel] = useState(false);
    const [openCard, setOpenCard] = useState(false);
    const [openFolder, setOpenFolder] = useState(false);
    const [lead, setLead] = useState();
    const [showActions, setShowActions] = useState(true);
    const [search, setSearch] = useState("");
    const [search2, setSearch2] = useState([]);
    const [sortingOptions, setSortingOptions] = useState();

    const { user } = useContext(AuthContext);
    const { openGlobalList } = useContext(ListContext);
    const { openInputDialog } = useContext(InputContext);
    const { openNotification } = useContext(NotificationContext);

    const windowSize = useWindowSize();
    const isMobile = windowSize?.width < 800 ? true : false;

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const selectData = useMemo(() => {
        return getDataFromSelect(select, leads);
    }, [select]);

    useEffect(() => {
        setPage(1);
    }, [folder, searchLabel, searchAssign]);

    useEffect(() => {
        if (lead) {
            const f = leads?.find((l) => l.id === lead.id);
            if (f) {
                setLead(f);
            }
        }
    }, [leads]);

    const handleBack = () => {
        let newPage = page - 1;
        if (newPage < 1) return;
        setPage(newPage);
        window.scrollTo(0, 0);
    };

    const handleForward = () => {
        let newPage = page + 1;
        let totalPages = Math.ceil(leads.length / ROWS_PER_PAGE);
        if (newPage > totalPages) return;
        setPage(newPage);
        window.scrollTo(0, 0);
    };

    const handleClickDate = (lead) => {
        console.log(lead);
    };

    const handleClickSelect = (lead) => {
        if (select?.includes(lead.id)) {
            const index = select.findIndex((s) => s === lead.id);
            const newSelect = [...select];
            newSelect.splice(index, 1);
            setSelect(newSelect);
        } else {
            const newSelect = [...select];
            newSelect.push(lead.id);

            setSelect(newSelect);
        }
    };

    const confirmation = useConfirmation();

    const handleDelete = async () => {
        console.log(select);
        const response = await confirmation("Delete leads", "Press OK to confirm");
        if (response) {
            await deleteLeads(select);
            setSelect([]);
        }
    };

    const handleUnassignLeads = async () => {
        const selectData = getDataFromSelect(select, leads);
        const assignList = getAssignList(selectData);

        const response = await openGlobalList(assignList, "Unassign leads", "name", "face", false, true);
        console.log(response);
        if (response) {
            await unassignLeads(selectData, response.email);
        }
    };

    const handleUnassignAdmin = async () => {
        //added by wayne 8-11-2023
        //add on function to unassign admin at source lead page
        const selectData = getDataFromSelect(select, leads);

        const adminList = getAdminList(selectData);
        const response = await openGlobalList(adminList, "Unassign admin", "email", "face", false, true);
        console.log(response);
        if (response) {
            await unassignAdmins(selectData, response.email);
        }
    };

    const handleClick = async (id, lead) => {
        const response = await openInputDialog("Edit", "Edit the " + id, id, lead.name);
        console.log(response);
        if (response) {
            await updateName(response, lead);
        }
    };

    const getFriends = useGetFriends();

    const handleAssignLeads = async () => {
        const friends = await getFriends(user);
        chooseFriends(friends);
    };

    const handleAssignAdmin = async () => {
        const friends = await getFriends(user);
        chooseAdmin(friends);
    };

    async function chooseAdmin(friends) {
        //added by wayne 7-11-2023
        //to add in add admin function
        const response = await openGlobalList(friends, "Assign to", "displayName", "face", true, true);
        if (!response) return;
        if (response === "addAccount") {
            const email = await openInputDialog("Add friend", "Please enter your friend email", "Email", "");
            if (email) {
                setLoading(true);
                const friend = await addFriend(user, email, openNotification);
                setLoading(false);
                if (friend) {
                    await assignAdmins(user, friend, selectData, openNotification);
                    setSelect([]);
                }
            }
        } else {
            await assignAdmins(user, response, selectData, openNotification);
            setSelect([]);
        }
    }

    async function chooseFriends(friends) {
        const response = await openGlobalList(friends, "Assign to", "displayName", "face", true, true);
        if (!response) return;
        if (response === "addAccount") {
            const email = await openInputDialog("Add friend", "Please enter your friend email", "Email", "");
            if (email) {
                setLoading(true);
                const friend = await addFriend(user, email, openNotification);
                setLoading(false);
                if (friend) {
                    await assignLeads(user, friend, selectData, openNotification);
                    setSelect([]);
                }
            }
        } else {
            await assignLeads(user, response, selectData, openNotification);
            setSelect([]);
        }
    }

    const handleLabels = () => {
        setOpenLabel(false);
    };

    const handleFolders = () => {
        setOpenFolder(false);
    };

    const handleClickLabel = (lead) => {
        setLead(lead);
        setOpenLabel(true);
    };

    const handleAddFolder = async () => {
        setOpenFolder(true);
    };

    const handleClickOpen = (lead) => {
        console.log(lead);
        setLead(lead);
        setOpenCard(true);
    };

    const [totalPages, displayLeads] = useMemo(() => {
        let totalPages = 1;
        let displayLeads = [];
        if (leads) {
            displayLeads = [...leads];

            if (displayLeads) {
                if (searchAssign) {
                    displayLeads = displayLeads.filter((lead) => {
                        let display = false;
                        lead.assignments?.forEach((assignment) => {
                            if (assignment.assign?.name?.toLowerCase().includes(searchAssign.toLowerCase())) {
                                display = true;
                            }
                        });
                        return display;
                    });
                }

                // Search leads by Labels
                if (searchLabel) {
                    displayLeads = displayLeads.filter((lead) => {
                        let display = false;
                        display = searchLabel.every((sLabel) => {
                            if (leadsMode === "ASSIGNED_LEADS") {
                                return lead.labels?.some((label) => {
                                    if (label.userEmail === user.id) {
                                        if (label.label.toLowerCase().trim() === sLabel.label.toLowerCase()) {
                                            return true;
                                        }
                                        return false;
                                    }
                                    return false;
                                });
                            } else if (leadsMode === "SOURCE_LEADS") {
                                return lead.labels?.some((label) => {
                                    if (label.label.toLowerCase().trim() === sLabel.label.toLowerCase()) {
                                        return true;
                                    }
                                    return false;
                                });
                            }
                        });
                        return display;
                    });
                }

                // Search leads by name, phone or email
                if (search) {
                    displayLeads = displayLeads.filter((lead) => {
                        if (checkAndConvertToLower(lead.name).includes(search.toLowerCase())) {
                            return true;
                        }
                        if (checkAndConvertToLower(lead.phone).includes(search.toLowerCase())) {
                            return true;
                        }
                        if (checkAndConvertToLower(lead.email).includes(search.toLowerCase())) {
                            return true;
                        }
                        return false;
                    });
                }

                if (!arrayIsEmpty(search2)) {
                    for (let s of search2) {
                        if (s.id && s.value && s.mode) {
                            const h = LEADS_HEADER.find((item) => item.id === s.id);
                            const type = h.type || "text";

                            if (s.mode === "contain") {
                                if (s.value === EMPTY) {
                                    displayLeads = displayLeads.filter(
                                        (po) => checkItem(po[s.id], type, user) === ""
                                    );
                                } else {
                                    console.log("LeadsFlexBox------------------------");
                                    displayLeads = displayLeads.filter((po) => {
                                        return checkItem(po[s.id], type, user).toString().includes(s.value);
                                    });
                                }
                            } else {
                                if (s.value === EMPTY) {
                                    displayLeads = displayLeads.filter(
                                        (po) => checkItem(po[s.id], type, user) !== ""
                                    );
                                } else {
                                    displayLeads = displayLeads.filter(
                                        (po) => !checkItem(po[s.id], type, user).includes(s.value)
                                    );
                                }
                            }
                        }
                    }
                }

                if (!arrayIsEmpty(sortingOptions)) {
                    displayLeads = displayLeads.sort((a, b) => {
                        for (let option of sortingOptions) {
                            const { id, mode } = option;
                            const h = LEADS_HEADER.find((item) => item.id === id);
                            const type = h.type || "text";
                            const itemA = checkItem(a[id], type, user);
                            const itemB = checkItem(b[id], type, user);
                            console.log("itemA: ", itemA, " itemB: ", itemB);

                            // For numeric or alphanumeric sorting
                            if (typeof itemA === "number" && typeof itemB === "number") {
                                console.log("Sorting numeric or alphanumeric ");

                                if (mode === "ascd") {
                                    if (itemA < itemB) return -1;
                                    if (itemA > itemB) return 1;
                                } else {
                                    if (itemA < itemB) return 1;
                                    if (itemA > itemB) return -1;
                                }
                            }

                            // For string sorting
                            else {
                                console.log("Sorting string");
                                const comparison = itemA.localeCompare(itemB);
                                if (comparison !== 0) {
                                    return mode === "ascd" ? comparison : -comparison;
                                }
                            }
                        }

                        return 0;
                    });
                }

                if (sortByAssigned) {
                    console.log("sorting");
                    displayLeads = displayLeads.sort((a, b) => {
                        const dateA = convertDate(getLatestAssignedDate(a, user));
                        const dateB = convertDate(getLatestAssignedDate(b, user));
                        return dateB - dateA;
                    });
                }

                totalPages = Math.ceil(displayLeads.length / ROWS_PER_PAGE);
                displayLeads = displayLeads.slice((page - 1) * ROWS_PER_PAGE, page * ROWS_PER_PAGE);
            }
        }
        return [totalPages, displayLeads];
    }, [
        leads,
        page,
        searchAssign,
        searchLabel,
        search,
        search2,
        ROWS_PER_PAGE,
        sortByAssigned,
        sortingOptions,
    ]);

    const handleExportExcel = () => {
        console.log(displayLeads);
        exportToExcel(displayLeads, ["name", "phone", "email"]);
    };

    return (
        <React.Fragment>
            {desktopView && (
                <LeadsTablePageV2
                    displayHeader={displayHeader}
                    handleBack={handleBack}
                    page={page}
                    totalPages={totalPages}
                    handleForward={handleForward}
                    displayLeads={displayLeads}
                    leads={leads}
                    setPage={setPage}
                    title={title}
                    folder={folder}
                    setMaxNumber={setMaxNumber}
                    maxNumber={maxNumber}
                    searchAssign={searchAssign}
                    setSearchAssign={setSearchAssign}
                    searchLabel={searchLabel}
                    setSearchLabel={setSearchLabel}
                    showActions={showActions}
                    setShowActions={setShowActions}
                    search={search}
                    setSearch={setSearch}
                    loading={loading}
                    followUpPage={followUpPage}
                    select={select}
                    colors={colors}
                    handleClick={handleClick}
                    handleClickDate={handleClickDate}
                    handleClickSelect={handleClickSelect}
                    user={user}
                    leadsMode={leadsMode}
                    handleClickLabel={handleClickLabel}
                    isMobile={isMobile}
                    enableSelect={enableSelect}
                    handleClickOpen={handleClickOpen}
                    limitActions={limitActions}
                    setLead={setLead}
                    setOpenCard={setOpenCard}
                    displayOpenCard={displayOpenCard}
                    search2={search2}
                    setSearch2={setSearch2}
                    sortByAssigned={sortByAssigned}
                    setSortByAssigned={setSortByAssigned}
                    sortingOptions={sortingOptions}
                    setSortingOptions={setSortingOptions}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                />
            )}
            {!desktopView && (
                <LeadsFlexBoxComponent
                    displayHeader={displayHeader}
                    handleBack={handleBack}
                    page={page}
                    totalPages={totalPages}
                    handleForward={handleForward}
                    displayLeads={displayLeads}
                    leads={leads}
                    setPage={setPage}
                    title={title}
                    folder={folder}
                    setMaxNumber={setMaxNumber}
                    maxNumber={maxNumber}
                    searchAssign={searchAssign}
                    setSearchAssign={setSearchAssign}
                    searchLabel={searchLabel}
                    setSearchLabel={setSearchLabel}
                    showActions={showActions}
                    setShowActions={setShowActions}
                    search={search}
                    setSearch={setSearch}
                    loading={loading}
                    followUpPage={followUpPage}
                    select={select}
                    colors={colors}
                    handleClick={handleClick}
                    handleClickDate={handleClickDate}
                    handleClickSelect={handleClickSelect}
                    user={user}
                    leadsMode={leadsMode}
                    handleClickLabel={handleClickLabel}
                    isMobile={isMobile}
                    enableSelect={enableSelect}
                    handleClickOpen={handleClickOpen}
                    limitActions={limitActions}
                    setLead={setLead}
                    setOpenCard={setOpenCard}
                    displayOpenCard={displayOpenCard}
                    search2={search2}
                    setSearch2={setSearch2}
                    sortByAssigned={sortByAssigned}
                    setSortByAssigned={setSortByAssigned}
                    sortingOptions={sortingOptions}
                    setSortingOptions={setSortingOptions}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    year={year}
                    setYear={setYear}
                    month={month}
                    setMonth={setMonth}
                />
            )}
            <FullDialog
                open={openLabel}
                handleClose={handleLabels}
                title={"Labels"}
                Component={<ManageLabels lead={lead} setLead={setLead} handleClose={handleLabels} />}
            />
            <FullDialog
                open={openFolder}
                handleClose={handleFolders}
                title={"Folders"}
                Component={
                    <ManageFolders
                        lead={lead}
                        handleClose={handleFolders}
                        select={select}
                        selectData={selectData}
                    />
                }
            />
            <FullDialog
                open={openCard}
                handleClose={() => setOpenCard(false)}
                title={"Lead Card"}
                Component={
                    <LeadCardPage lead={lead} handleClose={() => setOpenCard(false)} leadsMode={leadsMode} />
                }
            />
            <SelectComponent2
                select={select}
                setSelect={setSelect}
                handleDelete={handleDelete}
                data={displayLeads}
                handleAssignLeads={handleAssignLeads}
                handleAssignAdmin={handleAssignAdmin}
                handleUnassignLeads={handleUnassignLeads}
                handleUnassignAdmin={handleUnassignAdmin}
                handleAddFolder={handleAddFolder}
                handleExportExcel={handleExportExcel}
                leadsMode={leadsMode}
            />
        </React.Fragment>
    );
}

export const checkSelected = (lead, select) => {
    const selected = select?.includes(lead.id);
    if (!selected) {
        return <CheckBoxOutlineBlankIcon />;
    } else {
        return <CheckBoxIcon />;
    }
};

export const getLabelColor = (lead, assign) => {
    if (lead.warriors?.includes(assign.email)) {
        return { fontColor: "white", color: green[500] };
    } else {
        return { fontColor: "white", color: grey[500] };
    }
};

const getLatestAssignedDate = (lead, user) => {
    if (lead.assignments) {
        const assignments = lead.assignments.filter((assignment) => assignment.assign.email === user.email);
        if (assignments.length > 0) {
            assignments.sort((a, b) => convertDate(b.date) - convertDate(a.date));
            return assignments[0].date;
        } else {
            return lead.created;
        }
    } else {
        return lead.created;
    }
};

const checkAndConvertToLower = (variable) => {
    if (typeof variable === "string") {
        return variable.toLowerCase().trim();
    } else if (typeof variable === "number") {
        return variable.toString();
    } else {
        return "";
    }
};

export const removeDuplicatedAssignments = (assignments) => {
    let array = [];
    assignments?.forEach((assignment) => {
        const found = array.find((a) => a.assign.email === assignment.assign.email);
        if (found) return;
        array.push(assignment);
    });
    return array;
};
