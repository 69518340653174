import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

export default async function updateAiDelayReply(user, currentFlow, second) {
    try {
        const docRef = doc(db, "users", user.id, "whatsappFlows", currentFlow.id);
        updateDoc(docRef, { aiDelayReply: second });
        console.log("updated model");
    } catch (error) {
        console.log(error);
    }
}
