import { Box, Icon, IconButton, MenuItem, Typography, Fab } from "@mui/material";
import { addEdge, Background, Controls, ReactFlow, useEdgesState, useNodesState } from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { debounce } from "lodash";
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    onSnapshot,
    orderBy,
    query,
    where,
    setDoc,
    getDocs,
} from "firebase/firestore";
import React, { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import SearchAddInput from "../../components/SearchAddInput/SearchAddInput";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import CustomEdge from "./CustomEdge";
import CustomNode from "./CustomNode";

const nodeTypes = { customNode: CustomNode };
const edgeTypes = { customEdge: CustomEdge };

export default function WorkFlowPage() {
    const [columnWidths, setColumnWidths] = React.useState(["20%", "80%"]);
    const [search, setSearch] = React.useState("");
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [currentWorkflow, setCurrentWorkflow] = React.useState(null);
    const [currentWorkflowId, setCurrentWorkflowId] = React.useState("");
    const [workflows, setWorkflows] = React.useState([]);

    const { user } = useContext(AuthContext);

    const nodesToUpdateRef = useRef({});

    useEffect(() => {
        if (currentWorkflowId) {
            const unsubscribe = onSnapshot(doc(db, "workflows", currentWorkflowId), (doc) => {
                const workflow = mapDocSnapshot(doc);
                setCurrentWorkflow(workflow);
            });

            const loadData = async () => {
                // Fetch nodes and edges for the current workflow
                const nodesRef = collection(db, "workflows", currentWorkflowId, "nodes");
                const nodesSnapshot = await getDocs(nodesRef);
                const fetchedNodes = mapSnapshot(nodesSnapshot);
                setNodes(fetchedNodes);

                const edgesRef = collection(db, "workflows", currentWorkflowId, "edges");
                const edgesSnapshot = await getDocs(edgesRef);
                const fetchedEdges = mapSnapshot(edgesSnapshot);
                console.log("Fetched edges: ", fetchedEdges);
                setEdges(fetchedEdges);
            };

            loadData();

            return () => {
                unsubscribe();
            };
        }
    }, [currentWorkflowId]);

    useEffect(() => {
        const collectionRef = collection(db, "workflows");
        const q = query(collectionRef, where("admins", "array-contains", user.id), orderBy("date", "desc"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const workFlows = mapSnapshot(querySnapshot);
            setWorkflows(workFlows);
        });

        return unsubscribe;
    }, [user]);

    const onConnect = useCallback(
        (connection) => {
            const newEdge = { ...connection, animated: true, type: "customEdge" };
            setEdges((eds) => addEdge(newEdge, eds));
            saveEdgeToFirestore([newEdge], true);
        },
        [setEdges, currentWorkflowId]
    );

    const handleAddWorkflow = useCallback(async () => {
        const collectionRef = collection(db, "workflows");
        const response = await addDoc(collectionRef, {
            name: search || "New workflow",
            date: new Date(),
            admins: [user.id],
        });
        console.log("Document written with ID: ", response.id);
        setCurrentWorkflowId(response.id);
    }, [search, user.id]);

    const handleDeleteWorkflow = useCallback(async (event, id) => {
        event.stopPropagation();
        console.log("Deleting workflow with ID: ", id);
        const docRef = doc(db, "workflows", id);
        await deleteDoc(docRef);
        console.log("Document deleted with ID: ", id);
    }, []);

    const saveNodesToFirestore = useCallback(async (node) => {
        if (!currentWorkflowId) return;

        const nodesCollectionRef = collection(db, "workflows", currentWorkflowId, "nodes");

        console.log(node);

        const response = await addDoc(nodesCollectionRef, node);
        console.log("Document written with ID: ", response.id);
        return response.id;
    }, []);

    const updateNodesInFirestore = useCallback(async (nodesToUpdate) => {
        if (!currentWorkflowId) return;
        const nodesCollectionRef = collection(db, "workflows", currentWorkflowId, "nodes");

        const updatePromises = Object.values(nodesToUpdate).map((node) => {
            const docRef = doc(nodesCollectionRef, node.id);
            return setDoc(docRef, { position: node.position }, { merge: true });
        });

        await Promise.all(updatePromises);
        console.log(`${Object.keys(nodesToUpdate).length} nodes updated in Firestore`);
    }, []);

    const saveEdgeToFirestore = useCallback(async (newEdges, createNewEdge = false) => {
        //
        if (!currentWorkflowId) return;

        const edgesCollectionRef = collection(db, "workflows", currentWorkflowId, "edges");

        console.log(newEdges);

        for (const edge of newEdges) {
            if (createNewEdge) {
                const response = await addDoc(edgesCollectionRef, edge);
                console.log("New Edge created with ID: ", response.id);
            } else {
                if (edge.type === "remove") {
                    console.log("Deleting edge: ", edge);
                    const docRef = doc(edgesCollectionRef, edge.id);
                    await deleteDoc(docRef);
                    console.log("Edge deleted with ID: ", edge.id);
                    return;
                }

                const docRef = doc(edgesCollectionRef, edge.id);
                await setDoc(docRef, edge, { merge: true });
            }
        }
    }, []);

    // Memoize the debounced save function
    const debouncedSaveNodesToFirestore = useMemo(
        () =>
            debounce(() => {
                const nodesToUpdate = nodesToUpdateRef.current;
                nodesToUpdateRef.current = {};
                updateNodesInFirestore(nodesToUpdate);
            }, 1000),
        [currentWorkflowId]
    );

    // Update the onNodesChange handler to save changes to Firestore
    // Trigger the debounced save when nodes change
    // Update the onNodesChange handler to accumulate changes
    const handleNodesChange = useCallback(
        (changes) => {
            onNodesChange(changes);
            changes.forEach((change) => {
                if (change.type === "position" || change.type === "dimensions") {
                    const updatedNode = nodes.find((n) => n.id === change.id);
                    if (updatedNode) {
                        nodesToUpdateRef.current[change.id] = {
                            ...nodesToUpdateRef.current[change.id],
                            ...updatedNode,
                            ...change,
                        };
                    }
                }
            });
            debouncedSaveNodesToFirestore();
        },
        [nodes]
    );

    // Update the onEdgesChange handler to save changes to Firestore
    const handleEdgesChange = useCallback(
        (changes) => {
            console.log("Changes: ", changes);
            onEdgesChange(changes);
            const updatedEdges = changes.map((change) => ({
                ...edges.find((edge) => edge.id === change.id),
                ...change,
            }));
            saveEdgeToFirestore(updatedEdges, false);
        },
        [edges, onEdgesChange, saveEdgeToFirestore]
    );

    // function to add a node
    const addNode = useCallback(async () => {
        const newNode = {
            name: `node-${nodes.length + 1}`,
            type: "customNode",
            position: { x: 0, y: 0 },
            data: { label: `Node ${nodes.length + 1}` },
        };
        const id = await saveNodesToFirestore(newNode);
        setNodes((nds) => [...nds, { ...newNode, id }]);
    }, [nodes.length, setNodes, saveNodesToFirestore]);

    // Clean up the debounce function on component unmount
    useEffect(() => {
        return async () => {
            const nodesToUpdate = nodesToUpdateRef.current;
            nodesToUpdateRef.current = {};
            await updateNodesInFirestore(nodesToUpdate);
            debouncedSaveNodesToFirestore.cancel();
        };
    }, [debouncedSaveNodesToFirestore]);

    return (
        <>
            <Box display="flex" width="100%" height={"calc(100vh - 55px)"} position="relative">
                <Box display="flex" height="100%" width={columnWidths[0]} sx={{ border: "1px solid grey" }}>
                    <Box display={"flex"} flexDirection={"column"} width={"100%"}>
                        <SearchAddInput
                            search={search}
                            setSearch={setSearch}
                            placeholder={"Add / Search workflows ..."}
                            handleAdd={handleAddWorkflow}
                        />
                        {workflows.map((workflow) => (
                            <MenuItem key={workflow.id} onClick={() => setCurrentWorkflowId(workflow.id)}>
                                <Box
                                    display="flex"
                                    alignItems={"center"}
                                    gap={1}
                                    justifyContent={"space-between"}
                                    width={"100%"}
                                >
                                    <Box>
                                        <Typography sx={{ flex: 1 }}>{workflow.name}</Typography>
                                    </Box>
                                    <Box>
                                        <IconButton onClick={(e) => handleDeleteWorkflow(e, workflow.id)}>
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </Box>
                                </Box>
                            </MenuItem>
                        ))}
                    </Box>
                </Box>
                <Box display="flex" height="100%" width={columnWidths[1]}>
                    <div style={{ height: "100%", width: "100%" }}>
                        <ReactFlow
                            nodes={nodes}
                            onNodesChange={handleNodesChange}
                            edges={edges}
                            onEdgesChange={handleEdgesChange}
                            onConnect={onConnect}
                            nodeTypes={nodeTypes}
                            edgeTypes={edgeTypes}
                            fitView
                        >
                            <Background />
                            <Controls />
                        </ReactFlow>
                    </div>
                </Box>
                <Fab
                    color="primary"
                    aria-label="add"
                    style={{ position: "absolute", top: 16, right: 16 }}
                    onClick={addNode}
                >
                    <Icon>add</Icon>
                </Fab>
            </Box>
        </>
    );
}
