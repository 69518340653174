import { Box, Typography, IconButton } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useTheme } from "@emotion/react";

export default function QuotedMessageComponent({ quotedMessage, sender, onClose }) {
    const theme = useTheme();
    return (
        <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%" mb="8px" mx='12px' mt='8px'>
            <Typography
                variant="body2"
                color={theme.palette.mode === "light" ? "textSecondary" : "textPrimary"}
                sx={{ fontWeight: "bold", mb: "4px" }}
            >
                Reply:
            </Typography>
            <Box
                sx={{ backgroundColor: theme.palette.mode === "light" ? "#e0f7fa" : "#424242" }}
                display="flex"
                alignItems="center"
                border="1px solid #ccc"
                borderLeft="3px solid #34b7f1"
                borderRadius="8px"
                p="8px"
                width="100%" // Ensure the quoted message box takes full width
            >
                <Box flex="1">
                    <Typography
                        variant="body2"
                        color={theme.palette.mode === "light" ? "textSecondary" : "textPrimary"}
                        sx={{ fontWeight: "bold" }}
                    >
                        {sender}
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            display: "-webkit-box",
                            overflow: "hidden",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                            textOverflow: "ellipsis",
                        }}
                    >
                        {quotedMessage}
                    </Typography>
                </Box>
                <IconButton onClick={onClose}>
                    <HighlightOffIcon />
                </IconButton>
            </Box>
        </Box>
    );
}
