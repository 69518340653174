import { CssBaseline, ThemeProvider } from "@mui/material";
import { useContext, useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import Menu from "./components/Menu";
import { AuthContext } from "./context/AuthContext";
import useVersionCheck from "./hooks/useVersionCheck";
import AdminPage from "./pages/AdminPage/WaynePlaygroundPage";
import AffiliatePage from "./pages/AffiliatePage/AffiliatePage";
import CommissionPage from "./pages/AffiliatePage/CommissionPage";
import PaymentsPage from "./pages/AffiliatePage/PaymentsPage";
import UpdateAffiliates from "./pages/AffiliatePage/UpdateAffiliates";
import AppointmentCalenderPage from "./pages/AppointmentCalenderPage/AppointmentCalenderPage";
import AssignedLeadsPage from "./pages/AssignedLeadsPage";
import CalendarPage from "./pages/CalendarPage";
import ChatInboxPage from "./pages/ChatInboxPage/ChatInboxPage";
import ClientsPage from "./pages/ClientsPage/ClientsPage";
import DjcFunnelsPage from "./pages/DjcFunnelsPage/DjcFunnelsPage";
import FacebookConnectPage from "./pages/FacebookConnectPage/facebook-connect";
import LeadGenForm from "./pages/FacebookConnectPage/leadgen-forms";
import FacebookLoginPage from "./pages/FacebookLoginPage";
import FoldersManagementPage from "./pages/FoldersPage/FoldersManagementPage";
import FollowUpLeadsPage from "./pages/FollowUpLeadsPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import AffiliateSubmissionsPage from "./pages/FormsPage/AffiliateSubmissionsPage";
import CreateFormPage from "./pages/FormsPage/CreateFormPage";
import FormAffiliatePage from "./pages/FormsPage/FormAffiliatePage";
import FormPage from "./pages/FormsPage/FormPage";
import GoogleConnectPage from "./pages/GoogleConnectPage/GoogleConnectPage";
import HomePage from "./pages/HomePage";
import ImageGenerationPage from "./pages/ImageGenerationPage/ImageGenerationPage";
import ConnectInstagramPage from "./pages/InstagramPage/ConnectInstagramPage";
import LandingPage from "./pages/LandingPage";
import LeadsDashBoardPage from "./pages/LeadsDashboard/LeadsDashBoardPage";
import LeadsFlexboxPage from "./pages/LeadsFlexbox/LeadsFlexboxPage";
import LeadsTablePageV3 from "./pages/LeadsTablePageV3/LeadsTablePageV3";
import ListingPage from "./pages/ListingPage/ListingPage";
import ListingPageV2 from "./pages/ListingPageV2/ListingPageV2";
import MessengerInboxPage from "./pages/MessengerInboxPage/MessengerInboxPage";
import NewLeadsTable from "./pages/NewLeadsTable/NewLeadsTable";
import NotFoundPage from "./pages/NotFoundPage";
import GeminiChatPage from "./pages/Playground/GeminiChatPage";
import PlaygroundPage from "./pages/Playground/PlaygroundPage";
import QASystemPage from "./pages/QASystemPage/QASystemPage";
import RecordPage from "./pages/QASystemPage/RecordPage";
import OverviewFlowPage from "./pages/ReactFlowPage/ReactFlowPage";
import { default as ListScheduledMessages, default as ListScheduledMessagesPage } from "./pages/ScheduledMessages/ListScheduledMessages";
import ListWhatsappMessagesPage from "./pages/ScheduledMessages/ListWhatsappMessages";
import SignInPage from "./pages/SignInPage";
import SignOutPage from "./pages/SignOutPage";
import SignUpPage from "./pages/SignUpPage";
import SignUpTrialPage from "./pages/SignUpPage/SignUpTrialPage";
import SourceLeadsPage from "./pages/SourceLeadsPage";
import StripeCheckoutPage from "./pages/StripeCheckoutPage.jsx/StripeCheckoutPage";
import StripeCheckoutPageEliteone from "./pages/StripeCheckoutPage.jsx/StripeCheckoutPageEliteone";
import StripeCheckoutPageFreeTrial from "./pages/StripeCheckoutPage.jsx/StripeCheckoutPageFreeTrial";
import StripeCheckoutPagePremium10 from "./pages/StripeCheckoutPage.jsx/StripeCheckoutPagePremium10";
import StripeCheckoutPageYearly from "./pages/StripeCheckoutPage.jsx/StripeCheckoutPageYearly";
import TelegramWebHookGenerator from "./pages/TelegramBot/TelegramWebhookGenerator";
import TelegramConnectPage from "./pages/TelegramConnect/TelegramConnectPage";
import { default as CounterButton, default as Test1 } from "./pages/Tests/Test";
import TutorialsPage from "./pages/TutorialsPage/TutorialsPage";
import UploadDataPage from "./pages/UploadDataPage";
import UserCardPage from "./pages/UserCardPage";
import UsersPage from "./pages/UsersPage/UsersPage";
import FollowUpDesign from "./pages/WhatsappFlow/FollowUpDesign";
import WhatsappFlowPage from "./pages/WhatsappFlow/WhatsappFlowPage";
import WhatsappFlowUsagePage from "./pages/WhatsappFlow/WhatsappFlowUsage";
import WhatsappMessagePage from "./pages/WhatsappMessagePage";
import BackendDashboard from "./pages/WhatsappWebPage/BackendDashboard";
import ChatHistoryPage from "./pages/WhatsappWebPage/ChatHistoryPage";
import WebHookGenerator from "./pages/WhatsappWebPage/WebHookGenerator";
import WhatsappBroadcastPage from "./pages/WhatsappWebPage/WhatsappBroadcastPage";
import WhatsappWebPage from "./pages/WhatsappWebPage/WhatsappWebPage";
import WorkFlowPage from "./pages/WorkflowPage/WorkFlowPage";
import GoogleAIPage from "./tests/GoogleAIPage";
import TestPage from "./tests/TestPage";
import TestRawMessagesPage from "./tests/TestRawMessagesPage";
import UploadImageFromUrlPage from "./tests/UploadImageFromUrlPage";
import { ColorModeContext, useMode } from "./theme";
import ManageTelegramBots from "./pages/TelegramBot/ManageTelegramBots";
import SalesOverviewPage from "./pages/Sales/SalesOverviewPage";

function App() {
    const { user } = useContext(AuthContext);
    const [theme, colorMode] = useMode();

    // Check version is the latest, if not refresh the app
    useVersionCheck();

    const displayComponent = useMemo(() => {
        const displayComponent = user ? withUser() : noUser();
        return displayComponent;
    }, [user]);

    // useEffect(() => {
    //     const loadMessaging = async () => {
    //         try {
    //             // Add the public key generated from the console here.
    //             const currentToken = await getToken(messaging, {
    //                 vapidKey:
    //                     "BA4EzA3wlPaZvwvV1ZWCyjChJQOCa7kYtFQ9r4OpOtPdQE7zTr2vT-pWOvq_qM2Bu3QvcKlGwL2tWOnCJmuAWhc",
    //             });
    //             if (currentToken) {
    //                 console.log("currentToken", currentToken);
    //             } else {
    //                 console.log("Requesting permission...");
    //                 Notification.requestPermission().then((permission) => {
    //                     if (permission === "granted") {
    //                         console.log("Notification permission granted.");
    //                     }
    //                 });
    //             }
    //         } catch (err) {
    //             console.log("An error occurred while retrieving token. ", err);
    //         }
    //     };

    //     loadMessaging();
    // }, []);

    return (
        <div>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <div className="app">
                        <main className="content">
                            <Menu user={user} />
                            {displayComponent}
                        </main>
                    </div>
                </ThemeProvider>
            </ColorModeContext.Provider>
        </div>
    );
}

export default App;

// Logged In condition
function withUser() {
    return (
        <Routes>
            <Route index element={<HomePage />} />
            <Route exact path="/sign-up" element={<SignUpPage />} />
            <Route exact path="/sign-up/:referral" element={<SignUpPage />} />
            <Route exact path="/whatsapp-message-page" element={<WhatsappMessagePage />} />
            <Route exact path="/source-leads-page" element={<SourceLeadsPage />} />
            <Route exact path="/assigned-leads-page" element={<AssignedLeadsPage />} />
            <Route exact path="/follow-up-leads-page" element={<FollowUpLeadsPage />} />
            <Route exact path="/leads-table-page-v3" element={<LeadsTablePageV3 />} />
            <Route exact path="/leads-flexbox-page" element={<LeadsFlexboxPage />} />
            {/* //  */}
            <Route exact path="/user-card-page" element={<UserCardPage />} />
            <Route exact path="/calendar-page" element={<CalendarPage />} />
            <Route exact path="/upload-data-page" element={<UploadDataPage />} />
            <Route exact path="/whatsapp-web-page" element={<WhatsappWebPage />} />
            <Route exact path="/overview-flow-page" element={<OverviewFlowPage />} />
            <Route exact path="/playground-page" element={<PlaygroundPage />} />
            <Route exact path="/whatsapp-broadcast-page" element={<WhatsappBroadcastPage />} />
            <Route exact path="/new-leads-page" element={<NewLeadsTable />} />
            <Route exact path="/whatsapp-flow-page" element={<WhatsappFlowPage />} />
            <Route exact path="/backend-dashboard-page" element={<BackendDashboard />} />
            <Route exact path="/webhook-generator-page" element={<WebHookGenerator />} />
            <Route exact path="/facebook-login-page" element={<FacebookLoginPage />} />
            <Route exact path="/facebook-connect-page" element={<FacebookConnectPage />} />
            <Route exact path="/telegram-connect-page" element={<TelegramConnectPage />} />
            <Route exact path="/stripe-checkout-page" element={<StripeCheckoutPage />} />
            <Route exact path="/stripe-checkout-page/:params" element={<StripeCheckoutPage />} />
            <Route exact path="/stripe-checkout-page-freetrial" element={<StripeCheckoutPageFreeTrial />} />
            <Route exact path="/stripe-checkout-page-eliteone" element={<StripeCheckoutPageEliteone />} />
            <Route exact path="/stripe-checkout-page-yearly" element={<StripeCheckoutPageYearly />} />
            <Route exact path="/stripe-checkout-page-premium-10" element={<StripeCheckoutPagePremium10 />} />
            {/* // */}
            <Route exact path="/chat-history-page" element={<ChatHistoryPage />} />
            <Route exact path="/djc-funnel-page" element={<DjcFunnelsPage />} />
            <Route exact path="/users-page" element={<UsersPage />} />
            <Route exact path="/whatsapp-flow-usage-page" element={<WhatsappFlowUsagePage />} />
            <Route exact path="/follow-up-design" element={<FollowUpDesign />} />
            <Route exact path="/leads-gen-form" element={<LeadGenForm />} />
            <Route exact path="/test" element={<CounterButton />} />

            <Route exact path="/test1-page" element={<Test1 />} />
            <Route exact path="/test-raw-messages-page" element={<TestRawMessagesPage />} />
            <Route exact path="/upload-image-from-url-page" element={<UploadImageFromUrlPage />} />

            <Route exact path="/test-page" element={<TestPage />} />

            <Route exact path="/facebook-login-page" element={<FacebookLoginPage />} />
            <Route exact path="/sign-out" element={<SignOutPage />} />
            <Route exact path="/affiliate" element={<AffiliatePage />} />
            <Route exact path="/sign-up" element={<SignUpPage />} />
            <Route exact path="/sign-up/:referral" element={<SignUpPage />} />

            <Route exact path="/update-affiliates-page" element={<UpdateAffiliates />} />
            <Route exact path="/leads-dashboard-page" element={<LeadsDashBoardPage />} />
            <Route exact path="/commission-page" element={<CommissionPage />} />
            <Route exact path="/payments-page" element={<PaymentsPage />} />
            <Route exact path="/payments-page/:param" element={<PaymentsPage />} />
            <Route exact path="/folders-management-page" element={<FoldersManagementPage />} />
            <Route exact path="/telegram-webhook-generator-page" element={<TelegramWebHookGenerator />} />
            <Route exact path="/create-form-page" element={<CreateFormPage />} />
            <Route exact path="/form/:formId" element={<FormAffiliatePage />} />
            <Route exact path="/form/:formId/:referralCode" element={<FormPage />} />
            <Route exact path="/form/affiliate-submissions-page" element={<AffiliateSubmissionsPage />} />
            <Route path="/affiliate" element={<AffiliatePage />} />
            <Route path="/sign-up" element={<SignUpPage />} />
            <Route path="/sign-up/:referral" element={<SignUpPage />} />
            <Route path="/update-affiliates-page" element={<UpdateAffiliates />} />
            <Route path="/leads-dashboard-page" element={<LeadsDashBoardPage />} />
            <Route path="/commission-page" element={<CommissionPage />} />
            <Route path="/payments-page" element={<PaymentsPage />} />
            <Route path="/folders-management-page" element={<FoldersManagementPage />} />
            <Route path="/admin-page" element={<AdminPage />} />
            <Route path="/listing-page" element={<ListingPage />} />
            <Route path="/listing-pageV2" element={<ListingPageV2 />} />
            <Route path="/telegram-webhook-generator-page" element={<TelegramWebHookGenerator />} />
            <Route path="/create-form-page" element={<CreateFormPage />} />
            <Route path="/google-ai-page" element={<GoogleAIPage />} />
            <Route path="/google-connect-page" element={<GoogleConnectPage />} />
            <Route path="/chat-inbox-page" element={<ChatInboxPage />} />
            <Route path="/clients-page" element={<ClientsPage />} />
            <Route path="/tutorials-page" element={<TutorialsPage />} />

            <Route path="/sign-up-trial" element={<SignUpTrialPage />} />
            <Route path="/sign-up-trial/:referral" element={<SignUpTrialPage />} />

            <Route path="/connect-instagram-page" element={<ConnectInstagramPage />} />
            <Route path="/messenger-inbox-page" element={<MessengerInboxPage />} />

            <Route path="/list-scheduled-messages-page" element={<ListScheduledMessages />} />
            <Route path="/list-whatsapp-messages-page" element={<ListWhatsappMessagesPage />} />

            <Route path="/qa-system-page" element={<QASystemPage />} />
            <Route path="/gemini-chat-page" element={<GeminiChatPage />} />

            <Route path="/appointment-calender" element={<AppointmentCalenderPage />} />
            <Route path="/records/:recordId" element={<RecordPage />} />

            <Route path="/work-flow-page" element={<WorkFlowPage />} />

            <Route path="/list-scheduled-messages-page" element={<ListScheduledMessagesPage />} />

            <Route path="/image-generation-page" element={<ImageGenerationPage />} />

            <Route path="/manage-telegram-bots-page" element={<ManageTelegramBots />} />

            <Route path="/sales-overview-page" element={<SalesOverviewPage />} />

            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
}

// Logged out condition
function noUser() {
    return (
        <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route exact path="/sign-up" element={<SignUpPage />} />
            <Route exact path="/sign-up/:referral" element={<SignUpPage />} />
            <Route exact path="/sign-in" element={<SignInPage />} />
            <Route exact path="/sign-in/:path/:path2" element={<SignInPage />} />
            <Route path="*" element={<LandingPage />} />
            <Route exact path="forgot-password-page" element={<ForgotPasswordPage />} />
            <Route exact path="/form/:formId/:referralCode" element={<FormPage />} />
            <Route path="/records/:recordId" element={<RecordPage />} />

            <Route path="/sign-up-trial" element={<SignUpTrialPage />} />
            <Route path="/sign-up-trial/:referral" element={<SignUpTrialPage />} />
        </Routes>
    );
}
