import { collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import { convertDate } from "../FacebookConnectPage/convertDate";

export default async function fixAssignedLeads(user) {
    try {
        const collectionRef = collection(db, "leads");
        const q = query(collectionRef, where("warriors", "array-contains", user.id));
        const querySnapshot = await getDocs(q);
        const leads = mapSnapshot(querySnapshot);

        const filteredLeads = leads.filter((lead) => !lead.assignedDate);
        await Promise.all(
            filteredLeads.map(async (lead) => {
                let assignedDate;
                if (lead.assignments) {
                    const { assignments } = lead;
                    for (let i = 0; i < assignments.length; i++) {
                        if (assignments[i].assign.email === user.id) {
                            assignedDate = assignments[i].date;
                            break;
                        }
                    }
                }
                if (!assignedDate) assignedDate = lead.created;
                assignedDate = convertDate(assignedDate);
                const docRef = doc(db, "leads", lead.id);
                const response = await updateDoc(docRef, { assignedDate });
                console.log("Updated", lead.id);
                return response;
            })
        );
        const docRef = doc(db, "users", user.id);
        await updateDoc(docRef, { fixedAssignedLeads: true });

        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
}
