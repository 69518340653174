import { TelegramMessage } from "../../../functions/src/types/telegramTypes";

const sendTelegramBotMessage = async (telegramMessage: TelegramMessage, botToken: string) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        text: telegramMessage.body,
        chat_id: telegramMessage.to,
        parse_mode: "MarkdownV2",
        disable_web_page_preview: true,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
    };

    try {
        const response = await fetch(`https://api.telegram.org/bot${botToken}/sendMessage`, requestOptions);
        const result = await response.json();
        console.log(result);
        return result.ok;
    } catch (error) {
        console.error(error);
    }
};

export default sendTelegramBotMessage;
