import { addDoc, collection, Timestamp } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import checkAppointmentAvailability from "../pages/AppointmentCalenderPage/checkAppointmentAvailability";
import checkOffDays from "../pages/AppointmentCalenderPage/checkOffDays";

function standardizeTime(input) {
    // Remove spaces and convert to lowercase for easier parsing
    input = input.replace(/\s+/g, "").toLowerCase();

    // Extract time and modifier
    let time = input.match(/\d+[:.]\d+|\d+/g)[0]; // Matches "3:00", "3.00", or "3"
    let modifier = input.match(/am|pm/g)?.[0] || ""; // Matches "am" or "pm" if present

    // Convert "3" or "3:00" or "3.00" formats to a full time format
    if (!time.includes(":") && !time.includes(".")) {
        time += ":00"; // Append ":00" if no minutes are specified
    }

    time = time.replace(".", ":"); // Replace any dots with colons for standardization

    // Parse hours and minutes
    const [hours, minutes] = time.split(":").map(Number);
    let hour24 = hours % 12; // Converts "12" to "0" for correct 12-hour format processing
    if (modifier === "pm") hour24 += 12;

    return `${hour24.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
}

function standardizeDate(input) {
    let dateParts, month, day, year;
    const months = {
        jan: "01",
        feb: "02",
        mar: "03",
        apr: "04",
        may: "05",
        jun: "06",
        jul: "07",
        aug: "08",
        sep: "09",
        oct: "10",
        nov: "11",
        dec: "12",
    };

    // Normalize the input by removing any non-alphanumeric characters, except the delimiter
    input = input.toLowerCase().replace(/[^0-9a-z-\/\s]/g, "");

    // Match directly if the format includes a month name
    const match = input.match(/(\d{1,2})(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)(\d{4})/);
    if (match) {
        day = match[1];
        month = months[match[2]];
        year = match[3];
    } else {
        // Handle formats with delimiters
        if (input.includes("-")) {
            dateParts = input.split("-");
        } else if (input.includes("/")) {
            dateParts = input.split("/");
        } else if (input.includes(" ")) {
            dateParts = input.split(" ");
        } else {
            return null; // Unsupported format or further logic may be added here
        }

        // Attempt to identify parts based on common ordering
        if (dateParts.length === 3) {
            // Assuming the format could be either D-M-Y or M-D-Y; check typical range to infer
            if (parseInt(dateParts[0]) > 12) {
                day = dateParts[0];
                month = dateParts[1];
                year = dateParts[2];
            } else if (parseInt(dateParts[1]) > 12) {
                month = dateParts[0];
                day = dateParts[1];
                year = dateParts[2];
            } else {
                // Default to D-M-Y if ambiguous
                day = dateParts[0];
                month = dateParts[1];
                year = dateParts[2];
            }
        } else {
            return null; // Invalid format
        }
    }

    // Validate and format
    day = parseInt(day).toString().padStart(2, "0");
    month = parseInt(month).toString().padStart(2, "0"); // Ensures numeric month is also padded

    if (day && month && year) {
        return `${day}-${month}-${year}`;
    } else {
        return null; // Final check for validity
    }
}

const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};

function parseDateTimeForMalaysia(dateStr, timeStr) {
    // console.log("dateStr:", dateStr);
    // console.log("timeStr:", timeStr);
    const standardizedDateStr = standardizeDate(dateStr);
    const standardizedTimeStr = standardizeTime(timeStr);
    // console.log("standardizedDateStr:", standardizedDateStr);
    // console.log("standardizedTimeStr:", standardizedTimeStr);

    if (!standardizedDateStr) {
        throw new Error("Invalid date format");
    }

    const [day, month, year] = standardizedDateStr.split("-");
    const [hours, minutes] = standardizedTimeStr.split(":");

    let localDate = new Date(year, month - 1, day, parseInt(hours), parseInt(minutes));
    const utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
    const malaysiaTimeOffset = 8 * 60 * 60 * 1000; // 8 hours in milliseconds
    const malaysiaDate = new Date(utcDate.getTime() + malaysiaTimeOffset);

    return malaysiaDate;
}

function adjustTime(inputTime) {    
    // Normalize the input by removing spaces and converting to lower case for easier parsing
    inputTime = inputTime.replace(/\s+/g, "").toLowerCase();

    let hours, minutes, period;

    // Check if input is in 24-hour format (e.g., "15:00")
    const is24HourFormat = /^\d{1,2}:\d{2}$/.test(inputTime);

    if (is24HourFormat) {
        [hours, minutes] = inputTime.split(":").map(Number);
        period = hours >= 12 ? "pm" : "am";
    } else {
        // Use regex to separate hours, minutes, and period
        let match = inputTime.match(/(\d{1,2}[:.]\d{2}|\d{1,2})(am|pm)?/);

        if (!match) {
            throw new Error("Time format error: Invalid time format.");
        }

        let timePart = match[1];
        period = match[2]; // "am" or "pm"

        // Check if time part contains minutes
        [hours, minutes] = timePart.includes(":") ? timePart.split(":") : [timePart, "00"];
        hours = parseInt(hours);
        minutes = parseInt(minutes);

        // Handle the case where period is undefined due to no AM/PM being specified
        if (!period) {
            if (hours >= 1 && hours <= 11) {
                period = "am"; // Assume morning
            } else if (hours === 12) {
                period = "pm"; // Noon
            } else if (hours >= 13 && hours <= 23) {
                period = "pm"; // Afternoon/evening
            } else if (hours === 0) {
                period = "am"; // Midnight
                hours = 12; // Adjust 0 hour to 12 AM
            }
        }
    }

    // Adjust hours based on the period
    if (hours === 12) {
        hours = period === "am" ? 0 : 12; // Handle noon and midnight specifically
    } else {
        hours = period === "pm" ? hours + 12 : hours;
    }

    // Create a date object using the parsed time
    const date = new Date();
    date.setHours(hours, minutes, 0); // Set hours and minutes, seconds to 0

    // Calculate time one hour before and one hour after
    const oneHourBefore = new Date(date.getTime() - 60 * 60 * 1000);
    const oneHourAfter = new Date(date.getTime() + 60 * 60 * 1000);

    // Format the times back into "h:mm a" format
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    const formattedOneHourBefore = new Intl.DateTimeFormat("en-US", options).format(oneHourBefore);
    const formattedOneHourAfter = new Intl.DateTimeFormat("en-US", options).format(oneHourAfter);

    return {
        oneHourBefore: formattedOneHourBefore,
        oneHourAfter: formattedOneHourAfter,
    };
}

const addAppointmentV2 = async (date, time, custName, custContact, desc, email) => {
    try {
        console.log("date:", date);
        console.log("time:", time);
        console.log("custName:", custName);
        console.log("custContact:", custContact);
        console.log("desc:", desc);
        console.log("email:", email);

        const formattedProposedDateTime = parseDateTimeForMalaysia(date, time);

        const checkStatus = await checkAppointmentAvailability(
            formattedProposedDateTime,
            email,
            custContact
        );
        // let checkStatus = true;
        const offDayCrash = await checkOffDays(formattedProposedDateTime, email);
        console.log("checkStatus:", checkStatus);
        console.log("offDayCrash:", offDayCrash);
        if (!checkStatus.status && !offDayCrash) {
            const docRef = await addDoc(collection(db, "appointments"), {
                desc: desc,
                appDateTime: Timestamp.fromDate(formattedProposedDateTime),
                createdBy: "AI ASSISTANT",
                date: new Date(),
                updateDateTime: new Date(),
                startTime: Timestamp.fromMillis(checkStatus.startTime),
                endTime: Timestamp.fromMillis(checkStatus.endTime),
                owner: email,
                admins: [email],
                custName: custName,
                custContact: custContact,
            });
            return JSON.stringify({
                status: "success",
                desc: desc,
                appDateTime: Timestamp.fromDate(formattedProposedDateTime),
                createdBy: "AI ASSISTANT",
                date: new Date(),
                updateDateTime: new Date(),
                startTime: Timestamp.fromMillis(checkStatus.startTime),
                endTime: Timestamp.fromMillis(checkStatus.endTime),
                owner: email,
                admins: [email],
                custName: custName,
                custContact: custContact,
            });
        } else {
            if (checkStatus.status) {
                return JSON.stringify({
                    status: `Slot occupied.Please select other time slot that range before ${adjustTime(checkStatus.time).oneHourBefore} or after ${adjustTime(checkStatus.time).oneHourAfter},or select other day.`,
                });
            } else {
                return JSON.stringify({
                    status: `Date or time not available. Please select other day for appointment.`,
                });
            }
        }
    } catch (error) {
        console.error(error);
    }
};

export default addAppointmentV2;
