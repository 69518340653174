import axios from "axios";

export default async function checkClients(clientId, secret) {
    console.log(clientId);

    let response = "";
    const data = JSON.stringify({
        clientId: clientId,
        from: clientId,
        secret,
    });

    const configStatus = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://api.djc.ai/check-clients",
        headers: {
            "Content-Type": "application/json",
        },
        data: data,
    };

    try {
        const [responseStatus] = await Promise.all([axios.request(configStatus)]);
        response = responseStatus.data;
    } catch (error) {
        console.log(error);
    }

    return response;
}
