import { Box, Button, Container } from "@mui/material";
import { collectionGroup, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import mapSnapshot from "../utils-functions/mapSnapshot";

const emailList = [
    "abdlazan9@gmail.com",
    "ace.property.gs@gmail.com",
    "admon3154@gmail.com",
    "ahmad.zikri.yaacob@naturalgamma.com",
    "ain.boostactivation@gmail.com",
    "alan_0219@hotmail.com",
    "alan_yap1121@hotmail.com",
    "alanlaiproperty88@gmail.com",
    "alanyap6087@gmail.com",
    "alex07359@gmail.com",
    "alexchia320@gmail.com",
    "alextai0517@gmail.com",
    "aliceang1995@gmail.com",
    "alvin.careline@gmail.com",
    "anderson@justgroup.com.my",
    "andersont.iqi@gmail.com",
    "andrew.khor.realty@outlook.com",
    "andyfoong.fyk@gmail.com",
    "annieng.iqi@gmail.com",
    "antonyleeproperties@gmail.com",
    "arriechong1999@gmail.com",
    "axelle.property@gmail.com",
    "aya_raine@yahoo.com.au",
    "aybellim@outlook.com",
    "aydenjong.venus@gmail.com",
    "aylexchia@gmail.com",
    "bellagio0216@gmail.com",
    "benedict7119@gmail.com",
    "bernardling08@gmail.com",
    "bernice.cheng17@gmial.com",
    "berylhock@gmail.com",
    "bingjiee96@gmail.com",
    "caixuanlew@outlook.com",
    "catherinelee138@gmail.com",
    "ccwendycc1995@gmail.com",
    "chanyc234@gmail.com",
    "charmaine.px@gmail.com",
    "cheam127@gmail.com",
    "cheekit19960823@gmail.com",
    "cherish.shan93@gmail.com",
    "chiam1220@gmail.com",
    "chinyao9233@gmail.com",
    "chongccy@gmail.com",
    "chongjiaern96@gmail.com",
    "chongsitlim@gmail.com",
    "christai8798@gmail.com",
    "chwhwan@gmail.com",
    "cindy_pang91@hotmail.com",
    "ckkey08181@gmail.com",
    "cweilun11@gmail.con",
    "daisyyap0616@gmail.com",
    "davidcheong95@gmail.com",
    "davidchieng97@gmail.com",
    "debbieleow.dl@gmail.com",
    "desmondchongproperty21@gmail.com",
    "edwingoh.kokchai@gmail.com",
    "elainechen.rina@gmail.com",
    "ellon4896@gmail.com",
    "elvyching97@gmail.com",
    "emilytproperty@gmail.com",
    "eric_0_1@hotmail.com",
    "etaconsultancy.kl@gmail.com",
    "eunciemin.iqi@gmail.com",
    "eunicemin0729@gmail.com",
    "fedinandiqi@gmail.com",
    "fitrilan.property@gmail.com",
    "frankmunhc@gmail.com",
    "gangsheng177@gmail.com",
    "gerald2603@gmail.com",
    "gnelol8895@gmail.com",
    "han.lee183@hotmail.com",
    "hanst9803@gmail.com",
    "hanst98@gmail.com",
    "hantproperties@gmail.com",
    "harunibrahim.property@gmail.com",
    "hatimnadjemudin@gmail.com",
    "hello@simplynice.me",
    "hitolim20@gmail.com",
    "hkwai6640@gmail.com",
    "home2own8@gmail.com",
    "howhongcheng@hotmail.com",
    "huiyingang96@hotmail.com",
    "imjunsu0420@gmail.com",
    "iry_iry_cy@yahoo.com",
    "irychang20@gmail.com",
    "ivanmmw91@gmail.com",
    "ivym29@gmail.com",
    "jacky.chtansrc@gmail.com",
    "jared5217@gmail.com",
    "jasonmch02@gmail.com",
    "jasonng0103@gmail.com",
    "jasperlkv@gmail.com",
    "jaxonseow@gmail.com",
    "jayshenc22@gmail.com",
    "jeremytan.iqi@gmail.com",
    "jesuswee1988@gmail.com",
    "jgandco18@gmail.com",
    "jiasheng0828@gmail.com",
    "jinfanh@gmail.com",
    "jingwei993@gmail.com",
    "jockertp@gmail.com",
    "jonathan_lk92@hotmail.com",
    "joyspekyi@gmail.com",
    "junntproperties@gmail.com",
    "kahling212@gmail.com",
    "kaiyegolf@gmail.com",
    "kaycee.eliteone@gmail.com",
    "kcchong55@hotmail.com",
    "keatwei.chong@gmail.com",
    "kellywongyikei@gmail.com",
    "kennethkjr123@gmail.com",
    "kennyloo.general@gmail.com",
    "kennyloo.property@gmail.com",
    "khaithung1006@gmail.com",
    "kharwai7272@gmail.com",
    "kingsleyfork@gmail.com",
    "kkclj99968@gmail.com",
    "kkongkk777@gmail.com",
    "kohpohfen@gmail.com",
    "kwanlaimun0408@gmail.com",
    "laiminhui520@hotmail.com",
    "laurachandran@gmail.com",
    "leowtc28@gmail.com",
    "liewwyy@gmail.com",
    "lim_jian_123456@hotmail.com",
    "lim_mingchen@yahoo.com",
    "limyokehar@yahoo.com",
    "limzhengying98@hotmail.com",
    "login.steve91@gmail.com",
    "louisphun97@gmail.com",
    "marcuschoo155@gmail.com",
    "markproperty155@gmail.com",
    "mcsean3199@gmail.com",
    "meelingchowmeeling@gmail.com",
    "mei_ling0211@hotmail.com",
    "meijunkapo_1014@hotmail.com",
    "melvinsiah275@hotmail.com",
    "meow86@hotmail.com",
    "michelleng1883@gmail.com",
    "mikeyzproperties@gmail.com",
    "mindmrmj@gmail.com",
    "mohamad.iqiprop@gmail.com",
    "mohdnazri.mz31@yahoo.com",
    "mongny@hotmail.com",
    "mr.red9966@gmail.com",
    "msleow@gmail.com",
    "munhong0909@gmail.com",
    "mypropurtunity@gmail.com",
    "myra@eliteone.com.my",
    "nancyemmawork@gmail.com",
    "naral22564@jthoven.com",
    "nardfaith123@gmail.com",
    "ngsinyi@hotmail.com",
    "nicttw@gmail.com",
    "nooryee94@gmail.com",
    "odinestate@gmail.com",
    "peilynww@gmail.com",
    "pekyincheah@gmail.com",
    "phoewitt17@gmail.com",
    "phuichai1914@gmail.com",
    "pyproperties@gmail.com",
    "pywongiqi@gmail.com",
    "race_phua@hotmail.com",
    "rechard0112@gmail.com",
    "regphoo@gmail.com",
    "rockywolff92@gmail.com",
    "sam_yap0813@hotmail.com",
    "seanteo.prop@gmail.com",
    "seong31993199@gmail.com",
    "shanetanrealestate@gmail.com",
    "sharonbeh.property@gmail.com",
    "sharonding39@gmail.com",
    "shawn.cmh@gmail.com",
    "sheonlcy@gmail.com",
    "shxyproperties@gmail.com",
    "simongoh5533@gmail.com",
    "sinyun_1995@hotmail.com",
    "smell_00churn@icloud.com",
    "sooann@justgroup.com.my",
    "ss3mini109@gmail.com",
    "steven100880@gmail.com",
    "sukisow83@gmail.com",
    "susannarayananson@gmail.com",
    "syen.yap@mykeyglobal.com",
    "sylvestertiong17@gmail.com",
    "tammy.heng@yahoo.com",
    "tangryan00@gmail.com",
    "taykaewen@gmail.com",
    "teeseechen@hotmail.com",
    "terencewong.ppty@gmail.com",
    "timothyt@live.com.my",
    "tommycoin100@gmail.com",
    "tommylimgs@gmail.com",
    "trissyap4u@gmail.com",
    "tyng1217@gmail.com",
    "uktxymglia_1649252820@tfbnw.net",
    "ummi.hanifah7733@gmail.com",
    "vanzlee.arch@gmail.com",
    "vernicelee2621@gmail.com",
    "vinclee168.property@gmail.com",
    "vinzchau00@gmail.com",
    "waeong87@yahoo.com",
    "warrenyap168@gmail.com",
    "wayne13.iqi@gmail.com",
    "wayneho.mortgage@gmail.com",
    "williamffk@gmail.com",
    "wilsontan00@gmail.com",
    "win4783@gmail.com",
    "xiiaosheng33096@gmail.com",
    "yanthang.property@gmail.com",
    "yapch0619@gmail.com",
    "yctan.iqi@gmail.com",
    "yensiewng@gmail.com",
    "yhthee33@gmail.com",
    "yong970319jin@gmail.com",
    "yth_930421@hotmail.com",
    "yuki082380@gmail.com",
    "yukil960823@gmail.com",
    "zaiwisma@icloud.com",
    "zaxchey666@gmail.com",
    "zillion.invest88@gmail.com",
    "zzhenghoong96@gmail.com",
];

export default function TestPage() {
    const handleTest = async () => {
        emailList.forEach(async (userId) => {
            const docRef = doc(db, "users", userId);
            await updateDoc(docRef, { date: new Date("2023-07-01") });
            console.log(userId, " updated date to ", new Date());
        });
    };

    return (
        <Container>
            <Box m={1}>TestPage</Box>
            <Box>
                <Button variant="contained" onClick={handleTest}>
                    Test
                </Button>
            </Box>
        </Container>
    );
}
