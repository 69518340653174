import { getDownloadURL, ref } from "firebase/storage";
import { db, storage } from "../../firebase/firebase-utils";
import { doc, updateDoc } from "firebase/firestore";
import convertToThumbnailName from "./convertToThumbnailName";

export default async function getThumbnail(file) {
    if (file.thumbnailURL) return file.thumbnailURL;

    const fullPath = convertToThumbnailName(file.fullPath);
    const storageRef = ref(storage, fullPath);

    try {
        const url = await getDownloadURL(storageRef);
        console.log(url);

        if (file.uploadedBy) {
            const docRef = doc(db, "users", file.uploadedBy, "files", file.id);
            updateDoc(docRef, { thumbnailURL: url });
        }
        return url;
    } catch (error) {
        console.log(error);
        return null;
    }
}
