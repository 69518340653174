import {
    Avatar,
    Box,
    Dialog,
    DialogTitle,
    Grid,
    Icon,
    IconButton,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    TextField,
    Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { useState } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import { arrayIsEmpty } from "../utils-functions/arrayIsEmpty";

export default function ListComponent({
    onClose,
    selectedValue,
    open,
    objects = [],
    displayName,
    title,
    icon,
    addAccount,
    search,
    subDisplayName,
}) {
    const [searchValue, setSearchValue] = useState("");
    const [page, setPage] = useState(0);
    const rowsPerPage = 25; // Set the number of rows per page

    const handleClose = () => {
        onClose(selectedValue);
        setSearchValue("");
    };

    const handleListItemClick = (value) => {
        onClose(value);
        setSearchValue("");
    };

    const handleFirstPage = () => {
        setPage(0);
    };

    const handleLastPage = () => {
        setPage(Math.ceil(filteredObjects.length / rowsPerPage) - 1);
    };

    const handleBack = () => {
        setPage(page - 1);
    };

    const handleForward = () => {
        setPage(page + 1);
    };

    const handleSearch = (e) => {
        setSearchValue(e.target.value);
        setPage(0);
    };

    let filteredObjects = objects?.filter((object) => {
        let response = false;
        if (object[displayName]) {
            if (object[displayName].toString().toLowerCase().includes(searchValue.toLowerCase())) {
                response = true;
                return response;
            } else {
                response = false;
            }
        }

        if (object[subDisplayName]) {
            if (object[subDisplayName].toString().toLowerCase().includes(searchValue.toLowerCase())) {
                response = true;
                return response;
            } else {
                response = false;
            }
        }

        return response;
    });

    const paginatedObjects = filteredObjects.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

    return (
        <>
            <Dialog
                onClose={handleClose}
                open={open}
                style={{ zIndex: 20000001 }}
                PaperProps={{
                    sx: {
                        width: { xs: "99vw", md: "40vw" }, // Adjust breakpoints as needed
                        maxWidth: "100%", // Ensure the dialog doesn't exceed the screen width
                    },
                }}
            >
                <DialogTitle id="simple-dialog-title" style={{ padding: "10px" }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center">
                                <IconButton size="small" onClick={handleClose}>
                                    <Icon>clear</Icon>
                                </IconButton>
                                {title}
                            </Box>
                        </Grid>
                        {search && (
                            <Grid item xs={12}>
                                <Box m={1}>
                                    <TextField
                                        variant="outlined"
                                        label="search"
                                        fullWidth
                                        value={searchValue}
                                        onChange={handleSearch}
                                    />
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </DialogTitle>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <IconButton onClick={handleFirstPage} disabled={page === 0}>
                        <FirstPageIcon />
                    </IconButton>
                    <IconButton onClick={handleBack} disabled={page === 0}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography>{page + 1}</Typography>
                    <IconButton
                        onClick={handleForward}
                        disabled={page >= Math.ceil(filteredObjects.length / rowsPerPage) - 1}
                    >
                        <ArrowForwardIcon />
                    </IconButton>
                    <IconButton
                        onClick={handleLastPage}
                        disabled={page >= Math.ceil(filteredObjects.length / rowsPerPage) - 1}
                    >
                        <LastPageIcon />
                    </IconButton>
                    <Typography variant="caption">Total entry: {objects.length}</Typography>
                </Box>
                <div
                    style={{
                        overflowX: "hidden",
                        overflowY: "auto",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <List style={{ minWidth: "350px", height: "600px" }}>
                        {addAccount && (
                            <ListItemButton onClick={() => handleListItemClick("addAccount")}>
                                <ListItemAvatar>
                                    <Avatar sx={{ backgroundColor: blue[500] }}>
                                        <Icon>add</Icon>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Add account" />
                            </ListItemButton>
                        )}
                        {!arrayIsEmpty(objects) &&
                            paginatedObjects.map((object, i) => (
                                <ListItemButton onClick={() => handleListItemClick(object)} key={i}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Icon>{icon}</Icon>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={object[displayName]}
                                        secondary={object[subDisplayName] || ""}
                                    />
                                </ListItemButton>
                            ))}
                    </List>
                </div>
            </Dialog>
        </>
    );
}
