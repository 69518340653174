import { Avatar, Box, Button, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import useNotification from "../../context-utils/NotificationContext";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AuthContext } from "./../../context/AuthContext";
import React, { useEffect } from "react";
import { useContext, useState } from "react";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { updateListing } from "../ListingPage/updateListing";
import { updateAgentHeader } from "../ListingPage/updateAgentHeader";
import { notification } from "../../utils-functions/notification";
import { getAgentListings } from "../ListingPage/getAgentListing";
import { db } from "../../firebase/firebase-utils";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";

const HeaderPage = () => {
    const notify = useNotification();

    const { user } = useContext(AuthContext);
    const [userHeader, setUserHeader] = useState({
        avatar: "",
        agentDomain: "property.djcsystem.com",
        agentFB: "",
        email: user?.email || "",
        phone: user?.phone || "",
        whatsapp: "",
        agentCompany: "",
        agentCompanyAdd: "",
        aboutTitle: "",
        aboutDesc: "",
        displayEmail: "",
        displayNumber: ""
    });
    const [selectedImage, setSelectedImage] = useState(null);
    const [displayImage, setDisplayImage] = useState(null);

    useEffect(() => {
        if (user?.email) {
            const docRef = doc(db, "users", user.email); // Adjust to your Firestore path as necessary
            const unsubscribe = onSnapshot(docRef, (doc) => {
                const data = doc.data();
                const settings = data?.settings?.listingHeader;
                if (settings) {
                    setUserHeader({
                        avatar: settings.avatar || "",
                        agentDomain: settings.agentDomain || "property.djcsystem.com",
                        agentFB: settings.agentFB || "",
                        email: settings.email || user.email, // Assuming email might not be under settings
                        phone: settings.phone || "",
                        whatsapp: settings.whatsapp || "",
                        agentCompany: settings.agentCompany || "",
                        agentCompanyAdd: settings.agentCompanyAdd || "",
                        aboutTitle: settings.aboutTitle || "",
                        aboutDesc: settings.aboutDesc || "",
                        displayEmail: settings.displayEmail || "",
                        displayNumber: settings.displayNumber || ""
                    });
                    if (settings.avatar) {
                        setDisplayImage(settings.avatar);
                    }
                }
            });

            return () => unsubscribe();
        }
    }, [user?.email]);

    const handleAvatarChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setSelectedImage(selectedFile);
                setDisplayImage(e.target.result);
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    const handleView = () => {
        console.log("user", user);
        console.log("domain:", userHeader.agentDomain);
        const defaultDomain = "property.djcsystem.com";
        const linkToOpen =
            !userHeader.agentDomain || userHeader.agentDomain === defaultDomain
                ? `${process.env.REACT_APP_LISTING_HOST}agent/${user.clientId}`
                : userHeader.agentDomain;
        window.open(linkToOpen, "_blank");
    };

    const updateUserHeader = async () => {
        if (user?.email) {
            const docRef = doc(db, "users", user.email); // Make sure this path matches your Firestore structure
            try {
                await updateDoc(docRef, {
                    "settings.listingHeader": {
                        ...userHeader, // Spreading the userHeader state to update all fields
                        avatar: displayImage // Assuming you want to update the avatar with the selectedImage or the one already present
                    }
                });
                notify("Profile updated successfully!"); // Notify the user of success
            } catch (error) {
                console.error("Error updating document: ", error);
                notify("Failed to update profile."); // Notify the user of failure
            }
        }
    };

    return (
        <Grid item display="flex" justifyContent="center" xs={12} md={4}>
            <Grid container display="flex" spacing={1} justifyContent="center" >
                <Paper
                    sx={{
                        width: "100%",
                        padding: "8px",
                        minHeight: "100vh", // Changed height to minHeight
                        overflowY: "auto",
                        overflowX: "hidden",
                    }}
                >
                    <Box display="flex" flexDirection="column" alignItems="center" >
                       
                            <Stack
                                direction="column"
                                spacing={2}
                                sx={{ margin: 1, alignItems: "center", width: "100%" }}
                            >
                                <label>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        id="fileInput"
                                        style={{ display: "none" }}
                                        onChange={handleAvatarChange}
                                    />
                                    {/* Avatar component with src dynamically set based on selectedImage */}
                                    <Avatar
                                        alt={user.displayName || ""}
                                        src={displayImage || userHeader.avatar} // Use selectedImage if available, otherwise use default
                                        sx={{
                                            width: 106,
                                            height: 106,
                                            flex: "none", // Prevent stretching
                                            marginLeft: { xs: "auto", sm: 0 },
                                            marginRight: { xs: "auto", sm: 2 },
                                            display: "block",
                                            boxShadow: 5,
                                            marginBottom: 1
                                        }}
                                    />
                                </label>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    sx={{
                                        fontWeight: "bold",
                                        flex: 1,
                                        textAlign: "center",
                                        maxWidth: 400,
                                        marginTop: 3
                                    }}
                                >{user.displayName}
                                </Typography>
                                <Stack
                                    direction="column"
                                    spacing={2}
                                    sx={{
                                        display: "flex",
                                        alignItems: {
                                            xs: "center", // Align center for small screens
                                            sm: "flex-start" // Align flex-start for larger screens
                                        },
                                        marginLeft: 2,
                                        width: "100%"
                                    }}
                                >
                                    <section style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                        <DomainVerificationIcon />
                                        <span style={{ marginLeft: 8, width: "100%" }}>
                                            <TextField
                                                name="agentDomain"
                                                label="Domain(eg: https://www.yourdomain.com , please contact us for DNS settings)"
                                                variant="outlined"
                                                fullWidth
                                                margin="dense"
                                                value={userHeader.agentDomain}
                                                onChange={(e) => {
                                                    setUserHeader((prevState) => ({
                                                        ...prevState,
                                                        agentDomain: e.target.value
                                                    }));
                                                }}
                                            />
                                        </span>
                                    </section>
                                    <section style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                        <FacebookIcon />
                                        <span style={{ marginLeft: 8, width: "100%" }}>
                                            <TextField
                                                name="agentFB"
                                                label="Facebook Link"
                                                variant="outlined"
                                                fullWidth
                                                margin="dense"
                                                value={userHeader.agentFB || ""}
                                                onChange={(e) => {
                                                    setUserHeader((prevState) => ({
                                                        ...prevState,
                                                        agentFB: e.target.value
                                                    }));
                                                }}
                                            />
                                        </span>
                                    </section>
                                    <section style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                        <EmailIcon />

                                        <span style={{ marginLeft: 8, width: "100%" }}>
                                            <TextField
                                                name="displayEmail"
                                                label="Display Email"
                                                variant="outlined"
                                                fullWidth
                                                margin="dense"
                                                value={userHeader.displayEmail || ""}
                                                onChange={(e) => {
                                                    setUserHeader((prevState) => ({
                                                        ...prevState,
                                                        displayEmail: e.target.value
                                                    }));
                                                }}
                                            />
                                        </span>
                                    </section>
                                    <section style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                        <LocalPhoneIcon />
                                        <span style={{ marginLeft: 8, width: "100%" }}>
                                            <TextField
                                                name="displayNumber"
                                                label="Display Number (eg: +601012345678)"
                                                variant="outlined"
                                                fullWidth
                                                margin="dense"
                                                value={userHeader.displayNumber || ""}
                                                onChange={(e) =>
                                                    setUserHeader((prevState) => ({
                                                        ...prevState,
                                                        displayNumber: e.target.value
                                                    }))
                                                }
                                            />
                                        </span>
                                    </section>

                                    <section style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                        <WhatsAppIcon />
                                        <span style={{ marginLeft: 8, width: "100%" }}>
                                            <TextField
                                                name="whatsapp"
                                                label="Whatsapp (eg: +601012345678)"
                                                variant="outlined"
                                                fullWidth
                                                margin="dense"
                                                value={userHeader.whatsapp || ""}
                                                onChange={(e) =>
                                                    setUserHeader((prevState) => ({
                                                        ...prevState,
                                                        whatsapp: e.target.value
                                                    }))
                                                }
                                            />
                                        </span>
                                    </section>
                                </Stack>

                                <TextField
                                    name="agentCompany"
                                    label="Agent Company"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    value={userHeader.agentCompany || ""}
                                    onChange={(e) =>
                                        setUserHeader((prevState) => ({
                                            ...prevState,
                                            agentCompany: e.target.value
                                        }))
                                    }
                                />

                                <TextField
                                    name="agentCompanyAdd"
                                    label="Agent Company Address"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    margin="dense"
                                    value={userHeader.agentCompanyAdd || ""}
                                    onChange={(e) =>
                                        setUserHeader((prevState) => ({
                                            ...prevState,
                                            agentCompanyAdd: e.target.value
                                        }))
                                    }
                                />

                                <TextField
                                    name="aboutTitle"
                                    label="Title for About"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    value={userHeader.aboutTitle || ""}
                                    onChange={(e) =>
                                        setUserHeader((prevState) => ({
                                            ...prevState,
                                            aboutTitle: e.target.value
                                        }))
                                    }
                                />

                                <TextField
                                    name="aboutDesc"
                                    label="About Description"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    margin="dense"
                                    value={userHeader.aboutDesc || ""}
                                    onChange={(e) =>
                                        setUserHeader((prevState) => ({
                                            ...prevState,
                                            aboutDesc: e.target.value
                                        }))
                                    }
                                />
                                <Button
                                    variant="contained"
                                    onClick={updateUserHeader}
                                    sx={{ width: "60%", mt: 2 }} // Adjust styling as needed
                                    color="primary"
                                >
                                    Update
                                </Button>

                                <Button variant="contained" onClick={handleView} sx={{ width: "60%" }} color="success">
                                    View Page
                                </Button>
                            </Stack>
                        
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default HeaderPage;
