import { AppBar, Box, Dialog, IconButton, Slide, Toolbar, Typography } from "@mui/material";
import { forwardRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullDialog({ open, handleClose, title = "Dialog", Component }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar position={"fixed"} sx={{ backgroundColor: colors.grey[500], height: "56px" }}>
                    <Box display="flex" alignItems={"center"} p={1} pl={2}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => handleClose()}
                            aria-label="close"
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6">{title}</Typography>
                    </Box>
                </AppBar>
                <Box pt={7}>{Component}</Box>
            </Dialog>
        </Box>
    );
}
