import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { styled } from "@mui/material/styles";

const MarkdownContainer = styled("div")(({ theme }) => ({
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    "& a": {
        textOverflow: "ellipsis",
        overflow: "hidden",
        display: "inline-block",
        maxWidth: "100%",
        verticalAlign: "bottom",
        color: theme.palette.primary.main, // Optional: to use theme color for links
        textDecoration: "none", // Optional: to remove underline
    },
}));

const LinkRenderer = ({ href, children }) => (
    <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
    </a>
);

export default function FormatMarkDown({ text }) {
    return (
        <MarkdownContainer>
            <ReactMarkdown children={text} remarkPlugins={[remarkGfm]} components={{ a: LinkRenderer }} />
        </MarkdownContainer>
    );
}
