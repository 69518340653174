import { addDoc, collection, doc, getDocs, query, Timestamp, updateDoc, where } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import checkAppointmentAvailability from "../pages/AppointmentCalenderPage/checkAppointmentAvailability";
import checkOffDays from "../pages/AppointmentCalenderPage/checkOffDays";

// function parseDateTimeForMalaysia(dateStr, timeStr) {
//     const [day, month, year] = dateStr.split("-");
//     const [time, modifier] = timeStr.split(" ");
//     const [hours, minutes] = time.split(":");

//     // Convert 12-hour time format to 24-hour based on AM/PM modifier
//     let hour24 = parseInt(hours) % 12; // Correctly adjusts 12 AM to 0 hours
//     if (modifier?.toUpperCase() === "PM") hour24 += 12;

//     // Create a new Date object
//     // JavaScript Date constructor takes local time; thus we need to consider local timezone offset
//     let localDate = new Date(year, month - 1, day, hour24, parseInt(minutes));

//     // Assuming the server is in UTC; adjust for Malaysia Time Zone (UTC+8)
//     // If the server is not in UTC, adjust this calculation to match the server's timezone
//     const utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
//     const malaysiaTimeOffset = 8 * 60 * 60 * 1000; // 8 hours in milliseconds
//     const malaysiaDate = new Date(utcDate.getTime() + malaysiaTimeOffset);

//     return malaysiaDate;
// }

function standardizeTime(input) {
    // Remove spaces and convert to lowercase for easier parsing
    input = input.replace(/\s+/g, "").toLowerCase();

    // Extract time and modifier
    let time = input.match(/\d+[:.]\d+|\d+/g)[0]; // Matches "3:00", "3.00", or "3"
    let modifier = input.match(/am|pm/g)?.[0] || ""; // Matches "am" or "pm" if present

    // Convert "3" or "3:00" or "3.00" formats to a full time format
    if (!time.includes(":") && !time.includes(".")) {
        time += ":00"; // Append ":00" if no minutes are specified
    }

    time = time.replace(".", ":"); // Replace any dots with colons for standardization

    // Parse hours and minutes
    const [hours, minutes] = time.split(":").map(Number);
    let hour24 = hours % 12; // Converts "12" to "0" for correct 12-hour format processing
    if (modifier === "pm") hour24 += 12;

    return `${hour24.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
}

function standardizeDate(input) {
    let dateParts, month, day, year;
    const months = {
        jan: "01",
        feb: "02",
        mar: "03",
        apr: "04",
        may: "05",
        jun: "06",
        jul: "07",
        aug: "08",
        sep: "09",
        oct: "10",
        nov: "11",
        dec: "12",
    };

    // Normalize the input by removing any non-alphanumeric characters, except the delimiter
    input = input.toLowerCase().replace(/[^0-9a-z-\/\s]/g, "");

    // Match directly if the format includes a month name
    const match = input.match(/(\d{1,2})(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)(\d{4})/);
    if (match) {
        day = match[1];
        month = months[match[2]];
        year = match[3];
    } else {
        // Handle formats with delimiters
        if (input.includes("-")) {
            dateParts = input.split("-");
        } else if (input.includes("/")) {
            dateParts = input.split("/");
        } else if (input.includes(" ")) {
            dateParts = input.split(" ");
        } else {
            return null; // Unsupported format or further logic may be added here
        }

        // Attempt to identify parts based on common ordering
        if (dateParts.length === 3) {
            // Assuming the format could be either D-M-Y or M-D-Y; check typical range to infer
            if (parseInt(dateParts[0]) > 12) {
                day = dateParts[0];
                month = dateParts[1];
                year = dateParts[2];
            } else if (parseInt(dateParts[1]) > 12) {
                month = dateParts[0];
                day = dateParts[1];
                year = dateParts[2];
            } else {
                // Default to D-M-Y if ambiguous
                day = dateParts[0];
                month = dateParts[1];
                year = dateParts[2];
            }
        } else {
            return null; // Invalid format
        }
    }

    // Validate and format
    day = parseInt(day).toString().padStart(2, "0");
    month = parseInt(month).toString().padStart(2, "0"); // Ensures numeric month is also padded

    if (day && month && year) {
        return `${day}-${month}-${year}`;
    } else {
        return null; // Final check for validity
    }
}


function parseDateTimeForMalaysia(dateStr, timeStr) {
console.log("dateStr:", dateStr);
console.log("timeStr:", timeStr);
    const standardizedDateStr = standardizeDate(dateStr);
    const standardizedTimeStr = standardizeTime(timeStr);
console.log("standardizedDateStr:", standardizedDateStr);
console.log("standardizedTimeStr:", standardizedTimeStr);

    if (!standardizedDateStr) {
        throw new Error("Invalid date format");
    }

    const [day, month, year] = standardizedDateStr.split("-");
    const [hours, minutes] = standardizedTimeStr.split(":");

    let localDate = new Date(year, month - 1, day, parseInt(hours), parseInt(minutes));
    const utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
    const malaysiaTimeOffset = 8 * 60 * 60 * 1000; // 8 hours in milliseconds
    const malaysiaDate = new Date(utcDate.getTime() + malaysiaTimeOffset);

    return malaysiaDate;
}


function adjustTime(inputTime) {

    // Normalize the input by removing spaces and converting to lower case for easier parsing
    inputTime = inputTime.replace(/\s+/g, "").toLowerCase();

    // Use regex to separate hours, minutes, and period
    let [timePart, period] = inputTime.match(/(\d+[:.]\d+|\d+)(am|pm)?/);

    // Check if time part contains minutes
    let [hours, minutes] = timePart.includes(":") ? timePart.split(":") : [timePart, "00"];

    // Parse hours and minutes
    hours = parseInt(hours);
    minutes = parseInt(minutes);

    // Handle the case where period is undefined due to no AM/PM being specified
    if (!period) {
        throw new Error("Time format error: AM/PM designation is missing.");
    }

    // Adjust hours based on the period
    if (hours === 12) {
        hours = period === "am" ? 0 : 12; // Handle noon and midnight specifically
    } else {
        hours = period === "pm" ? hours + 12 : hours;
    }

    // Create a date object using the parsed time
    const date = new Date();
    date.setHours(hours, minutes, 0); // Set hours and minutes, seconds to 0

    // Calculate time one hour before and one hour after
    const oneHourBefore = new Date(date.getTime() - 60 * 60 * 1000);
    const oneHourAfter = new Date(date.getTime() + 60 * 60 * 1000);

    // Format the times back into "h:mm a" format
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    const formattedOneHourBefore = new Intl.DateTimeFormat("en-US", options).format(oneHourBefore);
    const formattedOneHourAfter = new Intl.DateTimeFormat("en-US", options).format(oneHourAfter);

    return {
        oneHourBefore: formattedOneHourBefore,
        oneHourAfter: formattedOneHourAfter,
    };
}


const editAppointmentV2 = async (custContact, date, time, newDate, newTime, email) => {
    try {
        console.log("date:", date);
        console.log("time:", time);
        console.log("newDate:", newDate);
        console.log("newTime:", newTime);
        const formattedProposedDateTime = parseDateTimeForMalaysia(date, time);
        const newFormattedProposedDateTime = parseDateTimeForMalaysia(newDate, newTime);
console.log("formattedProposedDateTime:", formattedProposedDateTime);
console.log("newFormattedProposedDateTime:", newFormattedProposedDateTime);
console.log("custContact:",custContact);
        const appointmentCrash = await checkAppointmentAvailability(
            newFormattedProposedDateTime,
            email,
            custContact
        );
        const offDayCrash = await checkOffDays(newFormattedProposedDateTime, email);

        if (!appointmentCrash && !offDayCrash) {
            const appointmentsRef = collection(db, "appointments");
            const q = query(
                appointmentsRef,
                where("appDateTime", "==", formattedProposedDateTime),
                where("custContact", "==", custContact),
                where("owner", "==", email)
            );

            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                return JSON.stringify({
                    status: "No matching appointment found.",
                });
            } else {
                querySnapshot.forEach(async (document) => {
                    const appointmentRef = doc(db, "appointments", document.id);
                    await updateDoc(appointmentRef, {
                        appDateTime: newFormattedProposedDateTime,
                        updateDateime: new Date(),
                        remark:'last updated by customer.'
                    });
                });
                return JSON.stringify({
                    status: "success",
                    message: "Appointment updated successfully.",
                });
            }
        } else {
            if (appointmentCrash) {
                return JSON.stringify({
                    status: `Slot occupied. Please select another time slot before ${adjustTime(time).oneHourBefore} or after ${adjustTime(time).oneHourAfter}, or select another day.`,
                });
            } else {
                return JSON.stringify({
                    status: `Date or time not available. Please select another day for the appointment.`,
                });
            }
        }
    } catch (error) {
        console.error("Error in editAppointment:", error);
        return JSON.stringify({
            status: "error",
            message: error.message,
        });
    }
};

export default editAppointmentV2;
